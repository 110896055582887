import './UnverifiedUser.sass'

import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import { useResendChangeEmail, useUserStatus, useVerifyUser } from 'dataQueries'

import { EmailVerifyMode } from '../EmailVerified/EmailVerified'
import { OnboardingPageVerifyEmail } from '../OnboardingUser/OnboardingPageVerifyEmail.component'
import { PageTransition } from 'utils/animations'
import { ThemeProvider } from '@emotion/react'
import { logAnalyticsEvent } from 'utils/analytics'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useAuth0 } from 'utils/auth'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useTranslation } from 'react-i18next'

const UnverifiedUser: React.FC = (props: any) => {

  const dispatch = useDispatch()
  const { t } = useTranslation(['unverified_user'])
  const { refreshUser, user } = useAuth0()

  const params = useMemo(() => new URLSearchParams(window.location.search), [])
  const isChangeEmail = useMemo(() => params.get('mode') === EmailVerifyMode.CHANGE_EMAIL, [params])

  const { spawnSuccessToast } = useSnackbar()
  const resendChangeEmail = useResendChangeEmail()
  const verifyUser = useVerifyUser()
  const userStatusRequest = useUserStatus()

  // log entering this page
  useEffect(() => {
    logAnalyticsEvent('enters_user_unverified_screen', {})
  }, [])

  // start long polling for user status
  useEffect(() => {
    window.setTimeout(() => {
      userStatusRequest.refetch()
    }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // handle user status
  useLayoutEffect(() => {
    if (
      userStatusRequest.isSuccess &&
      userStatusRequest.data?.isEmailVerified &&
      !user?.email_verified
    ) {
      logAnalyticsEvent('email_verified', {})
      refreshUser()
    } else if (
      (
        userStatusRequest.isSuccess ||
        userStatusRequest.isError
      ) &&
      !userStatusRequest.data?.isEmailVerified
    ) {
      window.setTimeout(() => {
        userStatusRequest.refetch()
      }, 2000)
    }
  }, [dispatch, userStatusRequest, user, refreshUser])

  const handleResendEmail = useCallback(() => {
    if (isChangeEmail) return resendChangeEmail.mutate({}, {
      onSuccess: () => {
        spawnSuccessToast(t('resend_success'))
      },
    })

    return verifyUser.mutate({}, {
      onSuccess: () => {
        spawnSuccessToast(t('resend_success'))
      },
    })
    // skip deps t, spawnSuccessToast, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeEmail, resendChangeEmail, verifyUser])

  return (
    <ThemeProvider theme={redesignMUITheme}>
      <PageTransition>
        <OnboardingPageVerifyEmail handleResendEmail={handleResendEmail} />
      </PageTransition>
    </ThemeProvider>
  )
}

export default UnverifiedUser
