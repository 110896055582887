import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Path } from 'constants/router'
import { PropertyTab } from '../_main/Property.controller'
import constate from 'constate'

/** Context provider for gallery navigation tabs */
export const [PropertyNavigationContextProvider, usePropertyNavigation] = constate(() => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string, tab?: string }>()

  const [activeTab, setActiveTab] = useState<PropertyTab>(PropertyTab.VISUALS_GALLERY)

  const goToPropertyPath = useCallback((selectedTab: PropertyTab) => {
    if (!id) return
    if (selectedTab === PropertyTab.VISUALS_GALLERY) navigate(Path.PROPERTY_DETAIL.replace(':id', id.toString()))
    if (selectedTab !== PropertyTab.VISUALS_GALLERY) navigate(Path.PROPERTY_INFO.replace(':id', id.toString()).replace(':tab', selectedTab))
  }, [id, navigate])

  const handleChangeTab = useCallback((tab: PropertyTab) => {
    setActiveTab(tab)
    goToPropertyPath(tab)
  }, [goToPropertyPath])

  useEffect(() => {
    if (activeTab) goToPropertyPath(activeTab)
    // no goToPropertyPath in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    handleChangeTab,
    setActiveTab,
    activeTab,
  }
})
