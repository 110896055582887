import * as Sentry from '@sentry/react'

import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { useCallback, useRef, useState } from 'react'

import { Endpoint } from 'constants/API'
import { Path } from 'constants/router'
import { PinturaEditor } from '@pqina/react-pintura'
import { PinturaSave } from './VisualPinturaEditingFooter.module'
import { VisualFileType } from 'constants/visual'
import constate from 'constate'
import { useFileAPI } from 'components/common/FileAPI'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

interface ExtendedFile extends File {
  tag: string
}

export const [VisualPinturaEditingContextProvider, useVisualPinturaEditing] = constate(({
  file,
  imageUrl,
  assignmentId,
  isFileVersioned,
}: {
  file: File
  imageUrl: string
  assignmentId: string
  isFileVersioned: boolean
}) => {
  const editorRef = useRef<PinturaEditor>(null)

  const { t } = useTranslation(['visual_pintura_editing'])
  const navigate = useNavigate()

  const { spawnSuccessToast, spawnErrorToast } = useSnackbar()

  const {
    baseUserData,
    organizationData,
  } = useUserData()

  const [isProcessingImage, setIsProcessingImage] = useState(false)

  const sourceVisualFilename = file.name

  const logEditEvent = useCallback((eventName: string, params?: {}) => {
    logAnalyticsEvent(eventName, {
      userEmail: baseUserData?.email,
      organizationId: organizationData?.id,
      assignmentId,
      visualName: file.name,
      originalImageUrl: imageUrl,
      ...params
    })
  }, [baseUserData?.email, organizationData?.id, assignmentId, file.name, imageUrl])

  const uploadEditedVisual = useFileAPI('SELF_EDITED_VISUALS', {
    uploadUrlResolver: (fileEntry, api) => api.post(
      Endpoint.SAVE_PINTURA_EDIT,
      { assignmentId: assignmentId!.toString() },
      {
        sourceVisualFilename: fileEntry.fileObject.name,
        contentType: fileEntry.fileObject.type,
        type: VisualFileType.POST,
        replaceExisting: isFileVersioned && (fileEntry.fileObject as ExtendedFile).tag === PinturaSave.PINTURA_RE_EDIT_VISUAL,
        tag: fileEntry.tag,
      },
      true // keep response to make sure the file is uploaded - otherwise response after getting signedUrl has different structure
    )
  })

  const handleProcessImage = useCallback(async (tag: string) => {
    if (!editorRef.current) return

    setIsProcessingImage(true)

    try {
      const imageWriterResult = await editorRef.current.editor.processImage()

      if (imageWriterResult.dest) {
        await new Promise<void>(() => {

          const originalFile = imageWriterResult.dest

          // Create a new File object while preserving original properties
          const extendedDest = new File([originalFile], originalFile.name, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
          })

          // Manually add the `tag` property
          Object.defineProperty(extendedDest, 'tag', {
            value: tag,
            writable: false,
            enumerable: true,
          })

          uploadEditedVisual.uploadFiles([extendedDest], {
            onSettled: (successIds, errorIds) => {
              if (errorIds.length > 0) {
                setIsProcessingImage(false)
                return spawnErrorToast(t('save_error'))
              }

              if (successIds.length === 1) {
                spawnSuccessToast(t('success_message'), { timeout: 3500 })

                logEditEvent(AnalyticsEvent.PINTURA_SAVE_IMAGE, {
                  status: 'saved',
                  result: successIds,
                })

                setTimeout(() => {
                  navigate(0)
                }, 3000)
              }
            },
          })
        })
      }
    } catch (error) {
      console.error('Error during image processing:', error)

      const sentryMessage = 'Pintura visual editing ERROR'
      Sentry.captureException(sentryMessage, (scope) => {
        scope.clearBreadcrumbs()
        scope.setFingerprint([sentryMessage])
        scope.setExtra('sourceVisualFilename', sourceVisualFilename)
        scope.setExtra('assignmentId', assignmentId)
        scope.setExtra('originalImageUrl', imageUrl)
        return scope
      })

      spawnErrorToast(t('general_error', { error }))

      logEditEvent(AnalyticsEvent.PINTURA_SAVE_IMAGE, {
        status: 'failed',
        result: error,
      })
    }

    // excluded dependencies: 'isSaved', 'logEditEvent', 'navigate', 'spawnErrorToast', 'spawnSuccessToast', and 't'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadEditedVisual, sourceVisualFilename, assignmentId, imageUrl])

  const onActionPageClose = useCallback(async () => {
    logEditEvent(AnalyticsEvent.PINTURA_EXIT)

    navigate(Path.GALLERY.replace(':id', assignmentId))
  }, [logEditEvent, navigate, assignmentId])

  return {
    sourceVisualFilename,
    imageUrl,
    assignmentId,
    logEditEvent,
    uploadEditedVisual,
    handleProcessImage,
    isProcessingImage,
    onActionPageClose,
    editorRef,
    isFileVersioned,
  }
})
