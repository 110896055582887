import { API_URL } from './apiURL'

/** Base URL for API requests */
export const URL = API_URL

/** Enumeration of all API endpoints */
export enum Endpoint {
  // Misc
  HELLO = '/hello',

  // User
  USER_ME = '/user/me',
  USER_META = '/user/metadata',
  USER_CHANGE_LOCALE = '/user/locale?email={email}',
  UPDATE_RECEIPT_EMAIL = '/user/billingEmail?billingEmail={billingEmail}',
  UPDATE_PREFERRED_PAYMENT_METHOD = '/user/preferredPaymentMethod?preferredPaymentMethod={paymentMethod}',
  USER_WORKSPACES = '/user/workspace',

  // Organization
  ORGANIZATION_SEARCH = '/organization/all',
  UPDATE_ORGANIZATION_SUBSCRIPTION = '/organization/update/{id}/{toggleSubscription}',

  // Workspace
  CREATE_WORKSPACE = '/workspace/',
  ADD_WORKSPACE_MEMBERS = '/workspace/{workspaceId}/members',
  REMOVE_WORKSPACE_MEMBER = '/workspace/{workspaceId}/member',
  REVOKE_INVITATION = '/workspace/{workspaceId}/invitation/{invitationId}',

  // Subscription
  SUBSCRIPTION_CHECKOUT_LINK = '/workspace/{workspaceId}/subscribe',

  // Assignment
  GET_UPSELL_PAYMENT_INTENT = '/assignment/{assignmentId}/upsellPaymentIntent',

  // Order
  ORDER_CONFIGURATION_CATEGORY = '/order/configuration/category',
  ORDER_CONFIGURATION_ADDRESS = '/order/configuration/address',
  ORDER_CONFIGURATION_PRODUCT = '/order/configuration/product',
  ORDER_CONFIGURATION_INSTRUCTION = '/order/configuration/instruction',
  ORDER = '/order/',
  ORDER_PAYMENT_INTENT = '/order/paymentIntent',
  AUTOMATE_ORDER = '/order/{orderId}/automate',
  STOP_AUTOMATED_ORDER = '/order/{orderId}/stopAutomation',

  // Visual
  VISUAL_LIST_ALL = '/assignment/{assignmentId}/visual/all?type={type}',
  VISUAL_PURCHASE_SESSION_LIST_ALL = '/order/{sessionId}/visual/all?type={type}',
  VISUAL_DOWNLOAD_URL = '/assignment/{assignmentId}/visual/{filename}?type={type}',
  VISUAL_PURCHASE_SESSION_DOWNLOAD_URL = '/order/{sessionId}/visual/{filename}?type={type}',
  VISUAL_UPLOAD_URL = '/assignment/{assignmentId}/visual/uploadUrl',
  VISUAL_UPLOAD_URL_REPLACE = '/assignment/{assignmentId}/visual/uploadUrl',
  VISUAL_PURCHASE_SESSION_UPLOAD_URL = '/order/{sessionId}/visual/uploadUrl',
  VISUAL_PURCHASE_SESSION_UPLOAD_URL_REPLACE = '/order/{sessionId}/visual/uploadUrl',
  VISUAL_DELETE = '/assignment/{assignmentId}/visual/{filename}',
  VISUAL_PURCHASE_SESSION_DELETE = '/order/{sessionId}/visual/{filename}',
  VISUAL_SELECT = '/assignment/{assignmentId}/visual/select',
  VISUAL_ADDITIONAL_PRICE = '/assignment/{assignmentId}/additionalVisualPrice',
  VISUAL_ARCHIVE = '/assignment/{assignmentId}/visual/archive',
  VISUAL_DOWNLOAD_ARCHIVE = '/assignment/{assignmentId}/visual/archive/{jobId}',
  VISUAL_DELETE_VIRTUAL_VISIT = '/assignment/{assignmentId}/virtualVisit',
  VISUAL_EXTEND_VIRTUAL_VISIT = '/assignment/{assignmentId}/virtualVisit/extendExpiration',
  SELECT_VISUALS_FOR_STAGING = '/assignment/{assignmentId}/visual/staging/select',
  VISUAL_EDITING_ARCHIVE = '/assignment/{assignmentId}/editedVisuals/{exportId}/archive',

  // Visuals Editing
  WATERMARK_CRUD = '/workspace/{workspaceId}/assets/watermark/',
  WATERMARK_LOAD = '/workspace/{workspaceId}/assets/watermark/{watermarkId}',
  EDITED_VISUAL_DOWNLOAD_URL = '/assignment/{assignmentId}/editedVisuals/{jobId}/{filename}?type={type}',

  // Admin
  ADMIN_USER_CLIENT = '/user/client',
  ADMIN_ONBOARD_CLIENT = '/user/client/onboard',
  ADMIN_RESEND_CLIENT_INVITATION = '/user/resendInvitation',
  ADMIN_SEND_ASSIGNMENT_VISUALS_TO_CLIENT = '/assignment/{assignmentId}/deliver',
  ADMIN_SEND_ASSIGNMENT_VISUALS_TO_EDITOR = '/assignment/{assignmentId}/visual/edit',
  ADMIN_EDIT_ASSIGNMENT_ADMIN_COMMENTS_FOR_EDITOR = '/assignment/{assignmentId}/adminCommentsForEditor',
  ADMIN_EDIT_ASSIGNMENT_INTERNAL_COMMENTS = '/assignment/{assignmentId}/adminInternalComments',
  ADMIN_EDIT_ASSIGNMENT_CREATIVE_INSTRUCTIONS_FROM_ADMIN = '/assignment/{assignmentId}/creativeInstructionsFromAdmin',
  ADMIN_GET_TRANSLATION = '/translation/all',
  ADMIN_UPDATE_TRANSLATION = '/translation/updateBatch',
  ADMIN_CT_UNVAILABLE_LIST = '/assignment/{assignmentId}/creativesUnavailability',

  // GCP Job
  JOB = '/job/{id}',

  // Self edit visual with Pintura
  SAVE_PINTURA_EDIT = '/assignment/{assignmentId}/self-edited-visuals/upload-url',
}

export enum OrderEndpoint {
  GET_POA_UPLOAD_URL = '/order/{orderId}/documents/upload-url',
  DELETE_UPLOADED_POA = '/order/{orderId}/documents/{filename}',
}

export enum AssignmentDocumentsEndpoint {
  GET_UPLOAD_URL = '/assignment/{assignmentId}/documents/uploadUrl',
  GET_REUPLOAD_URL = '/assignment/{assignmentId}/documents/uploadUrl',
  DELETE_DOCUMENT = '/assignment/{assignmentId}/documents',
}

export enum PurchaseFlowEndpoint {
  GET_POA_UPLOAD_URL = '/order/{sessionId}/power-of-attorney/uploadUrl',
  DELETE_UPLOADED_POA = '/order/{sessionId}/power-of-attorney',
  GET_POA_REPS_UPLOAD_URL = '/order/{sessionId}/power-of-attorney-representatives/uploadUrl',
  DELETE_UPLOADED_POA_REPS = '/order/{sessionId}/power-of-attorney-representatives',
  GET_DOCUMENT_UPLOAD_URL = '/order/{sessionId}/document/uploadUrl',
  DELETE_UPLOADED_DOCUMENT = '/order/{sessionId}/document/{filename}',
}

export enum PropertyEndpoint {
  GET_PROPERTY_DETAIL = '/workspace/{workspaceId}/realty/{propertyId}',
  GET_PROPERTY_DETAIL_WITH_OBJECT_REFERENCE = '/workspace/{workspaceId}/realty/object-reference-id/{objectReferenceId}',
  GET_SEARCH_PROPERTY = '/workspace/{workspaceId}/realty/search/text',
}
