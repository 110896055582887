import { DynamicSummary, SummaryContactPerson, SummaryTextBlock } from '../Summary'
import { MeetingData, MeetingInfo, useCart, usePurchaseFlowConfig, usePurchaseFlowOrderMeta, useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'
import React, { Fragment, useMemo } from 'react'

import { EventTime } from 'components/common/EventTime'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { useTimezone } from 'components/contexts/timezone.context'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * Logistics component displays order-related data.
 *
 * @example
 * <Logistics />
 */
export const Logistics: React.FC = () => {

  const { t } = useTranslation(['order', 'product_kind'])
  const { adminSelectedUserEmail } = useTargetOrderUser()
  const { reference, meetingInformation, selectedMeetingType } = usePurchaseFlowOrderMeta()
  const { selectedAssignmentPlace, catalogueTimezone } = usePurchaseFlowConfig()
  const { sumMinTime, sumMaxTime } = useCart()
  const { userTimezone } = useTimezone()

  const selectedMeetingInformation: MeetingInfo | undefined = useMemo(() => {
    if (!selectedMeetingType) return undefined

    return meetingInformation[selectedMeetingType as keyof MeetingData]
  }, [meetingInformation, selectedMeetingType])

  const hasDataToShow = useMemo(() => Boolean(
    adminSelectedUserEmail
    || reference
    || selectedMeetingType
    || selectedMeetingInformation
    || selectedAssignmentPlace?.formatted_address
  ), [adminSelectedUserEmail, reference, selectedAssignmentPlace?.formatted_address, selectedMeetingInformation, selectedMeetingType])

  if (!hasDataToShow) return null

  return (
    <SectionedBorderBox title={t('step_validation.logistics')} flex="0 0 300px" height="100%">
      <Stack gap={2}>

        {adminSelectedUserEmail &&
          <DynamicSummary
            title={t('step_validation.client')}
            value={adminSelectedUserEmail}
          />
        }

        {reference &&
          <DynamicSummary
            title={t('step_validation.order_reference')}
            value={reference}
          />
        }

        {selectedMeetingType &&
          <DynamicSummary
            title={t('step_validation.meeting')}
            value={t(`product_kind:${selectedMeetingType}`) || '-'}
          />
        }

        {selectedMeetingInformation &&
          <Fragment>

            {selectedMeetingInformation.date &&
              <DynamicSummary
                title={t('step_validation.date_time')}
                value={
                  <EventTime
                    eventStart={selectedMeetingInformation.date.toISOString(true)}
                    eventTimezone={catalogueTimezone ?? null}
                    userTimezone={userTimezone}
                    minDuration={sumMinTime}
                    maxDuration={sumMaxTime}
                  />
                }
              />
            }

            {selectedMeetingInformation.address &&
              <DynamicSummary
                title={t('step_validation.key_pickup_address')}
                value={
                  <SummaryTextBlock
                    value={selectedMeetingInformation.address.formatted_address}
                  />
                }
              />
            }

            <DynamicSummary
              title={t('step_validation.contact_person')}
              value={
                <SummaryContactPerson
                  name={selectedMeetingInformation.name}
                  phone={selectedMeetingInformation.phone}
                  email={selectedMeetingInformation.email ?? '-'}
                />
              }
            />

            {selectedMeetingInformation.comments &&
              <DynamicSummary
                title={t('step_validation.meeting_instructions')}
                value={
                  <SummaryTextBlock
                    value={selectedMeetingInformation.comments}
                  />
                }
              />
            }

          </Fragment>
        }

        {selectedAssignmentPlace?.formatted_address &&
          <DynamicSummary
            title={t('step_validation.address')}
            value={
              <SummaryTextBlock
                value={selectedAssignmentPlace?.formatted_address}
              />
            }
          />
        }

      </Stack>
    </SectionedBorderBox>
  )
}
