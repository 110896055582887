import { DocumentUploadType, UploadTagType } from 'constants/documents'
import { usePurchaseFlowConfig, usePurchaseFlowProducts } from '../../_main/contexts'

import { useFileAPI } from 'components/common/FileAPI'
import { PurchaseFlowEndpoint } from 'constants/API'
import { EnergyPassReason } from 'constants/purchaseFlow/sharedEnergyCertificateTypes'
import constate from 'constate'
import { useState } from 'react'

export enum AdditionalInformationUploadType {
  EXTERIOR_VIEW = 'EXTERIOR_VIEW',
  HEATER_NAMEPLATE = 'HEATER_NAMEPLATE',
  CONSTRUCTION_DESCRIPTION = 'CONSTRUCTION_DESCRIPTION',
}

export const [AdditionalInformationContextProvider, useAdditionalInformation] = constate(() => {

  const { sessionId } = usePurchaseFlowConfig()
  const { selectedProducts } = usePurchaseFlowProducts()

  const [energyCertificateReason, setEnergyCertificateReason] = useState<EnergyPassReason>(EnergyPassReason.NEW_RENTAL)
  const [message, setMessage] = useState<string>('')

  const useAdditionalInformationUpload = (type: AdditionalInformationUploadType, uploadTag: UploadTagType) => useFileAPI(type, {
    uploadUrlResolver: sessionId && selectedProducts ? (fileEntry, api) => (api.post(
      PurchaseFlowEndpoint.GET_DOCUMENT_UPLOAD_URL,
      { sessionId: sessionId!.toString() },
      {
        productId: Object.values(selectedProducts)[0].id.toString(),
        documentMetadata: {
          filename: fileEntry.originalFilename,
          contentType: fileEntry.fileObject.type,
          type: DocumentUploadType.INPUT,
          tag: uploadTag,
        }
      },
      true
    ))
      : undefined,
    deleteHandler: sessionId && selectedProducts ? (_, api) => api.delete(
      PurchaseFlowEndpoint.DELETE_UPLOADED_DOCUMENT,
      { sessionId: sessionId!.toString() },
      true
    ) : undefined
  })

  const exteriorViewUpload = useAdditionalInformationUpload(
    AdditionalInformationUploadType.EXTERIOR_VIEW, UploadTagType.EXTERNAL_VIEW
  )
  const heaterNameplateUpload = useAdditionalInformationUpload(
    AdditionalInformationUploadType.HEATER_NAMEPLATE, UploadTagType.HEATING_SYSTEM_LABEL
  )
  const constructionDescriptionUpload = useAdditionalInformationUpload(
    AdditionalInformationUploadType.CONSTRUCTION_DESCRIPTION, UploadTagType.CONSTRUCTION_DESCRIPTION
  )

  return {
    message,
    setMessage,
    energyCertificateReason,
    setEnergyCertificateReason,
    useAdditionalInformationUpload,
    exteriorViewUpload,
    heaterNameplateUpload,
    constructionDescriptionUpload,
  }
})
