import './OrderDetail.sass'

import { AssignmentDTOIsAdministratorDTO, DealDTOIsAdministratorDTO, DealDTOOrAssignmentDTOIsDealDTO } from 'utils/typeguards'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { applyFeeDiscount, formatPrice } from 'utils/price'
import { useDeleteAssignmentDateTime, useEditAssignmentDateTime } from 'dataQueries'

import { AssignmentDTO } from 'models/assignment'
import Button from '../Button/Button'
import { DealDTO } from 'models/deal'
import Icon from '../Icon/Icon'
import { IconType } from 'constants/assets'
import { ManageAssignmentsPopup } from '../ManageAssignmentsPopup/ManageAssignmentsPopup'
import { ProductCategory } from 'models/product'
import { getSalesforceOrderLink } from 'utils/serialization'
import { isEditingCategory } from 'utils/validators'
import { useAuth0 } from 'utils/auth'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The additional classes to append */
  className?: string
  /** The assignment data object */
  assignmentData: AssignmentDTO
  /** The deal data object */
  dealData: DealDTO
}

/**
 * @component Display detail of the order
 * @example
 * <OrderDetail assignmentData={assignment} dealData={deal} />
 */
export const OrderDetail: React.FC<Props> = ({
  className = '',
  assignmentData,
  dealData,
}) => {
  const { t } = useTranslation(['order_detail', 'deal_assignment', 'language', 'category'])
  const { roles } = useAuth0()
  const editAssignmentDateTime = useEditAssignmentDateTime()
  const deleteAssignmentDateTime = useDeleteAssignmentDateTime()
  const [manageAssignmentsPopup, setManageAssignmentsProductsPopup] = useState<string | null>(null)
  const category = useMemo(() => dealData.productCategory as ProductCategory, [dealData])

  const onCloseHandler = useCallback(() => {
    dealData.missions.forEach((assignment) => {
      editAssignmentDateTime.reset()
      deleteAssignmentDateTime.reset()
    })
    setManageAssignmentsProductsPopup(null)
  }, [editAssignmentDateTime, deleteAssignmentDateTime, dealData.missions])

  return (
    <div className={`order-detail ${className}`}>
      <div className="content">
        <div className="main-wrapper">
          {roles.isAdmin &&
            <Fragment>
              <div className="line first-row first-column-span">
                <strong className="category margin-right">{t(`category:${category}`)}</strong>
                <span className="number background">{`#${dealData.id}`}</span>

                {DealDTOOrAssignmentDTOIsDealDTO(dealData) && DealDTOIsAdministratorDTO(dealData) &&
                  <Fragment>

                    {dealData.pipedriveId && !dealData.salesforceId &&
                      <span className="number">
                        PD: <a className="pipedrive-link" target="_blank" href={`https://backbone.pipedrive.com/deal/${String(dealData.pipedriveId)}`} rel="noreferrer">{dealData.pipedriveId}</a>
                      </span>
                    }

                    {!dealData.pipedriveId && dealData.salesforceId &&
                      <span className="number">
                        SF: <a className="pipedrive-link" target="_blank" href={getSalesforceOrderLink(dealData.salesforceId)} rel="noreferrer">{dealData.salesforceId}</a>
                      </span>
                    }

                    {!dealData.pipedriveId && !dealData.salesforceId &&
                      <span style={{ textDecoration: 'line-through', marginLeft: '1rem' }}>PD / SF</span>
                    }

                    {dealData.orderExternalIds.length > 0 &&
                      <span className="number">
                        EXT: {dealData.orderExternalIds.join(', ')}
                      </span>
                    }

                  </Fragment>
                }

              </div>
              <div className="line right first-row third-column">
                <Button
                  type="secondary"
                  onClick={e => setManageAssignmentsProductsPopup(dealData.id.toString())}
                >
                  {t('manage_assignments')}
                </Button>
                <ManageAssignmentsPopup
                  dealData={dealData}
                  currentAssignmentId={assignmentData.id}
                  isOpen={manageAssignmentsPopup === dealData.id.toString()}
                  onClickOutside={onCloseHandler}
                  onClickClose={onCloseHandler}
                />
              </div>
            </Fragment>
          }
          {roles.isClient &&
            <Fragment>
              <div className="line first-row first-column">
                <span className="category margin-right">{t(`category:${category}`)}</span>
                <strong className="reference">{dealData.reference || ''}</strong>
              </div>
              <div className="line first-row second-column"></div>
              <div className="line right first-row third-column">
                <span className="number background">{`#${dealData.id}`}</span>
              </div>
            </Fragment>
          }
          <div className="line second-row first-column-span">
            <h3 className="heading black">{dealData.address}</h3>
          </div>
          <div className="line right second-row third-column">
            <strong className="title black">{formatPrice(applyFeeDiscount(dealData.feePrice))}</strong>
          </div>
        </div>
        <div className={!isEditingCategory(category) ? 'wrapper-three-columns' : 'wrapper-two-columns'}>
          {roles.isAdmin && AssignmentDTOIsAdministratorDTO(assignmentData) && !!assignmentData.client &&
            <Fragment>
              <div className="line first-column first-row">
                <strong className="title">{t('client')}</strong>
              </div>
              <div className="line first-column second-row">
                <strong className="name black">
                  {assignmentData.client?.name}
                </strong>
                {!!assignmentData.client?.phone &&
                  <span className="group">
                    <span className="separate-left">
                      <a href={`tel:${assignmentData.client?.phone}`}>
                        <Icon className="small" icon={IconType.PHONE} />
                        <span>{assignmentData.client?.phone}</span>
                      </a>
                    </span>
                  </span>
                }
                {!!assignmentData.client?.email &&
                  <span className="group">
                    <span className="separate-left">
                      <a href={`mailto:${assignmentData.client?.email}`}>
                        <Icon className="small" icon={IconType.ENVELOPE} />
                        <span>{assignmentData.client?.email}</span>
                      </a>
                    </span>
                  </span>
                }
              </div>
            </Fragment>
          }
          {roles.isAdmin && AssignmentDTOIsAdministratorDTO(assignmentData) && !!assignmentData.client?.organizationName &&
            <Fragment>
              <div className="line second-column first-row">
                <strong className="title">{t('client_organization')}</strong>
              </div>
              <div className="line vertical-align-start second-column second-row">
                <strong className="name black">{assignmentData.client?.organizationName}</strong>
              </div>
            </Fragment>
          }
          {!isEditingCategory(category) && DealDTOOrAssignmentDTOIsDealDTO(dealData) && !!dealData.contactPerson.name &&
            <Fragment>
              <div className="line third-column first-row">
                {roles.isAdmin ?
                  <strong className="title">{t('coordination')}</strong>
                  :
                  <strong className="caption">{t('coordination')}</strong>
                }
              </div>
              <div className="line vertical-align-start third-column second-row">
                <Fragment>
                  <div className="name">
                    <span className="margin-right">{t('deal_assignment:contact_on_site')}</span>
                    <strong className="black">{dealData.contactPerson.name}</strong>
                  </div>
                  {!!dealData.contactPerson.phone &&
                    <span className="group">
                      <span className="separate-left">
                        <a href={`tel:${dealData.contactPerson.phone}`}>
                          <Icon className="small" icon={IconType.PHONE} />
                          <span>{dealData.contactPerson.phone}</span>
                        </a>
                      </span>
                    </span>
                  }
                  {!!dealData.contactPerson.email &&
                    <span className="group">
                      <span className="separate-left">
                        <a href={`mailto:${dealData.contactPerson.email}`}>
                          <Icon className="small" icon={IconType.ENVELOPE} />
                          <span>{dealData.contactPerson.email}</span>
                        </a>
                      </span>
                    </span>
                  }
                </Fragment>
              </div>
            </Fragment>
          }
        </div>
      </div>
    </div>
  )
}
