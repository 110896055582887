import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { Stack, useMediaQuery } from '@mui/material'
import { useGalleryAssignment, useGalleryDeal, useGalleryProduct, useGalleryVirtualVisit } from '../../contexts'

import { AssignmentStage } from 'constants/assignment'
import { ClientGalleryDocuments } from '../ClientGalleryDocuments'
import { ClientGalleryEmptyContent } from '../ClientGalleryEmptyContent'
import { ClientGalleryPhoto } from '../ClientGalleryPhoto'
import { ClientGalleryVirtualVisit } from '../ClientGalleryVirtualVisit'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { Tab } from '../_main/ClientGallery.controller'
import { useTranslation } from 'react-i18next'

export const AssignmentStageWithDocumentSendToClient = new Set([
  AssignmentStage.DOCUMENTS_SENT_TO_CLIENT,
  AssignmentStage.VISUALS_SENT_TO_CLIENT
])

/**
 * @interface Props
 */
interface Props {
  /** Set current active tab */
  setActiveTab: Dispatch<SetStateAction<Tab>>
}

/**
 * Client gallery content.
 *
 * @example <ClientGalleryContent />
 */
export const ClientGalleryContent: FC<Props> = ({ setActiveTab }) => {
  const { t } = useTranslation(['gallery'])

  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)
  const { isVirtualVisit, isDocumentProduct } = useGalleryProduct()
  const { virtualVisitID } = useGalleryVirtualVisit()
  const { assignmentStage, isDocumentWithValidateDraft } = useGalleryAssignment()
  const { requiresPowerOfAttorneyDocuments, totalRequestCountData } = useGalleryDeal()

  const isVisualSendToClient = useMemo(() => assignmentStage === AssignmentStage.VISUALS_SENT_TO_CLIENT, [assignmentStage])
  const isDocSendToClient = useMemo(() => assignmentStage && AssignmentStageWithDocumentSendToClient.has(assignmentStage), [assignmentStage])
  const isWaitingPoADoc = useMemo(() => assignmentStage === AssignmentStage.WAITING_FOR_POWER_OF_ATTORNEY_DOCUMENTS, [assignmentStage])
  const isDocumentInStageProgress = useMemo(() =>
    assignmentStage === AssignmentStage.WAITING_FOR_CLIENT_TO_UPLOAD_DOCUMENTS
    || assignmentStage === AssignmentStage.REQUEST_SENT_TO_PROCESSOR
    || assignmentStage === AssignmentStage.DOCUMENTS_RECEIVED_FROM_PROCESSOR, [assignmentStage])

  const isShowDefaultInProgressContent = useMemo(() => {
    if (isVisualSendToClient) return false
    if (isDocSendToClient || isWaitingPoADoc) return false
    if (isDocSendToClient || isDocumentWithValidateDraft) return false

    return true
  }, [isDocSendToClient, isDocumentWithValidateDraft, isVisualSendToClient, isWaitingPoADoc])

  const hasMessageRequests = useMemo(() => {
    if (!isDocumentProduct) return false
    if (!isDocumentInStageProgress) return false
    if (isDocSendToClient) return false

    const hasOpenRequests = !!totalRequestCountData && totalRequestCountData > 0

    return hasOpenRequests && assignmentStage === AssignmentStage.WAITING_FOR_CLIENT_TO_UPLOAD_DOCUMENTS
  }, [assignmentStage, isDocSendToClient, isDocumentInStageProgress, isDocumentProduct, totalRequestCountData])

  return (
    <Stack width="100%" paddingTop={isMobileView ? '10rem' : '0'}>

      {/** ASSIGNMENT NOT DELIVERED TO CLIENT */}
      {isShowDefaultInProgressContent && !hasMessageRequests && (
        <ClientGalleryEmptyContent
          title={t('client_gallery.empty')}
          buttonText={t('client_gallery.empty_action')}
          onButtonClick={() => setActiveTab(Tab.ORDER_INFO)}
        />
      )}

      {/** ASSIGNMENT NOT DELIVERED TO CLIENT AND HAS REQUESTS. Documents */}
      {hasMessageRequests &&
        <ClientGalleryEmptyContent
          title={t('client_gallery.available_requests.title')}
          description={t('client_gallery.available_requests.description')}
          buttonText={t('client_gallery.available_requests.see_request')}
          onButtonClick={() => setActiveTab(Tab.REQUESTS)}
        />
      }

      {/** ASSIGNMENT DELIVERED TO CLIENT. Photo, staging, video... etc */}
      {isVisualSendToClient && !isVirtualVisit && !isDocumentProduct && <ClientGalleryPhoto />}

      {/** ASSIGNMENT DELIVERED TO CLIENT. Matterport - Virtual visit */}
      {isVisualSendToClient && isVirtualVisit && !!virtualVisitID && (
        <ClientGalleryVirtualVisit />
      )}

      {/** ASSIGNMENT WAITING FOR PoA UPLOAD. Documents */}
      {isDocumentProduct && isWaitingPoADoc && requiresPowerOfAttorneyDocuments &&
        <ClientGalleryEmptyContent
          title={t('client_gallery.empty_waiting_power_of_attorney')}
          description={t('client_gallery.empty_waiting_power_of_attorney_description')}
          buttonText={t('client_gallery.empty_waiting_power_of_attorney_action')}
          onButtonClick={() => setActiveTab(Tab.ORDER_INFO)}
        />
      }

      {/** ASSIGNMENT DELIVERED TO CLIENT. Documents */}
      {(isDocSendToClient || isDocumentWithValidateDraft) && isDocumentProduct && <ClientGalleryDocuments />}

    </Stack>
  )
}
