import { Chip, ChipProps, ThemeProvider } from '@mui/material'
import React, { ReactElement } from 'react'

import { BadgeMUITheme } from 'constants/styling/badgeTheme'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'

/**
 * @typedef BadgeSize Available size
 */
export type BadgeSize = 'xs' | 'sm' | 'md' | 'lg'

/**
 * @typedef BadgeVariant Available variant
 */
export type BadgeVariant = 'filled' | 'outlined'

/**
 * @typedef BadgeColor Available color
 */
export type BadgeColor = 'gray' | 'darkGray' | 'beige' | 'blue' | 'coral' | 'green' | 'orange'

/**
 * @interface Props Input properties
 */
interface Props extends ChipProps {
  /** The label of the badge. */
  label?: string
  /** The size of the badge. */
  size?: BadgeSize
  /** The variant style of the badge. */
  variant?: BadgeVariant,
  /** The color set of the badge. */
  color?: BadgeColor
  /** The icon on the left side of the badge label. */
  icon?: ReactElement
  /** OnClick action on delete icon */
  onDelete?: () => void
}

/**
 * @component MUIBadge 
 * @example
 * <MUIBadge label="Chips" size="sm" color="blue" />
 */

export const MUIBadge: React.FC<Props> = ({
  label,
  size = 'md',
  variant = 'outlined',
  color = 'gray',
  icon,
  onDelete,
  ...props
}) =>
  <ThemeProvider theme={BadgeMUITheme}>
    <Chip
      label={label}
      size={size}
      variant={variant}
      color={color}
      onDelete={onDelete}
      deleteIcon={<ClearRoundedIcon />}
      icon={icon}
      {...props}
    />
  </ThemeProvider>
