import '../../Gallery.sass'

import { APIRequestErrorType, APIRequestState } from 'constants/API'
import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { AssignmentDetailsCard, AutomateOrderCard, ChangeRemunerationDrawer, CreativeAssignmentCard, GalleryAdminCommentSection, GalleryBreadcrumbs, GalleryDownloadStatus, GalleryProductFeedback, GalleryUploadErrorPopup, GalleryVirtualVisitInstructions, SubmitAssignmentModal, UpsellPayment, VirtualVisitHostingInfo, VisualDownloadPopup } from '../../../common'
import { Color, ColorClass, IconType } from 'constants/assets'
import { ESProduct, ProductKind } from 'constants/product'
import { ErrorMessageType, errorMessageType, getDownloadErrorsString } from 'utils/helpers'
import { FC, Fragment, KeyboardEvent, MouseEvent as ReactMouseEvent, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { KeyboardEventKey, ZERO_DECIMAL } from 'constants/misc'
import { POST_WATERMARK, VisualFileType } from 'constants/visual'
import { RootStore, UploadImage } from 'models/redux'
import { Trans, useTranslation } from 'react-i18next'
import { TypeOfAvailableOrderings, TypeOfAvailableVirtualVisitLinkTypes, VisualTypeSelectEnumType, useGallery, useGalleryAdditionalVisuals, useGalleryAssignment, useGalleryComments, useGalleryConstants, useGalleryDeal, useGalleryDocumentsContext, useGalleryEditorProduct, useGalleryOrder, useGalleryProduct, useGalleryVirtualVisit, useGalleryVisualSelection, useGalleryVisualType, useGalleryVisuals, useGalleryVisualsMeta } from '../../contexts'
import { decimalFromBig, formatPrice } from 'utils/price'
import { deleteVisual, uploadVisual, uploadVisualReplacement } from 'redux/Individual/Visual/LoadVisual'
import { purgeSelectVisuals, selectVisuals } from 'redux/Individual/Visual/SelectVisuals'
import { useDispatch, useSelector } from 'react-redux'
import { useSubmitAssignment, useUpdateVirtualVisit } from 'dataQueries'

import { ActionTypeAPIData } from 'constants/redux'
import { AdminGalleryDocumentsPanel } from '../AdminGalleryDocuments'
import { AdminGalleryMessageThreads } from '../AdminGalleryMessageThreads'
import { ArchiveVisualsTag } from 'redux/Individual/Visual/ArchiveVisuals'
import AutoFireActionInput from 'components/common/AutoFireActionInput/AutoFireActionInput'
import { Badge } from 'components/common/Badges'
import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import { Box } from '@mui/material'
import Button from 'components/common/Button/Button'
import { CopyButton } from 'components/common/CopyButton'
import { DocumentGalleryContent } from 'components/common/DocumentGalleryContent'
import DynamicQueryContent from 'components/common/DynamicQueryContent/DynamicQueryContent'
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage'
import { FeatureFlag } from 'utils/featureFlags/featureFlags'
import FetchedContent from 'components/common/FetchedContent/FetchedContent'
import GalleryImage from 'components/common/GalleryImage/GalleryImage'
import Icon from 'components/common/Icon/Icon'
import { Link } from 'react-router-dom'
import { MUIButton } from 'components/common/MUIButton'
import { MUITooltip } from 'components/common/MUITooltip'
import Modal from 'components/common/Modals/Modal/Modal'
import { Nullable } from 'models/helpers'
import { OrderDetail } from 'components/common/OrderDetail/OrderDetail'
import { PIPEDRIVE_INFINITY } from 'constants/pipedrive'
import { Path } from 'constants/router'
import ProgressCard from 'components/common/ProgressCard/ProgressCard'
import { QueryStatus } from 'components/common/QueryStatus'
import ReactLoading from 'react-loading'
import { SendToClient } from '../SendToClient'
import { StripeError } from '@stripe/stripe-js'
import TabLinkBar from 'components/common/TabLinkBar/TabLinkBar'
import TriangleIcon from 'components/common/TriangleIcon/TriangleIcon'
import { archiveVisuals } from 'redux/Individual/Visual/ArchiveVisuals/ArchiveVisuals.actions'
import closeButtonStyles from 'components/styles/close-button.module.sass'
import { editMissionAdminCommentsForEditor } from 'redux/Individual/Admin/EditMissionAdminCommentsForEditor'
import { getSubscriptionPricingLink } from 'utils/localization/subscriptionPricing'
import i18n from 'translations/i18n'
import { listVisuals } from 'redux/Individual/Visual/ListVisuals'
import { multilineText } from 'utils/formatting'
import { supportEmail } from 'constants/contacts'
import { useActionPopup } from 'utils/hooks/useActionPopup'
import { useAuth0 } from 'utils/auth'
import { useDraggingFile } from 'utils/hooks/useDraggingFile'
import { useFlag } from '@unleash/proxy-client-react'
import { useImageRegex } from 'utils/hooks'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useUserData } from 'components/contexts/UserDataContext'
import { virtualFurnitureHomeLink } from 'constants/staticLinks'
import { wrapGrid } from 'animate-css-grid'

export const AdministratorGalleryController: FC = () => {
  const queryClient = useQueryClient()

  const allowResumableUploads = useFlag(FeatureFlag.ALLOW_RESUMABLE_UPLOADS)
  const uploadType = allowResumableUploads ? 'resumable' : 'standard'

  const { assignmentId } = useGallery()

  const {
    NONE_EDITOR_PRODUCT,
    VisualTypeSelectEnum,
    AvailableFileTypes,
    AvailableOrderings,
    AvailableVirtualVisitLinkTypes,
    visualTypeIconMap,
    stagesUnlockingSendAssignmentVisualsToClient,
    stagesUnlockingCreativeSendAssignmentVisualsToAdmin,
    productKindsUnlockingMatterportLinks,
    beforeUnloadHandler,
  } = useGalleryConstants()

  const {
    assignment,
    assignmentData,
    product,
    assignmentStage,
    canSelectVisualsForStaging,
    isBKBNDecide,
    isFeedbackButtonAllowed,
    logGalleryEvent,
  } = useGalleryAssignment()

  const {
    dealData,
    dealAssignmentsForTab,
  } = useGalleryDeal()

  const {
    visualTypeSelect,
    setVisualTypeSelect,
    originalType,
    thumbnailType,
    webType,
    normalizedOriginalType,
    fileTypes,
    setFileTypes,
    transformTypes,
    transformAdminTypes,
  } = useGalleryVisualType()

  const {
    isVirtualVisit,
    isFloorPlan,
    isVirtualVisitMatterport,
    isDocumentProduct
  } = useGalleryProduct()

  const {
    setCurrentOrder,
    currentAdjustedOrder,
    sortEntriesFunction,
  } = useGalleryOrder()

  const {
    allVisuals,
    allVisualsFilenames,
    purchasedVisualsKeys,
    purchasedVisualsCount,
    purchasedVisualsExist,
    uploadMinCount,
    uploadMaxCount,
    selectMaxValue,
    selectedVisualsForStagingCount,
    selectedVisualsForStagingExist,
    visualsMetadataMap,
  } = useGalleryVisualsMeta()

  const {
    downloadVisuals,
    downloadVisualsEntries,
    uploadVisualsEntries,
    downloadVisualsKeys,
    downloadVisualsCount,
    uploadVisualsCount,
    uploadVisualsOriginalFilenames,
    visualsCount,
    listedVisuals,
    visualsKeyToOriginalNameDictionary,
    downloadVisualsWithErrorEntries,
  } = useGalleryVisuals()

  const {
    virtualVisitShareLinkRef,
    virtualVisitEmbedCodeRef,
    selectedVirtualVisitLinkType,
    setSelectedVirtualVisitLinkType,
    virtualVisitInput,
    setVirtualVisitInput,
    virtualVisitID,
    virtualVisitShareLink,
    virtualVisitExpirationDate,
    virtualVisitExpired,
    virtualVisitMatterportEmail,
    virtualVisitEmbedCode,
  } = useGalleryVirtualVisit()

  const {
    selected,
    setSelected,
    canSelectVisuals,
    selectableVisualsCount,
    selectAll,
    selectAllPurchased,
    deselectAll,
    toggleSelectOnImage,
    selectedNotPurchasedVisualsCount,
    selectVisualsRequest,
  } = useGalleryVisualSelection()

  const {
    currency,
    pricePerAdditionalVisual,
    vat,
    discount,
    selectedAdditionalVisualsCount,
    totalForAdditionalVisualsBeforeDiscount,
    totalForAdditionalVisualsAfterDiscount,
    totalVatForAdditionalVisuals,
    totalAfterVatForAdditionalVisuals,
    isAdditionalVisualPurchaseDisabled,
    additionalVisualProductId,
    isAdditionalVisualsPurchaseModalOpen,
    setIsAdditionalVisualsPurchaseModalOpen,
  } = useGalleryAdditionalVisuals()

  const {
    adminCommentsForEditor,
    setAdminCommentsForEditor,
    adminCommentsForEditorResponse,
  } = useGalleryComments()

  const {
    editorProducts,
    editorProduct,
    setEditorProduct,
    sendAssignmentVisualsToEditor,
    canSendAssignmentVisualsToEditor,
    assignmentVisualsAlreadySentToEditor,
    cannotSendAssignmentVisualsToEditorInfo,
    canEditEditorCommentsAndProducts,
    initiateSendAssignmentVisualsToEditor,
  } = useGalleryEditorProduct()

  const { documentTypeSelect } = useGalleryDocumentsContext()

  const dispatch = useDispatch()
  const { t } = useTranslation(['gallery', 'deal_assignment', 'common'])
  const { spawnSuccessToast } = useSnackbar()

  // REACT QUERIES
  const submitAssignment = useSubmitAssignment()
  const updateVirtualVisit = useUpdateVirtualVisit()

  const { showConfirm } = useActionPopup()
  const { roles } = useAuth0()
  const {
    baseUserData,
    clientData,
    creativeData,
    nonPersonalUserWorkspaces,
  } = useUserData()

  const isDraggingFile = useDraggingFile()
  const [visualsGrid, setVisualsGrid] = useState<HTMLDivElement | undefined>(undefined)
  const setVisualsGridRef = useCallback((node: HTMLDivElement) => {
    if (node) setVisualsGrid(node)
  }, [])

  const visualUploadingTriggered = useRef(false)

  const [isLightboxModalOpen, setLightboxModalOpen] = useState(false)
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false)
  const [expandedImageName, setExpandedImageName] = useState<string | undefined>(undefined)
  const [isSubmitAssignmentOpen, setIsSubmitAssignmentOpen] = useState(false)
  const [isUploadErrorPopupOpen, setIsUploadErrorPopupOpen] = useState(false)

  const initiateDownloadVisualsArchive = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.ARCHIVE_VISUALS]?.[assignmentId]?.[ArchiveVisualsTag.CLIENT_ARCHIVE])
  const visualsArchiveDownloadJobId = useMemo(() => (initiateDownloadVisualsArchive && initiateDownloadVisualsArchive.data?.id) || '', [initiateDownloadVisualsArchive])
  const downloadVisualsArchive = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.DOWNLOAD_ARCHIVE]?.[visualsArchiveDownloadJobId])
  const archiveAdmin = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.ARCHIVE_VISUALS]?.[assignmentId]?.[ArchiveVisualsTag.ADMIN_ARCHIVE])
  const visualsArchiveAdminDownloadJobId = useMemo(() => (archiveAdmin && archiveAdmin.data?.id) || '', [archiveAdmin])
  const downloadVisualsArchiveAdmin = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.DOWNLOAD_ARCHIVE]?.[visualsArchiveAdminDownloadJobId])

  // Admin functionalities
  const visualsRecentlySubmited = useMemo(() => (submitAssignment.isPending || submitAssignment.isSuccess), [submitAssignment])
  const canSubmitVisualsForStaging = useMemo(() => !visualsRecentlySubmited && selected.size !== 0 && uploadVisualsCount === 0, [visualsRecentlySubmited, selected.size, uploadVisualsCount])
  const cannotSubmitVisualsForStagingInfo = useMemo(() => {
    if (visualsRecentlySubmited) return t('assignment_submitted')
    else if (selected.size === 0) return t('no_visuals_selected')
    else return ''
  }, [selected.size, visualsRecentlySubmited, t])

  const canSendAssignmentVisualsToClient = useMemo(() => {
    return ((isVirtualVisit && !!virtualVisitID) || (visualsCount >= 1)) &&
      !!assignmentStage &&
      stagesUnlockingSendAssignmentVisualsToClient.has(assignmentStage)
  }, [assignmentStage, isVirtualVisit, stagesUnlockingSendAssignmentVisualsToClient, virtualVisitID, visualsCount])

  const canDeleteVisuals = useMemo(() => !isVirtualVisit, [isVirtualVisit])
  const canUploadVisuals = useMemo(() => !isVirtualVisit && originalType !== POST_WATERMARK, [isVirtualVisit, originalType])
  const canReplaceVisuals = useMemo(() => originalType !== POST_WATERMARK, [originalType])
  const canSubmitVisuals = useMemo(() => {
    if (!assignmentStage) return false
    if (!stagesUnlockingCreativeSendAssignmentVisualsToAdmin.has(assignmentStage)) return false
    if (!!product) return !productKindsUnlockingMatterportLinks.has(product?.kind)
    return true
  }, [product, productKindsUnlockingMatterportLinks, assignmentStage, stagesUnlockingCreativeSendAssignmentVisualsToAdmin])
  const downloadArchiveUrl = useMemo(() => downloadVisualsArchive?.data?.url, [downloadVisualsArchive])
  const downloadArchiveAdminUrl = useMemo(() => downloadVisualsArchiveAdmin?.data?.url, [downloadVisualsArchiveAdmin])
  const showMatterportTransferInfo = useMemo(() => !!assignmentStage && stagesUnlockingSendAssignmentVisualsToClient.has(assignmentStage) && isVirtualVisit && virtualVisitMatterportEmail, [assignmentStage, stagesUnlockingSendAssignmentVisualsToClient, isVirtualVisit, virtualVisitMatterportEmail])
  const archiveDownloadPending = useMemo(() => {
    if (initiateDownloadVisualsArchive) {
      if (initiateDownloadVisualsArchive.state === APIRequestState.ERROR) return false
      if (downloadVisualsArchive?.state === APIRequestState.ERROR) return false
      if (downloadVisualsArchive?.state === APIRequestState.OK) return false
      return true
    } else return false
  }, [initiateDownloadVisualsArchive, downloadVisualsArchive])
  const archiveAdminDownloadPending = useMemo(() => {
    if (archiveAdmin) {
      if (archiveAdmin.state === APIRequestState.ERROR) return false
      if (downloadVisualsArchiveAdmin?.state === APIRequestState.ERROR) return false
      if (downloadVisualsArchiveAdmin?.state === APIRequestState.OK) return false
      return true
    } else return false
  }, [archiveAdmin, downloadVisualsArchiveAdmin])
  const availableVisualTypes = useMemo(() => {
    return Object.values(VisualTypeSelectEnum).map(type => VisualTypeSelectEnum[type as VisualTypeSelectEnumType])
  }, [VisualTypeSelectEnum])

  // Virtual visit
  const showVirtualVisitInstructions = useMemo(() => {
    if ((product?.kind === ProductKind.HD || product?.kind === ProductKind.SD) && originalType === VisualFileType.POST) return false

    return true
  }, [originalType, product?.kind])

  // Grid animations
  useLayoutEffect(() => {
    if (visualsGrid) wrapGrid(visualsGrid)
  }, [visualsGrid])

  useEffect(() => {
    if (uploadVisualsCount > 0) {
      visualUploadingTriggered.current = true
    }
  }, [uploadVisualsCount])

  useEffect(() => {
    // use uploadRef to check if the download process is after uploading or not. 
    // If so, wait until all the download visuals process finish then show the error msg popup if there's error in downloadEntries.
    if (visualUploadingTriggered.current && !isUploadErrorPopupOpen && downloadVisualsWithErrorEntries.length && (downloadVisualsCount === visualsCount)) {
      setIsUploadErrorPopupOpen(true)
      logGalleryEvent(AnalyticsEvent.UPLOAD_ERROR, {
        userEmail: creativeData?.email,
        userType: 'admin',
        errorReason: getDownloadErrorsString(downloadVisualsWithErrorEntries, thumbnailType),
      })
    }
  }, [creativeData?.email, downloadVisualsCount, downloadVisualsWithErrorEntries, downloadVisualsWithErrorEntries.length, isUploadErrorPopupOpen, logGalleryEvent, thumbnailType, visualsCount])

  const closeLightboxModal = useCallback((e?: ReactMouseEvent<Element, MouseEvent>) => {
    if (e) e.preventDefault()
    setLightboxModalOpen(false)
    setTimeout(() => {
      setExpandedImageName(undefined)
    }, 500)
  }, [])

  const closeDownloadModal = useCallback((e?: ReactMouseEvent<Element, MouseEvent>) => {
    if (e) e.preventDefault()
    if (archiveDownloadPending) return
    setDownloadModalOpen(false)
  }, [archiveDownloadPending])

  const onFloorPlannerLinkClick = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()

    logAnalyticsEvent(AnalyticsEvent.FLOOR_PLANNER_LINK_CLICKED, {
      galleryType: 'ADMIN',
      assignmentId: assignmentData?.id,
      productCategory: product?.category,
      productKind: product?.kind,
    })
  }, [assignmentData?.id, product?.category, product?.kind])

  const expandedImage = useMemo(() => {
    if (!expandedImageName) return undefined
    if (!downloadVisuals?.[expandedImageName]) return undefined
    return downloadVisuals[expandedImageName]
  }, [expandedImageName, downloadVisuals])

  const expandedImageWebType = useMemo(() => expandedImage?.[webType], [expandedImage, webType])

  const switchExpandedImage = useCallback((direction: 'next' | 'previous') => {
    if (!expandedImage) return
    if (!downloadVisuals) return

    const isDirectionNext = direction === 'next'

    if (downloadVisualsCount === 0) return
    let chooseThisOne = false

    for (let i = isDirectionNext ? 0 : downloadVisualsCount - 1; isDirectionNext ? i < downloadVisualsCount : i >= 0; isDirectionNext ? i++ : i--) {
      if (chooseThisOne) return setExpandedImageName(downloadVisualsKeys[i])
      if (expandedImageName === downloadVisualsKeys[i]) chooseThisOne = true
    }

    return setExpandedImageName(isDirectionNext ? downloadVisualsKeys[0] : downloadVisualsKeys[downloadVisualsCount - 1])
  }, [expandedImage, expandedImageName, downloadVisuals, downloadVisualsKeys, downloadVisualsCount])

  const deleteSingleVisual = useCallback((visualKey: string) => {
    if (!canDeleteVisuals) return
    if (!visualKey) return
    if (selected.has(visualKey)) {
      selected.delete(visualKey)
      setSelected(new Set(selected))
    }
    dispatch(deleteVisual(assignmentId, visualKey, normalizedOriginalType))
  }, [canDeleteVisuals, selected, dispatch, assignmentId, normalizedOriginalType, setSelected])

  const deleteAllVisuals = useCallback(async () => {
    if (!canDeleteVisuals) return
    if (!(await showConfirm(t('delete_all_prompt')))) return
    if (downloadVisuals) {
      for (let downloadKey of downloadVisualsKeys) {
        dispatch(deleteVisual(assignmentId, downloadKey, normalizedOriginalType))
      }
    }
    if (uploadVisualsEntries) {
      for (let entry of uploadVisualsEntries) {
        const uploadImage = entry[1]?.[normalizedOriginalType]
        if (!uploadImage?.file?.name) continue
        dispatch(deleteVisual(assignmentId, uploadImage.file.name, normalizedOriginalType))
      }
    }
  }, [t, canDeleteVisuals, downloadVisuals, uploadVisualsEntries, assignmentId, dispatch, downloadVisualsKeys, normalizedOriginalType, showConfirm])

  const lightboxModalKeyDownHandler = useCallback((e: KeyboardEvent) => {
    if (isDownloadModalOpen) return
    switch (e.key) {
      // escape
      case KeyboardEventKey.ESCAPE:
        return closeLightboxModal()
      // spacebar
      case KeyboardEventKey.SPACE:
        if (!expandedImage || !expandedImageName) return
        return toggleSelectOnImage(expandedImageName)
      // left key
      case KeyboardEventKey.ARROW_LEFT:
        return switchExpandedImage('previous')
      // right key
      case KeyboardEventKey.ARROW_RIGHT:
        return switchExpandedImage('next')
    }
  }, [isDownloadModalOpen, expandedImage, expandedImageName, closeLightboxModal, toggleSelectOnImage, switchExpandedImage])

  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections && fileRejections.length > 0) {
      const formats: string[] = []
      for (let fileRejection of fileRejections) {
        const split = fileRejection.file.name.split('.')
        if (split.length < 2) formats.push(fileRejection.file.name)
        else formats.push(split.slice(1).join('.'))
      }
      const formatsString = Array.from(new Set(formats).keys()).join(', ')
      alert(`${t('unsupported_file_format')}\n${formatsString}`)
    }

    const duplicateFiles = []
    for (let file of acceptedFiles) {
      if (!uploadVisualsOriginalFilenames.includes(file.name)) {
        dispatch(uploadVisual(assignmentId, file, normalizedOriginalType, uploadType))
      } else duplicateFiles.push(file.name)
    }
    if (duplicateFiles.length > 0) {
      alert(t('duplicate_file_alert', { count: duplicateFiles.length, files: duplicateFiles.join(', ') }))
    }
  }, [t, dispatch, normalizedOriginalType, uploadVisualsOriginalFilenames, assignmentId, uploadType])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  // Warn about upload being in progress while leaving the browser tab
  useEffect(() => {
    if (uploadVisualsCount > 0) {
      window.addEventListener('beforeunload', beforeUnloadHandler)
      return () => {
        window.removeEventListener('beforeunload', beforeUnloadHandler)
      }
    }
  }, [beforeUnloadHandler, uploadVisualsCount])

  // Warn about archive download in progress while leaving the browser tab
  useEffect(() => {
    if (archiveDownloadPending || archiveAdminDownloadPending) {
      window.addEventListener('beforeunload', beforeUnloadHandler)
      return () => {
        window.removeEventListener('beforeunload', beforeUnloadHandler)
      }
    }
  }, [archiveDownloadPending, archiveAdminDownloadPending, beforeUnloadHandler])

  const isArchiveDisabled = useMemo(() => {
    if (initiateDownloadVisualsArchive && initiateDownloadVisualsArchive.state === APIRequestState.RUNNING) return true
    if (!product) return true
    if (purchasedVisualsExist) return false
    if (selected.size === 0) return true
    if (selectMaxValue !== PIPEDRIVE_INFINITY) {
      if (selected.size < selectMaxValue) return true
      if (selected.size > selectMaxValue && isAdditionalVisualPurchaseDisabled) return true
    }
    return false
  }, [selected, selectMaxValue, initiateDownloadVisualsArchive, product, purchasedVisualsExist, isAdditionalVisualPurchaseDisabled])

  const imageNameReplacePattern = useImageRegex(assignmentId)

  const initiateArchiveDownload = useCallback(async () => {
    if (isArchiveDisabled) return
    if (!product) return

    // If there is limit for downloading/selecting visuals, proceed with additional checks
    if (selectMaxValue !== PIPEDRIVE_INFINITY) {

      // If additional visuals are selected, open purchase modal
      if (selectedAdditionalVisualsCount > 0) {
        setIsAdditionalVisualsPurchaseModalOpen(true)
        return
      }

      // If no additional visuals are selected and no visuals have been purchased yet
      // check, that required amount have been selected, show warning propmt and select those visuals with immediate download after selection completes
      if (!purchasedVisualsExist) {
        if (selected.size !== selectMaxValue || !(await showConfirm(t('purchase_warning_prompt'), { title: t('purchase_warning_prompt_title') }))) return
        dispatch(selectVisuals(assignmentId, transformTypes(), Array.from(selected), normalizedOriginalType, product.id, undefined, undefined, true))
        // Open visual download popup
        setDownloadModalOpen(true)
        return
      }
    }

    // If there is no limit for downloading/selecting visuals, but no visuals have been purchased, select all visuals and download those selected
    if (!purchasedVisualsExist) {
      if (!allVisualsFilenames.length) console.warn('No visual filenames found!')
      dispatch(selectVisuals(assignmentId, transformTypes(), allVisualsFilenames, normalizedOriginalType, product.id, undefined, undefined, true, Array.from(selected)))
      // Open visual download popup
      setDownloadModalOpen(true)
      return
    }

    // If there is no limit for downloading/selecting visuals
    // OR no additional visuals are selected and visuals have already been purchased
    // Trigger downloading of selected visuals
    dispatch(archiveVisuals(assignmentId, transformTypes(), Array.from(selected), ArchiveVisualsTag.CLIENT_ARCHIVE, false))

    // Open visual download popup
    setDownloadModalOpen(true)

    // Track archive download trigger
    logGalleryEvent(AnalyticsEvent.GALLERY_DOWNLOAD_TRIGGERED, {
      role: Array.from(roles.all.values()).join(','),
      workspaceIDs: nonPersonalUserWorkspaces.length ? nonPersonalUserWorkspaces.map(({ id }) => id).join(', ') : '',
      productCategory: product.category,
      productType: product.type,
      productKind: product.kind,
      productSegments: product.segments.join(','),
      assignmentOwnerId: dealData?.customer.id,
      assignmentOwnerEmail: dealData?.customer.email,
    })

  }, [isArchiveDisabled, product, selectMaxValue, purchasedVisualsExist, dispatch, assignmentId, transformTypes, selected, roles.all, nonPersonalUserWorkspaces, selectedAdditionalVisualsCount, setIsAdditionalVisualsPurchaseModalOpen, showConfirm, t, normalizedOriginalType, allVisualsFilenames, dealData?.customer.email, dealData?.customer.id, logGalleryEvent])

  // Relist visuals if stripe payment fails and user leaves purchase modal without finishing the payment
  const handleAfterAdditionalPurchaseModalClose = useCallback((stripeError: StripeError | null) => {
    if (stripeError) {
      dispatch(listVisuals(assignmentId, normalizedOriginalType))
    }

    dispatch(purgeSelectVisuals(assignmentId))
  }, [dispatch, assignmentId, normalizedOriginalType])

  const initiateArchiveAdminDownloadAll = useCallback(() => {
    if (downloadVisualsCount === 0) return
    dispatch(archiveVisuals(assignmentId, transformAdminTypes(), downloadVisualsKeys, ArchiveVisualsTag.ADMIN_ARCHIVE, true))
  }, [downloadVisualsCount, dispatch, assignmentId, transformAdminTypes, downloadVisualsKeys])

  const initiateArchiveAdminDownloadSelected = useCallback(() => {
    if (selected.size === 0) return
    dispatch(archiveVisuals(assignmentId, transformAdminTypes(), Array.from(selected), ArchiveVisualsTag.ADMIN_ARCHIVE, true))
  }, [selected, dispatch, assignmentId, transformAdminTypes])

  const initiateArchiveAdminDownloadPurchased = useCallback(() => {
    if (purchasedVisualsCount === 0) return
    dispatch(archiveVisuals(assignmentId, transformAdminTypes(), Array.from(purchasedVisualsKeys), ArchiveVisualsTag.ADMIN_ARCHIVE, true))
  }, [purchasedVisualsCount, dispatch, assignmentId, transformAdminTypes, purchasedVisualsKeys])

  const initiateSubmitAssignment = useCallback(async (creativeNote: string) => {
    if (!canSubmitVisuals) return
    if (visualsRecentlySubmited) return
    if (!product) return
    if (isVirtualVisit) {
      submitAssignment.mutate({ assignmentId, filenames: [], creativeComment: creativeNote, product, minUploadCount: uploadMinCount })
    } else {
      if (uploadVisualsCount > 0) return
      if (selected.size === 0) return

      submitAssignment.mutate({ assignmentId, filenames: Array.from(selected), creativeComment: creativeNote, product, minUploadCount: uploadMinCount })
    }
  }, [canSubmitVisuals, visualsRecentlySubmited, product, isVirtualVisit, assignmentId, uploadMinCount, uploadVisualsCount, selected, submitAssignment])

  const errorElement = useCallback((errorType: APIRequestErrorType) => (
    <div className="page-error">
      <h1 className="error-heading">{t('forbidden_access_heading')}</h1>
      {(errorType === APIRequestErrorType.FORBIDDEN_ERROR || APIRequestErrorType.PAYMENT_REQUIRED_ERROR) ?
        <div>
          <Trans t={t} i18nKey="forbidden_access">
            <span>
              <a href={getSubscriptionPricingLink(i18n.language)} target="_blank" rel="noreferrer" className="underline">&nbsp;</a>
            </span>
          </Trans>
          <BlockInfo className="margin-top">
            <Trans t={t} i18nKey={'forbidden_contact_us'}>
              <span>
                <a href={`mailto:${supportEmail}`} className="underline">&nbsp;</a>
              </span>
            </Trans>
          </BlockInfo>
        </div>
        :
        <p><ErrorMessage error_type={errorType} /></p>
      }
      <Link to={Path.INDEX}>
        <Button className="back-button">{t('back_to_my_orders')}</Button>
      </Link>
    </div>
  ), [t])

  const preloadListing = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = listedVisuals.map(visual => {
      const filename = visual.name.replace(imageNameReplacePattern, '') + (visual.originalName ? ` | ${visual.originalName}` : '')
      const reactKey = visual.name
      return [visual.name, reactKey, (
        <div className="image-wrap">
          <GalleryImage
            image={undefined}
            label={filename}
            wait={true}
            faded={true}
            expandable={false}
            purchased={purchasedVisualsKeys.has(visual.name)}
            visualMetadata={visualsMetadataMap[visual.name]}
            deletable={canDeleteVisuals}
            onDelete={() => visual.name && deleteSingleVisual(visual.name)}
            onFloorPlannerLinkClick={onFloorPlannerLinkClick}
          >
            <div className="text">
              <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner" />
              <span>{t('loading')}</span>
            </div>
          </GalleryImage>
        </div>
      )]
    })

    return tupleArray
  }, [listedVisuals, imageNameReplacePattern, purchasedVisualsKeys, visualsMetadataMap, canDeleteVisuals, onFloorPlannerLinkClick, t, deleteSingleVisual])

  const downloadListing = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = downloadVisualsEntries.map(([key, allTypes]) => {
      const img = allTypes?.[thumbnailType]
      if (!img) throw new Error(`Image does not exist for key: ${key} and visual type: ${thumbnailType}`)
      const isError = img.request.error_type !== APIRequestErrorType.NONE
      const errorMsg = img.request.error
      const isOtherMsg = errorMessageType(errorMsg) === ErrorMessageType.OTHER
      const filename = img.file?.name || ''
      const originalFilename = img.originalFilename || !!filename && visualsKeyToOriginalNameDictionary[filename] || ''
      const label = filename.replace(imageNameReplacePattern, '') + (originalFilename ? ` | ${originalFilename}` : '')
      const reactKey = key
      return [key, reactKey, (
        <div className="image-wrap">
          <GalleryImage
            image={img.signedUrl}
            label={label}
            wait={img.deleting}
            faded={img.deleting}
            deletable={canDeleteVisuals}
            onDelete={() => img.file?.name && deleteSingleVisual(img.file.name)}
            selectable={canSelectVisuals && !isError}
            selected={!isError && selected.has(img.file?.name || '')}
            visualMetadata={visualsMetadataMap[img.file?.name || '']}
            onSelect={canSelectVisuals && !isError ? e => {
              toggleSelectOnImage(img.file?.name)
            } : undefined}
            expandable={!!(webType && allTypes?.[webType]?.signedUrl)}
            purchased={purchasedVisualsKeys.has(key)}
            onClick={e => {
              setExpandedImageName(img.file?.name)
              setLightboxModalOpen(true)
            }}
            droppable={canReplaceVisuals}
            isUserDraggingFile={canReplaceVisuals && isDraggingFile}
            dropZoneOptions={{ noClick: true, multiple: false }}
            onFloorPlannerLinkClick={onFloorPlannerLinkClick}
            insideHover={canReplaceVisuals && isDraggingFile ?
              <div className="text">
                <span>{t('drop_here')}</span>
              </div>
              :
              undefined
            }
            onDrop={canReplaceVisuals ? async (acceptedFiles, rejectedFiles) => {
              if (rejectedFiles.length === 1) return window.alert(t('reject_files'))
              if (acceptedFiles.length === 0) return window.alert(t('reject_files'))
              if (rejectedFiles.length > 1) return window.alert(t('drop_only_one'))
              if (acceptedFiles.length > 1) return window.alert(t('drop_only_one'))
              if (acceptedFiles.length === 1) {
                if (!img.file?.name) return
                if (await showConfirm(t('replace_visual_prompt'))) {
                  if (!uploadVisualsOriginalFilenames.includes(img.file.name)) {
                    dispatch(uploadVisualReplacement(assignmentId, img.file.name, acceptedFiles[0], normalizedOriginalType, uploadType))
                  } else {
                    alert(t('duplicate_file_alert', { count: 1, files: img.file.name }))
                  }
                }
              }
            } : undefined}
          >
            {img.deleting ?
              <div className="text">
                <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner" />
                <span>{t('deleting')}</span>
              </div>
              :
              isError ?
                <div className="text">
                  <span>
                    <Trans t={t} i18nKey="thumbnail_failed_description">
                      <MUITooltip placement="top" content={isOtherMsg ? errorMsg : t(`image_processing_error.${errorMsg}`)}>
                        <span style={{ cursor: 'pointer' }} data-tip data-for="re-upload-tooltip">&nbsp;</span>
                      </MUITooltip>
                    </Trans>
                  </span>
                </div>
                :
                <Fragment></Fragment>
            }
          </GalleryImage>
        </div>
      )]
    })

    return tupleArray
  }, [downloadVisualsEntries, thumbnailType, visualsKeyToOriginalNameDictionary, imageNameReplacePattern, canDeleteVisuals, canSelectVisuals, selected, visualsMetadataMap, webType, purchasedVisualsKeys, canReplaceVisuals, isDraggingFile, onFloorPlannerLinkClick, t, deleteSingleVisual, toggleSelectOnImage, showConfirm, uploadVisualsOriginalFilenames, dispatch, assignmentId, normalizedOriginalType, uploadType])

  const uploadImageContent = useCallback((img: Nullable<UploadImage>, label: string) => {
    if (!img) return
    const isError = img.request.error_type !== APIRequestErrorType.NONE
    const errorMsg = img.request.error
    const isOtherMsg = errorMessageType(errorMsg) === ErrorMessageType.OTHER

    if (img.deleting) {
      return (
        <Fragment>
          <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner" />
          <span>{t('deleting')}</span>
        </Fragment>
      )
    }

    if (img.progress === 100) {
      if (isError) {
        return (
          <span>
            <Trans t={t} i18nKey="upload_failed_description">
              <MUITooltip placement="top" content={isOtherMsg ? errorMsg : t(`image_processing_error.${errorMsg}`)}>
                <span style={{ cursor: 'pointer' }} data-tip data-for="re-upload-tooltip">&nbsp;</span>
              </MUITooltip>
            </Trans>
          </span>
        )
      }

      return (
        <Fragment>
          <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner" />
          <span>{t('processing')}</span>
        </Fragment>
      )
    }

    return (
      <span>
        {img.originalFilename &&
          <Fragment>
            {label}
            <br />
          </Fragment>
        }
        {t('uploading')}: {img.progress || 0}%
      </span>
    )
  }, [t])

  const uploadListing = useCallback(() => {
    const tupleArray: [string, string, string, JSX.Element][] = uploadVisualsEntries.map(([key, allTypes]) => {
      const img = allTypes?.[normalizedOriginalType]
      if (!img) throw new Error(`Image does not exist for key: ${key} and visual type: ${normalizedOriginalType}`)
      // For sorting fresh uploads on top
      const sortKey = (img.replaces || key.match(imageNameReplacePattern)) ? key : `${assignmentId}-bkbn-ZZZZZ-${img.droppedIn}-${key}`
      const reactKey = img.originalFilename || key
      const fileName = img.file ? img.file.name.replace(imageNameReplacePattern, '') : ''
      const originalFilename = img.originalFilename
      const label = fileName ? fileName + (originalFilename ? ` | ${originalFilename}` : '') : originalFilename || ''
      return [key, reactKey, sortKey, (
        <div className="image-wrap">
          <GalleryImage
            image={undefined}
            label={label}
            wait={img.deleting}
            faded={img.deleting}
            deletable={canDeleteVisuals}
            onDelete={() => img.file?.name && deleteSingleVisual(img.file.name)}
          >
            <div className="text">
              {uploadImageContent(img, label)}
            </div>
          </GalleryImage>
        </div>
      )]
    })

    return tupleArray
  }, [uploadVisualsEntries, normalizedOriginalType, imageNameReplacePattern, assignmentId, canDeleteVisuals, uploadImageContent, deleteSingleVisual])

  const visualsListing = useCallback(() => {
    const map: Map<string, [string, JSX.Element]> = new Map()
    if (isVirtualVisit) return map

    for (let [key, reactKey, node] of preloadListing()) map.set(key, [reactKey, node])
    for (let [key, reactKey, node] of downloadListing()) map.set(key, [reactKey, node])
    for (let [key, reactKey, sortKey, node] of uploadListing()) {
      map.delete(key)
      map.set(sortKey, [reactKey, node])
    }

    const entries: [string, [string, JSX.Element]][] = Array.from(map.entries())
    const sortedEntries = entries.sort(sortEntriesFunction)

    return (
      <Fragment>
        {sortedEntries.map(([key, [reactKey, node]]) => (
          <Fragment key={reactKey}>
            {node}
          </Fragment>
        ))}
      </Fragment>
    )
  }, [isVirtualVisit, preloadListing, uploadListing, downloadListing, sortEntriesFunction])

  const fileSelection = useCallback(() => (
    <Fragment>
      <div className="part">
        <Button
          type="secondary"
          disabled={selected.size === selectableVisualsCount}
          onClick={selectAll}
          textAndIcon={true}
          className="space-right space-bottom"
        >
          <Icon icon={IconType.CHECK} />
          <span>{t('select_all')}</span>
        </Button>

        {purchasedVisualsCount > 0 &&
          <Button
            type="secondary"
            disabled={selected.size === purchasedVisualsCount}
            onClick={selectAllPurchased}
            textAndIcon={true}
            className="space-right space-bottom"
          >
            <Icon icon={IconType.CHECK} />
            <span>{t('select_all_purchased')}</span>
          </Button>
        }

        <Button
          type="secondary"
          disabled={selected.size === 0}
          onClick={deselectAll}
          textAndIcon={true}
        >
          <Icon icon={IconType.CROSS} />
          <span>{t('deselect_all')}</span>
        </Button>
      </div>
    </Fragment>
  ), [deselectAll, selectAll, selectAllPurchased, selectableVisualsCount, selected.size, purchasedVisualsCount, t])

  const fileTypeSelection = useCallback(() => (
    <Fragment>
      <div className="part">
        <p><strong>{t('select_file_type')}</strong></p>
        {Object.values(AvailableFileTypes).map(k => (
          <label key={k} className="checkbox square" htmlFor={`file_type_checkbox_${k}`}>
            <input
              type="checkbox"
              name="file_type_checkbox"
              id={`file_type_checkbox_${k}`}
              value={k}
              checked={fileTypes.has(k)}
              onChange={e => {
                if (fileTypes.has(k)) {
                  fileTypes.delete(k)
                  setFileTypes(new Set(fileTypes))
                } else {
                  setFileTypes(new Set(fileTypes.add(k)))
                }
              }}
            />
            <span className="checkmark"></span>
            <span className="label-after">
              <span>{t(`file_type.${k}`)}</span>

              <MUITooltip
                id={`file_type_tooltip_${k}`}
                type="secondary"
                content={
                  <div className="tooltip-content">
                    <span>{t(`file_type_tooltip.${k}`)}</span>
                  </div>
                }
              >
                <Icon
                  icon={IconType.QUESTION}
                  colorClass={ColorClass.SECONDARY_ORANGE_DARK}
                  className="tooltip-icon space-larger-left"
                />
              </MUITooltip>
            </span>
          </label>
        ))}
      </div>
    </Fragment>
  ), [t, AvailableFileTypes, fileTypes, setFileTypes])

  return (
    <Fragment>
      <div className="page gallery">
        <div className="page-content">
          {assignment &&
            <Fragment>
              <GalleryBreadcrumbs assignment={assignment.data} />
              <DynamicQueryContent
                query={assignment}
                showStates={['error']}
              >
                {allVisuals &&
                  <FetchedContent
                    request={allVisuals}
                    error={errorElement(allVisuals?.error_type)}
                  >
                    <Fragment>
                      <div className="order-detail-wrapper">
                        {(assignmentData && dealData) ?
                          <OrderDetail
                            assignmentData={assignmentData}
                            dealData={dealData}
                          />
                          :
                          <Fragment></Fragment>
                        }
                      </div>

                      {!!dealData &&
                        <AutomateOrderCard />
                      }

                      <div className="assignment-tabs">
                        <TabLinkBar assignments={dealAssignmentsForTab} activeAssignmentId={assignmentId}></TabLinkBar>
                      </div>

                      <div className="gallery-content">
                        <div className="details-wrapper">

                          <Fragment>
                            {assignmentStage ?
                              <Fragment>
                                {isDocumentProduct
                                  ? <AdminGalleryDocumentsPanel assignmentId={assignmentId} assignment={assignmentData} product={product} />
                                  : <Fragment>
                                    {/** SEVERAL PRODUCTS FEEDBACK BUTTON */}
                                    {isFeedbackButtonAllowed && <GalleryProductFeedback />}

                                    {originalType !== VisualFileType.RAW &&
                                      <div className="details">
                                        <div className="gallery-link-wrapper">
                                          <div className="gallery-link-info">
                                            <span>{t('link_copy_text')}</span>
                                          </div>
                                          <div className="gallery-link-button">
                                            <CopyButton
                                              copyText={window.location.href}
                                              textContent={t('link_copy')}
                                              onCopy={() => {
                                                logAnalyticsEvent('Use Copy Link', {
                                                  userId: baseUserData?.id,
                                                  workspaces: clientData?.workspaces,
                                                })
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    }

                                    <Fragment>
                                      <AssignmentDetailsCard
                                        assignmentId={assignmentId}
                                      />
                                      <CreativeAssignmentCard />
                                    </Fragment>

                                    {/** Header of admin section */}
                                    <div className="details">
                                      <div className="sticky">
                                        {availableVisualTypes.length === 1 ?
                                          <Fragment>
                                            <TriangleIcon type={ColorClass.PRIMARY_BLUE} icon={visualTypeIconMap[availableVisualTypes[0]]} />
                                          </Fragment>
                                          :
                                          <Fragment>
                                            {availableVisualTypes.length > 1 &&
                                              <div className="space-bottom-large flex">
                                                <div className="visualTypeTabs">
                                                  {availableVisualTypes.map(type => (
                                                    <Button
                                                      key={type}
                                                      type={visualTypeSelect === type ? 'primary' : 'secondary'}
                                                      textAndIcon={true}
                                                      onClick={() => {
                                                        setVisualTypeSelect(type)
                                                        setSelected(new Set())
                                                      }}
                                                    >
                                                      <Icon icon={visualTypeIconMap[type]} />
                                                      <span>{t(`visual_type.admin.${type}`)}</span>
                                                    </Button>
                                                  ))}
                                                </div>
                                              </div>
                                            }
                                          </Fragment>
                                        }

                                        <Fragment>
                                          {originalType !== POST_WATERMARK && !isVirtualVisit &&
                                            <Fragment>
                                              <select
                                                name="orderingSelect"
                                                id="orderingSelect"
                                                className="space-top"
                                                value={currentAdjustedOrder}
                                                onChange={e => {
                                                  const newOrdering = (e.target.value as TypeOfAvailableOrderings)
                                                  setCurrentOrder(newOrdering)
                                                }}
                                              >
                                                {Object.values(AvailableOrderings).map(ordering => (
                                                  <option key={ordering} value={ordering}>{ordering}</option>
                                                ))}
                                              </select>
                                            </Fragment>
                                          }

                                          <br />

                                          {!isVirtualVisit &&
                                            <Fragment>
                                              <p>{t('admin_download')}</p>
                                              {fileTypeSelection()}
                                              <div className="part">
                                                <Button
                                                  type="secondary blue"
                                                  onClick={() => {
                                                    if (archiveAdminDownloadPending) return
                                                    initiateArchiveAdminDownloadAll()
                                                  }}
                                                  disabled={archiveAdminDownloadPending}
                                                  className="space-right"
                                                >
                                                  {t('download_all_admin', { count: downloadVisualsCount })}
                                                </Button>
                                                {archiveAdminDownloadPending &&
                                                  <span>{t('download_requested')}</span>
                                                }
                                              </div>

                                              <div className="part">
                                                <Button
                                                  type="secondary orange"
                                                  onClick={() => {
                                                    if (archiveAdminDownloadPending) return
                                                    initiateArchiveAdminDownloadSelected()
                                                  }}
                                                  disabled={selected.size === 0 || archiveAdminDownloadPending}
                                                  className="space-right"
                                                >
                                                  {t('download_selected_admin', { count: selected.size })}
                                                </Button>
                                                {archiveAdminDownloadPending &&
                                                  <span>{t('download_requested')}</span>
                                                }
                                              </div>

                                              {purchasedVisualsExist &&
                                                <div className="part">
                                                  <Button
                                                    type="secondary green"
                                                    onClick={() => {
                                                      if (archiveAdminDownloadPending) return
                                                      initiateArchiveAdminDownloadPurchased()
                                                    }}
                                                    disabled={purchasedVisualsCount === 0 || archiveAdminDownloadPending}
                                                    className="space-right"
                                                  >
                                                    {t('download_purchased_admin', { count: purchasedVisualsCount })}
                                                  </Button>
                                                  {archiveAdminDownloadPending &&
                                                    <span>{t('download_requested')}</span>
                                                  }
                                                </div>
                                              }

                                              {archiveAdmin &&
                                                <GalleryDownloadStatus
                                                  nopadding={true}
                                                  isSimpleVersion={true}
                                                  assignmentId={assignmentId}
                                                  archive={archiveAdmin}
                                                  downloadVisualsArchive={downloadVisualsArchiveAdmin}
                                                  downloadArchiveUrl={downloadArchiveAdminUrl}
                                                  isAutoDownload={true}
                                                />
                                              }
                                            </Fragment>
                                          }

                                          {<GalleryAdminCommentSection />}

                                          {originalType === VisualFileType.RAW && (!isVirtualVisit || isVirtualVisitMatterport || (isVirtualVisit && isFloorPlan)) &&
                                            <Fragment>
                                              <hr className="section-divider" />
                                              <div className="part">
                                                <h2>{t('editing_section')}</h2>
                                                {canSelectVisuals &&
                                                  <Fragment>
                                                    {fileSelection()}
                                                  </Fragment>
                                                }
                                                {editorProducts &&
                                                  <select
                                                    name="editorProducts"
                                                    id="editorProducts"
                                                    onChange={e => setEditorProduct(ESProduct[e.target.value as ESProduct] ? e.target.value as ESProduct : NONE_EDITOR_PRODUCT)}
                                                    value={editorProduct}
                                                    disabled={!canEditEditorCommentsAndProducts}
                                                  >
                                                    <option value={NONE_EDITOR_PRODUCT} disabled>{NONE_EDITOR_PRODUCT}</option>
                                                    {editorProducts.map(eProduct => (
                                                      <option value={eProduct} key={eProduct}>{eProduct}</option>
                                                    ))}
                                                  </select>
                                                }
                                                <br />
                                                <p>
                                                  <strong>{t('admin_comments_for_editor')}</strong>
                                                  {isBKBNDecide &&
                                                    <Badge color="orange" type="fill" className="title-badge">
                                                      {t('bkbn_decide')}
                                                    </Badge>
                                                  }
                                                </p>
                                                {assignmentVisualsAlreadySentToEditor ?
                                                  <BlockInfo className="blue">
                                                    {multilineText(adminCommentsForEditor)}
                                                  </BlockInfo>
                                                  :
                                                  <AutoFireActionInput
                                                    value={adminCommentsForEditor}
                                                    request={adminCommentsForEditorResponse}
                                                    action={() => dispatch(editMissionAdminCommentsForEditor(assignmentId, adminCommentsForEditor, queryClient))}
                                                    render={(className, state) => (
                                                      <textarea
                                                        name="adminCommentsForEditor"
                                                        id="adminCommentsForEditor"
                                                        className={className}
                                                        value={adminCommentsForEditor}
                                                        onChange={e => setAdminCommentsForEditor(e.target.value)}
                                                        disabled={!canEditEditorCommentsAndProducts}
                                                      >
                                                      </textarea>
                                                    )}
                                                  />
                                                }
                                              </div>
                                              <div className="part">
                                                <div className="flex">
                                                  <Button
                                                    type="secondary green"
                                                    disabled={!canSendAssignmentVisualsToEditor}
                                                    onClick={initiateSendAssignmentVisualsToEditor}
                                                    textAndIcon={true}
                                                    className="space-right"
                                                  >
                                                    <Icon icon={IconType.CHECK} />
                                                    <span>{t('send_to_editor')}</span>
                                                  </Button>

                                                  {!canSendAssignmentVisualsToEditor && cannotSendAssignmentVisualsToEditorInfo &&
                                                    <span>{cannotSendAssignmentVisualsToEditorInfo}</span>
                                                  }
                                                </div>
                                                {sendAssignmentVisualsToEditor &&
                                                  <QueryStatus
                                                    query={sendAssignmentVisualsToEditor}
                                                    successMessage={t('sent_to_editor')}
                                                    spaceTopRem={2}
                                                    onPurge={() => sendAssignmentVisualsToEditor.reset()}
                                                  />
                                                }
                                              </div>
                                            </Fragment>
                                          }

                                          {originalType !== POST_WATERMARK &&
                                            <hr className="section-divider" />
                                          }
                                        </Fragment>

                                        <Fragment>
                                          <Fragment>
                                            <h2>{t('upload_visuals')}</h2>
                                            {isVirtualVisit ?
                                              <Fragment>
                                                {!virtualVisitID &&
                                                  <p>{t('upload_visuals_description_virtual_visit')}</p>
                                                }
                                              </Fragment>
                                              :
                                              <Fragment>
                                                {originalType === VisualFileType.POST &&
                                                  <p>{t('upload_visuals_description_post')}</p>
                                                }
                                              </Fragment>
                                            }
                                            {!isVirtualVisit &&
                                              <Fragment>
                                                {(() => {
                                                  const getMinCount = () => {
                                                    return 1
                                                  }
                                                  const minCount = getMinCount()
                                                  const maxCount = originalType !== VisualFileType.RAW ? selectMaxValue : uploadMaxCount
                                                  const progressTitle = t('upload_minimum', { count: minCount === PIPEDRIVE_INFINITY ? 1 : minCount })
                                                  return (
                                                    <Fragment>
                                                      <ProgressCard
                                                        className="part"
                                                        title={progressTitle}
                                                        max={minCount === PIPEDRIVE_INFINITY ? Number.MAX_SAFE_INTEGER : minCount}
                                                        value={visualsCount}
                                                        highlighted={maxCount === PIPEDRIVE_INFINITY ? false : visualsCount > maxCount}
                                                        leftText={(() => {
                                                          if (minCount === PIPEDRIVE_INFINITY) return t('n_uploaded', { count: visualsCount })
                                                          if (visualsCount < minCount) return t('n_remaining', { count: minCount - visualsCount })
                                                          if (originalType !== VisualFileType.RAW && visualsCount > minCount) return t('n_above', { count: visualsCount - minCount })
                                                          if (visualsCount > maxCount) return t('n_above', { count: visualsCount - maxCount })
                                                          return t('n_uploaded', { count: visualsCount })
                                                        })()}
                                                      />
                                                    </Fragment>
                                                  )
                                                })()}
                                              </Fragment>
                                            }
                                          </Fragment>
                                          <div className="part">
                                            {(originalType === VisualFileType.POST || isVirtualVisit) &&
                                              <SendToClient
                                                canSendAssignmentVisualsToClient={canSendAssignmentVisualsToClient}
                                                assignmentId={assignmentId}
                                                visualsCount={visualsCount}
                                                selectMaxValue={selectMaxValue}
                                              />
                                            }

                                            {showMatterportTransferInfo &&
                                              <div className="matterport-transfer-info">
                                                <Icon color={Color.SECONDARY_ORANGE_DARK} icon={IconType.DANGER} />
                                                <strong>{t('virtual_visit_needs_transfer', { email: virtualVisitMatterportEmail })}</strong>
                                              </div>
                                            }

                                            {canSelectVisuals && originalType === VisualFileType.RAW &&
                                              <Fragment>
                                                {fileSelection()}
                                              </Fragment>

                                            }

                                            {canDeleteVisuals &&
                                              <Button
                                                type="secondary red"
                                                disabled={visualsCount === 0}
                                                onClick={deleteAllVisuals}
                                                textAndIcon={true}
                                              >
                                                <Icon icon={IconType.TRASH} />
                                                <span>{t('delete_all')}</span>
                                              </Button>
                                            }

                                            {canSubmitVisuals && originalType === VisualFileType.RAW &&

                                              <Box marginTop={2}>
                                                <Button
                                                  type="secondary green"
                                                  className="space-bottom space-right"
                                                  onClick={() => setIsSubmitAssignmentOpen(true)}
                                                  disabled={(selected.size === 0 && !isVirtualVisit) || uploadVisualsCount > 0 || visualsRecentlySubmited}
                                                >
                                                  {t('submit_assignment')}
                                                </Button>
                                                <Fragment>
                                                  {visualsRecentlySubmited ?
                                                    <span>{t('assignment_submitted')}</span>
                                                    :
                                                    (selected.size === 0 && !isVirtualVisit) &&
                                                    <span>{t('no_visuals_selected')}</span>
                                                  }
                                                </Fragment>
                                              </Box>
                                            }
                                          </div>
                                        </Fragment>

                                        {originalType === VisualFileType.RAW && canSelectVisualsForStaging &&
                                          <Fragment key="RAW_VISUALS_SELECTION_FOR_STAGING">
                                            <hr className="section-divider" />
                                            <h2>{t('visuals_ready')}</h2>
                                            {!selectedVisualsForStagingExist ?
                                              <Fragment>
                                                <p>{t('visuals_ready_for_staging_description')}</p>
                                                <ProgressCard
                                                  className="part"
                                                  title={`${(t('select_visuals'))} - ${(product ? (product.quantity > 1 ? `${product.quantity} x ` : '') + t(`product:p_${product.id}`) : 'N/A')}`}
                                                  max={selectMaxValue === PIPEDRIVE_INFINITY ? Number.MAX_SAFE_INTEGER : selectMaxValue}
                                                  value={selected.size}
                                                  highlighted={selected.size > selectMaxValue}
                                                  leftText={
                                                    selected.size === selectMaxValue || selectMaxValue === PIPEDRIVE_INFINITY
                                                      ?
                                                      t('all_set')
                                                      :
                                                      selected.size > selectMaxValue
                                                        ?
                                                        t('n_above', { count: selected.size - selectMaxValue })
                                                        :
                                                        t('n_remaining', { count: selectMaxValue - selected.size })
                                                  }
                                                />
                                              </Fragment>
                                              :
                                              <Fragment>
                                                <p>{t('visuals_ready_for_staging_selected_description')}</p>
                                                <ProgressCard
                                                  className="part"
                                                  title={t('selected_visuals')}
                                                  max={selectedVisualsForStagingCount}
                                                  value={selectedVisualsForStagingCount}
                                                  highlighted={true}
                                                  color="green"
                                                  leftText={t('n_visuals_selected', { count: selectedVisualsForStagingCount })}
                                                />
                                              </Fragment>
                                            }
                                            {canSelectVisuals &&
                                              <Fragment>
                                                {fileSelection()}
                                              </Fragment>
                                            }
                                            <BlockInfo className="part">
                                              <p className="select-visuals-instruction">
                                                <Trans t={t} i18nKey="select_visuals_for_staging_instruction">
                                                  <a href={virtualFurnitureHomeLink} rel="nofollow noopener noreferrer" target="_blank">&nbsp;</a>
                                                </Trans>
                                              </p>
                                            </BlockInfo>
                                            <br />
                                            <Button
                                              type="secondary green"
                                              className="space-bottom space-right"
                                              // onClick={}
                                              disabled={!canSubmitVisualsForStaging}
                                            >
                                              {t('validate')}
                                            </Button>
                                            <Fragment>
                                              <span>{cannotSubmitVisualsForStagingInfo}</span>
                                            </Fragment>

                                            <QueryStatus
                                              query={submitAssignment}
                                              successMessage={t('assignment_submitted')}
                                              spaceBottomRem={2}
                                            />

                                          </Fragment>
                                        }
                                        {originalType !== VisualFileType.RAW &&
                                          <Fragment>
                                            <hr className="section-divider" />

                                            <h2>{t('visuals_ready')}</h2>

                                            {!isVirtualVisit ?
                                              <Fragment>
                                                <p>
                                                  {!purchasedVisualsExist ?
                                                    t('visuals_ready_description')
                                                    :
                                                    !isAdditionalVisualPurchaseDisabled ?
                                                      t('visuals_ready_purchased_additional_visuals_description')
                                                      :
                                                      t('visuals_ready_purchased_description')
                                                  }
                                                </p>
                                                <ProgressCard
                                                  className="part"
                                                  title={
                                                    !purchasedVisualsExist
                                                      ?
                                                      `${(t('select_visuals'))} - ${(product ? (product.quantity > 1 ? `${product.quantity} x ` : '') + t(`product:p_${product.id}`) : 'N/A')}`
                                                      :
                                                      t('selected_visuals')
                                                  }
                                                  underTitleText={t('n_visuals_selected', { count: selected.size })}
                                                  max={
                                                    !purchasedVisualsExist
                                                      ?
                                                      selectMaxValue === PIPEDRIVE_INFINITY ? Number.MAX_SAFE_INTEGER : selectMaxValue
                                                      :
                                                      (selectedAdditionalVisualsCount > 0 && !isArchiveDisabled) ? selectedAdditionalVisualsCount : purchasedVisualsCount
                                                  }
                                                  value={selected.size}
                                                  highlighted={!purchasedVisualsExist ? selected.size > selectMaxValue : true}
                                                  color={selectedNotPurchasedVisualsCount > 0 ? 'orange' : 'green'}
                                                  leftText={
                                                    !purchasedVisualsExist
                                                      ?
                                                      (selected.size === selectMaxValue || selectMaxValue === PIPEDRIVE_INFINITY)
                                                        ?
                                                        t('all_set')
                                                        :
                                                        selected.size > selectMaxValue
                                                          ?
                                                          (selectedAdditionalVisualsCount > 0 && !isArchiveDisabled && pricePerAdditionalVisual && discount.gt(0))
                                                            ?
                                                            t('n_extra', { count: selected.size - selectMaxValue })
                                                            :
                                                            t('n_above', { count: selected.size - selectMaxValue })
                                                          :
                                                          t('n_remaining', { count: selectMaxValue - selected.size })
                                                      :
                                                      (selectedAdditionalVisualsCount > 0 && !isArchiveDisabled)
                                                        ?
                                                        t('n_extra', { count: selectedAdditionalVisualsCount })
                                                        :
                                                        undefined
                                                  }
                                                  textWithLineThrough={
                                                    (selectedAdditionalVisualsCount > 0 && !isArchiveDisabled && pricePerAdditionalVisual && discount.gt(0))
                                                      ?
                                                      formatPrice(totalForAdditionalVisualsBeforeDiscount, currency)
                                                      :
                                                      undefined
                                                  }
                                                  rightText={
                                                    (selectedAdditionalVisualsCount > 0 && !isArchiveDisabled && pricePerAdditionalVisual)
                                                      ?
                                                      formatPrice(totalForAdditionalVisualsAfterDiscount, currency)
                                                      :
                                                      undefined
                                                  }
                                                  leftTextAdditional={purchasedVisualsExist ? t('n_visuals_purchased', { count: purchasedVisualsCount }) : undefined}
                                                  data-for={`additional_visual_price_tooltip_${assignmentId}`}
                                                  tooltipData={(
                                                    <div className="tooltip-content">
                                                      <h4>{t('gallery:additional_visual_price:total')}</h4>
                                                      {discount.gt(0) &&
                                                        <strong className="total-before-discount">{formatPrice(totalForAdditionalVisualsBeforeDiscount, currency)}</strong>
                                                      }
                                                      <strong className="total">{formatPrice(totalForAdditionalVisualsAfterDiscount, currency)}</strong>
                                                      <div className="vat">
                                                        <span className="nowrap">{t('gallery:additional_visual_price:vat')} ({formatPrice(vat)} %)</span>
                                                        <strong>{formatPrice(totalVatForAdditionalVisuals, currency)}</strong>
                                                        <span className="nowrap">{t('gallery:additional_visual_price:after_vat')}</span>
                                                        <strong>{formatPrice(totalAfterVatForAdditionalVisuals, currency)}</strong>
                                                      </div>
                                                    </div>
                                                  )}
                                                />
                                                {canSelectVisuals &&
                                                  <Fragment>
                                                    {fileSelection()}
                                                  </Fragment>
                                                }
                                                <BlockInfo className="part">
                                                  {isFloorPlan ?
                                                    <Trans t={t} i18nKey={'gallery:questions_support'} values={{ supportEmail }}>
                                                      <strong>
                                                        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                                                      </strong>
                                                    </Trans>
                                                    :
                                                    <Trans t={t} i18nKey={'gallery:editing_advice'} values={{ supportEmail }}>
                                                      <strong>
                                                        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                                                      </strong>
                                                    </Trans>
                                                  }
                                                </BlockInfo>
                                                {fileTypeSelection()}
                                                <div className="part">
                                                  {(!(selectedAdditionalVisualsCount > 0) || isArchiveDisabled) ?
                                                    <Button
                                                      type="primary"
                                                      disabled={isArchiveDisabled && !downloadArchiveUrl}
                                                      onClick={() => initiateArchiveDownload()}
                                                      className="space-right"
                                                    >
                                                      {t('download')}
                                                    </Button>
                                                    :
                                                    <Button
                                                      type="primary"
                                                      onClick={() => initiateArchiveDownload()}
                                                      className="space-right"
                                                    >
                                                      {t('download_and_purchase')}
                                                    </Button>
                                                  }
                                                  {isArchiveDisabled && initiateDownloadVisualsArchive?.state !== APIRequestState.ERROR &&
                                                    <Fragment>
                                                      {downloadArchiveUrl ?
                                                        <span>{t('download_ready')}</span>
                                                        :
                                                        purchasedVisualsExist
                                                          ?
                                                          <span>{t('download_requested')}</span>
                                                          :
                                                          <Fragment>
                                                            {selected.size === 0 &&
                                                              <span>{t('no_visuals_selected')}</span>
                                                            }
                                                            {selectMaxValue !== PIPEDRIVE_INFINITY &&
                                                              <Fragment>
                                                                {selected.size > 0 && selected.size < selectMaxValue &&
                                                                  <span>{t('select_n_more', { count: selectMaxValue - selected.size })}</span>
                                                                }
                                                                {selected.size > selectMaxValue && isAdditionalVisualPurchaseDisabled &&
                                                                  <span>{t('select_n_less', { count: selected.size - selectMaxValue })}</span>
                                                                }
                                                              </Fragment>
                                                            }
                                                          </Fragment>
                                                      }
                                                    </Fragment>
                                                  }
                                                </div>
                                              </Fragment>
                                              :
                                              <Fragment>
                                                <p>{t('visuals_ready_virtual_visit_description')}</p>
                                                <BlockInfo className="part">
                                                  <Trans t={t} i18nKey={'gallery:questions_support'} values={{ supportEmail }}>
                                                    <strong>
                                                      <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                                                    </strong>
                                                  </Trans>
                                                </BlockInfo>
                                                {!!virtualVisitID && !virtualVisitMatterportEmail &&
                                                  <VirtualVisitHostingInfo
                                                    hostingExpirationDate={virtualVisitExpirationDate}
                                                    isExpired={virtualVisitExpired}
                                                    assignmentId={assignmentId}
                                                    discount={dealData?.financials.discount}
                                                  />
                                                }
                                              </Fragment>
                                            }
                                          </Fragment>
                                        }
                                      </div>
                                    </div>
                                  </Fragment>
                                }
                              </Fragment>
                              :
                              <div className="details">
                                <div className="error-wrap">
                                  <h3 className="error-heading"><ErrorMessage error_type={'error'} /></h3>
                                  <ErrorMessage className="error" error_type={APIRequestErrorType.UNKNOWN_ERROR} />
                                  <div className="error-contact-wrap">
                                    <BlockInfo className="error-contact">
                                      <Trans t={t} i18nKey={'gallery:error_contact_us'} values={{ supportEmail }}>
                                        <strong>
                                          <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                                        </strong>
                                      </Trans>
                                    </BlockInfo>
                                  </div>
                                </div>
                              </div>
                            }
                          </Fragment>
                        </div>

                        <div className="main-content-wrapper">

                          {isDocumentProduct
                            ? (
                              <Fragment>
                                <DocumentGalleryContent documentUploadType={documentTypeSelect} />
                                <AdminGalleryMessageThreads />
                              </Fragment>
                            )
                            : (
                              <Fragment>
                                {assignmentStage ?
                                  <div className={`main-content ${isVirtualVisit ? 'virtual-visit' : ''}`.trim()}>
                                    {canUploadVisuals &&
                                      <Fragment>
                                        <div className={`drop-zone ${isDraggingFile ? 'drag-active' : ''} ${isDragActive ? 'drag-over' : ''} ${visualsCount > 0 ? 'margin-bottom' : ''}`} {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          {isDragActive || isDraggingFile ?
                                            <h3>{t('drop_here')}</h3>
                                            :
                                            <Fragment>
                                              <p>{t('drag_n_drop')}</p>
                                              <Button type="primary" className="choose-files">{t('choose_button')}</Button>
                                            </Fragment>
                                          }
                                        </div>
                                      </Fragment>
                                    }

                                    {/* VIRTUAL VISIT SECTION */}
                                    {!isVirtualVisit ?
                                      <div className="photo-grid" ref={setVisualsGridRef}>
                                        {visualsListing()}
                                      </div>
                                      :
                                      <Fragment>
                                        {showVirtualVisitInstructions &&
                                          <GalleryVirtualVisitInstructions />
                                        }

                                        {!showVirtualVisitInstructions &&
                                          <div className="virtual-visit-input-wrap">
                                            <label htmlFor="virtual-visit-input">{t('upload_visuals_instructions_virtual_visit_input_label')}</label>
                                            <div className="line">
                                              <input
                                                type="text"
                                                name="virtual-visit-input"
                                                id="virtual-visit-input"
                                                className="virtual-visit-input"
                                                placeholder="Virtual Visit ID / Link"
                                                value={virtualVisitInput}
                                                onChange={e => {
                                                  let result = ''
                                                  const patterns = [
                                                    /.*my\.matterport\.com\/show\/\?m=(\w+)&.+/im,
                                                    /.*my\.matterport\.com\/show\/\?m=(\w+)'.+/im,
                                                    /.*my\.matterport\.com\/show\/\?m=(\w+)".+/im,
                                                    /.*my\.matterport\.com\/show\/\?m=(\w+)/im,
                                                    /(\w+)/im,
                                                  ]
                                                  for (const pattern of patterns) {
                                                    const matches = e.target.value.match(pattern)
                                                    if (!matches || matches.length <= 1) continue
                                                    result = matches[1]
                                                    break
                                                  }
                                                  if (result) {
                                                    setVirtualVisitInput(result)
                                                  } else {
                                                    setVirtualVisitInput(e.target.value)
                                                  }
                                                }}
                                              />
                                              <MUIButton
                                                className="submit"
                                                type="defaultPrimary"
                                                isLoading={updateVirtualVisit.isPending}
                                                disabled={updateVirtualVisit.isPending || !virtualVisitInput}
                                                onClick={async () => await showConfirm(t('virtual_visit_update_prompt'))
                                                  && updateVirtualVisit.mutate({ assignmentId, matterportId: virtualVisitInput },
                                                    {
                                                      onSuccess: () => {
                                                        spawnSuccessToast(t('virtual_visit_update_success'))
                                                        // [TODO-RQ BKBN-1295] invalidate query in query function instead of dispatch after calling listVisuals() via react-query
                                                        dispatch(listVisuals(assignmentId, normalizedOriginalType))
                                                      },
                                                    }
                                                  )}
                                              >
                                                {t('upload_visuals_instructions_virtual_visit_input_submit')}
                                              </MUIButton>
                                            </div>
                                          </div>
                                        }
                                        {!!virtualVisitID && !showVirtualVisitInstructions &&
                                          <div className="virtual-visit-embed">
                                            <iframe
                                              className="matterport-embed"
                                              title={virtualVisitID}
                                              width="100%"
                                              height="100%"
                                              src={`https://my.matterport.com/show/?m=${virtualVisitID}`}
                                              frameBorder="0"
                                              allowFullScreen
                                              allow="xr-spatial-tracking"
                                            >
                                            </iframe>
                                          </div>
                                        }
                                        {!!virtualVisitID && !showVirtualVisitInstructions &&
                                          <div className="virtual-visit-share">
                                            <h2>{t('virtual_visit_share')}</h2>
                                            <div className="radios">
                                              {Object.values(AvailableVirtualVisitLinkTypes).map(linkType => (
                                                <Fragment key={linkType}>

                                                  <label className="checkbox" htmlFor={`virtual_visit_link_type_${linkType}`}>
                                                    <input
                                                      type="radio"
                                                      name="virtual_visit_link_type"
                                                      id={`virtual_visit_link_type_${linkType}`}
                                                      value={linkType}
                                                      checked={selectedVirtualVisitLinkType === linkType}
                                                      onChange={e => setSelectedVirtualVisitLinkType(e.target.value as TypeOfAvailableVirtualVisitLinkTypes)}
                                                    />
                                                    <span className="checkmark"></span>
                                                    <span className="label-after">{t(`virtual_visit_link_type.${linkType}`)}</span>
                                                    <MUITooltip
                                                      id={`virtual_visit_link_type_tooltip_${linkType}`}
                                                      type="secondary"
                                                      content={
                                                        <div className="tooltip-content">
                                                          <span>{t(`virtual_visit_link_type_tooltip.${linkType}`)}</span>
                                                        </div>
                                                      }
                                                    >
                                                      <Icon
                                                        icon={IconType.QUESTION}
                                                        colorClass={ColorClass.SECONDARY_ORANGE_DARK}
                                                        className="tooltip-icon"
                                                        data-tip
                                                        data-for={`virtual_visit_link_type_tooltip_${linkType}`}
                                                      />
                                                    </MUITooltip>
                                                  </label>
                                                </Fragment>
                                              ))}
                                            </div>
                                            <p>{t(`virtual_visit_link_type_description.${selectedVirtualVisitLinkType}`)}</p>
                                            <h3>{t('virtual_visit_link')}</h3>
                                            <div className="input-group">
                                              <input type="text" name="virtual-visit-share-link" id="virtual-visit-share-link" ref={virtualVisitShareLinkRef} readOnly={true} value={virtualVisitShareLink} />

                                              <CopyButton
                                                type="secondary"
                                                copyText={virtualVisitShareLink}
                                                textContent={t('virtual_visit_copy')}
                                                disabled={!virtualVisitShareLinkRef.current || !virtualVisitID}
                                                onCopy={() => {
                                                  if (!virtualVisitShareLinkRef.current) return
                                                  virtualVisitShareLinkRef.current.focus()
                                                  virtualVisitShareLinkRef.current.select()
                                                  virtualVisitShareLinkRef.current.setSelectionRange(0, 99999)
                                                }}
                                              />
                                            </div>

                                            <span className="embedded-header">
                                              <h3>
                                                {t('virtual_visit_embed')}
                                              </h3>
                                              <MUITooltip
                                                id={'virtual_visit_embed_tooltip'}
                                                type="secondary"
                                                content={
                                                  <div className="tooltip-content">
                                                    <span>{t('virtual_visit_embed_tooltip')}</span>
                                                  </div>
                                                }
                                              >
                                                <Icon
                                                  icon={IconType.QUESTION}
                                                  colorClass={ColorClass.SECONDARY_ORANGE_DARK}
                                                  className="tooltip-icon space-larger-left"
                                                  data-tip
                                                  data-for={'virtual_visit_embed_tooltip'}
                                                />
                                              </MUITooltip>
                                            </span>

                                            <div className="input-group">
                                              <input type="text" name="virtual-visit-embed-code" id="virtual-visit-embed-code" ref={virtualVisitEmbedCodeRef} readOnly={true} value={virtualVisitEmbedCode} />

                                              <CopyButton
                                                type="secondary"
                                                textContent={t('virtual_visit_copy')}
                                                copyText={virtualVisitEmbedCode}
                                                disabled={!virtualVisitEmbedCodeRef.current || !virtualVisitID}
                                                onCopy={() => {
                                                  if (!virtualVisitEmbedCodeRef.current) return
                                                  virtualVisitEmbedCodeRef.current.focus()
                                                  virtualVisitEmbedCodeRef.current.select()
                                                  virtualVisitEmbedCodeRef.current.setSelectionRange(0, 99999)
                                                }}
                                              />
                                            </div>
                                          </div>
                                        }
                                      </Fragment>
                                    }
                                  </div>

                                  :
                                  <div className="error-wrap">
                                    <h3 className="error-heading"><ErrorMessage error_type={'error'} /></h3>
                                    <ErrorMessage className="error" error_type={APIRequestErrorType.UNKNOWN_ERROR} />
                                    <div className="error-contact-wrap">
                                      <BlockInfo className="error-contact">
                                        <Trans t={t} i18nKey={'gallery:error_contact_us'} values={{ supportEmail }}>
                                          <strong>
                                            <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                                          </strong>
                                        </Trans>
                                      </BlockInfo>
                                    </div>
                                  </div>
                                }
                              </Fragment>
                            )
                          }


                        </div>
                      </div>
                    </Fragment>
                  </FetchedContent>
                }
              </DynamicQueryContent>
            </Fragment>
          }
        </div>
      </div>
      <Modal
        className={`gallery-lightbox-modal ${selected.has(expandedImageName || '') ? 'selected' : ''} ${purchasedVisualsKeys.has(expandedImageName || '') ? 'purchased' : ''}`}
        modalContentClassName="gallery-lightbox-modal-content"
        isOpen={isLightboxModalOpen}
        onClickOutside={closeLightboxModal}
        onKeyDown={lightboxModalKeyDownHandler}
      >
        <div className="inside">
          <div className="info-header">
            <strong>{t('photo_no')}{expandedImage && webType && expandedImageWebType?.file?.name}</strong>
            {expandedImage && webType &&
              <span>{t('reference')}: #{expandedImageWebType?.file?.name.split('.')[0].split('-')[2] || ''}</span>
            }
            <div className={closeButtonStyles.closeWrap}>
              <Button
                type="secondary nobackground noborder"
                onClick={closeLightboxModal}
              >
                <Icon icon={IconType.CROSS} />
              </Button>
            </div>
          </div>
          <div className="image-box" style={{ backgroundImage: `url(${expandedImage && webType && expandedImageWebType?.signedUrl})` }}>
            {webType && !expandedImage?.[webType]?.file?.name &&
              <ErrorMessage className="error" error_type={APIRequestErrorType.NOTFOUND_ERROR} />
            }
            {webType && expandedImageWebType && expandedImageWebType?.request.error_type && expandedImageWebType?.request.error_type !== APIRequestErrorType.NONE &&
              <ErrorMessage className="error" error_type={expandedImageWebType?.request.error_type} />
            }
            {expandedImage && downloadVisualsCount > 1 &&
              <Fragment>
                <Button
                  className="arrow previous"
                  type="secondary nobackground noborder"
                  onClick={(e => { e.preventDefault(); switchExpandedImage('previous') })}
                >
                  <Icon icon={IconType.CARET_LEFT} />
                </Button>
                <Button
                  className="arrow next"
                  type="secondary nobackground noborder"
                  onClick={(e => { e.preventDefault(); switchExpandedImage('next') })}
                >
                  <Icon icon={IconType.CARET_RIGHT} />
                </Button>
              </Fragment>
            }
            {expandedImage && expandedImageName && canSelectVisuals &&
              <span
                className={`actionbutton check circular large select ${selected.has(expandedImageName || '') ? 'checked' : ''}`}
                onClick={e => toggleSelectOnImage(expandedImageName)}
              >
                <Icon icon={IconType.CHECK} /></span>
            }
          </div>
        </div>
      </Modal>

      <VisualDownloadPopup
        isOpen={isDownloadModalOpen}
        onClickClose={!archiveDownloadPending ? closeDownloadModal : undefined}
      />

      <UpsellPayment
        isOpen={isAdditionalVisualsPurchaseModalOpen}
        assignmentId={assignmentId}
        productId={additionalVisualProductId}
        productQuantity={selectedAdditionalVisualsCount}
        total={!!currency ? { value: decimalFromBig(totalForAdditionalVisualsAfterDiscount), currency, discountPercentage: ZERO_DECIMAL } : null}
        beforeConfirmAction={(paymentIntentId, billingProductId) => product?.id && dispatch(selectVisuals(assignmentId, transformTypes(), Array.from(selected), normalizedOriginalType, product.id, billingProductId, paymentIntentId))}
        beforeConfirmActionState={selectVisualsRequest?.state}
        beforeConfirmationActionRequest={selectVisualsRequest}
        onClose={() => setIsAdditionalVisualsPurchaseModalOpen(false)}
        afterClosed={handleAfterAdditionalPurchaseModalClose}
        successNode={(
          <div className="av-payment-success">
            <Icon className="av-payment-success-icon" color={Color.SECONDARY_GREEN_DARK} icon={IconType.CHECK} />
            <h2 className="av-payment-success-title">{t('additional_visuals_purchase.success_title')}</h2>
            <p className="av-payment-success-text">{t('additional_visuals_purchase.success_text')}</p>
            <Button
              type="primary"
              onClick={() => {
                setIsAdditionalVisualsPurchaseModalOpen(false)
                dispatch(archiveVisuals(assignmentId, transformTypes(), Array.from(selected), ArchiveVisualsTag.CLIENT_ARCHIVE, true))
              }}>
              {t('download')}
            </Button>
          </div>
        )}
      >
        {/* Using Trans to render tags like <strong> and <br /> */}
        <Trans t={t} i18nKey="purchase_more_visuals_prompt">
          <span></span>
        </Trans>
      </UpsellPayment>
      <div className="gallery-preloader-cache">
        {webType && downloadVisualsEntries.map(([key, allTypes]) => (
          <img key={key} src={allTypes?.[webType]?.signedUrl} onError={(e) => console.log('webType', webType, e)} alt="hidden" />
        ))}
        {thumbnailType && downloadVisualsEntries.map(([key, allTypes]) => (
          <img key={key} src={allTypes?.[thumbnailType]?.signedUrl} onError={(e) => console.log('thumbnailType', thumbnailType, e)} alt="hidden" />
        ))}
      </div>

      {/* SUBMIT ASSIGNMENT MODAL */}
      <SubmitAssignmentModal
        isOpen={isSubmitAssignmentOpen}
        type="visual"
        onSubmit={note => initiateSubmitAssignment(note)}
        onClose={() => setIsSubmitAssignmentOpen(false)}
        mutation={submitAssignment}
        onAfterClosed={() => submitAssignment.reset()}
      />

      <ChangeRemunerationDrawer />

      <GalleryUploadErrorPopup
        isOpen={isUploadErrorPopupOpen}
        onClose={() => {
          visualUploadingTriggered.current = false
          setIsUploadErrorPopupOpen(false)
        }}
      />

    </Fragment>
  )
}
