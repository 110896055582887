import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { BEIGE_500, GRAY_900 } from 'constants/styling/theme'
import { FloorPlanRenderType, useFloorPlanConfig } from '../_main/FloorPlanConfigStep.context'
import { Stack, Typography } from '@mui/material'

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { FC } from 'react'
import { FloorPlanFormat } from '../FloorPlanLayout'
import { MUIButton } from 'components/common/MUIButton'
import { SelectableImageBox } from '../SelectableImageBox'
import basic_furniture_3D from 'assets/img/floorplan/3D/3D_furniture_variant1.webp'
import basic_furniture_black_2D from 'assets/img/floorplan/2D/2D_commercial_bw_furniture.webp'
import { usePurchaseFlowConfig } from 'components/pages/PurchaseFlow/_main/contexts'
import { useTranslation } from 'react-i18next'

interface FloorPlanOptionProps {
  /** The URL of the image to be displayed in the box. */
  imageUrl: string
  /** A boolean indicating if the box is currently active. */
  isActive: boolean
  /** The function to be called when the box is selected. */
  onSelect: () => void
  /** The text displayed at the bottom of the box. */
  bottomText: string
}

const FloorPlanOption: FC<FloorPlanOptionProps> = ({ imageUrl, isActive, onSelect, bottomText }) => (
  <SelectableImageBox
    text={bottomText}
    height={14.4}
    width={16.8}
    elevation='xs'
    inactiveBorderColor='transparent'
    isSelected={isActive}
    onSelect={onSelect}
    image={imageUrl}
  />
)

/**
 * @component
 * FloorPlanRenderTypeController displays the 2D/3D option to select, as default is no option.
 * 
 * @example 
 * <FloorPlanRenderTypeController />
 */
export const FloorPlanRenderTypeController: FC = () => {
  const { t } = useTranslation(['purchase_flow', 'actions'])
  const {
    currentFloorPlanRenderType,
    setCurrentFloorPlanRenderType,
    setFloorPlanTemplateType,
    setSelectedTemplateId,
    setSelectedPropertyType,
    setFloorPlanFormat,
  } = useFloorPlanConfig()
  const { selectedCategory } = usePurchaseFlowConfig()

  const handleFloorTypeClick = (floorType: FloorPlanRenderType) => {
    setCurrentFloorPlanRenderType(floorType)
    setFloorPlanFormat({ [FloorPlanFormat.JPG]: FloorPlanFormat.JPG }) // Reset format to default
    logAnalyticsEvent(AnalyticsEvent.FLOOR_PLAN_TYPE_SELECTED, {
      type: floorType,
      category: selectedCategory,
    })
  }

  const goToStepSelection = () => {
    setFloorPlanTemplateType(null)
    setSelectedTemplateId(null)
    setSelectedPropertyType(null)
  }

  return (
    <BorderBoxWrapper backgroundColor={BEIGE_500} padding="2.4rem">

      <Stack alignItems="center" position="relative">
        {/** Go back button */}
        <MUIButton
          isIconButton
          type="secondaryNoBorder"
          size="xs"
          startIcon={<ArrowBackRoundedIcon />}
          onClick={goToStepSelection}
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
          }}
        >
          {t('actions:Back')}
        </MUIButton>

        <Typography variant="text-md" fontWeight={600} color={GRAY_900}>
          {t('floor_plan_step.render_type.title')}
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="center" alignItems="center" gap={1.6} marginTop={2}>

        <FloorPlanOption
          imageUrl={basic_furniture_black_2D}
          isActive={currentFloorPlanRenderType === FloorPlanRenderType.RENDER_2D}
          onSelect={() => handleFloorTypeClick(FloorPlanRenderType.RENDER_2D)}
          bottomText={t('floor_plan_step.render_type.2D')}
        />

        <FloorPlanOption
          imageUrl={basic_furniture_3D}
          isActive={currentFloorPlanRenderType === FloorPlanRenderType.RENDER_3D}
          onSelect={() => handleFloorTypeClick(FloorPlanRenderType.RENDER_3D)}
          bottomText={t('floor_plan_step.render_type.3D')}
        />

      </Stack>

    </BorderBoxWrapper>
  )
}
