import { DocumentUploadType, UploadTagType } from 'constants/documents'
import { usePurchaseFlowConfig, useTargetOrderUser } from '../../_main/contexts'

import { useFileAPI } from 'components/common/FileAPI'
import { PurchaseFlowEndpoint } from 'constants/API'
import constate from 'constate'
import { useMemo } from 'react'

export const [PowerOfAttorneyStepContextProvider, usePowerOfAttorneyStep] = constate(() => {

  const { sessionId } = usePurchaseFlowConfig()
  const { targetUser } = useTargetOrderUser()

  const powerOfAttorneyUpload = useFileAPI('PF_POWER_OF_ATTORNEY', {
    uploadUrlResolver: (fileEntry, api) => {
      return api.post(
        PurchaseFlowEndpoint.GET_POA_UPLOAD_URL,
        { sessionId: sessionId!.toString() },
        {
          filename: fileEntry.fileObject.name,
          contentType: fileEntry.fileObject.type,
        },
        true
      )
    },
    deleteHandler: (_, api) => api.delete(
      PurchaseFlowEndpoint.DELETE_UPLOADED_POA,
      { sessionId: sessionId!.toString() },
      true
    )
  })

  const powerOfAttorneyRepsUpload = useFileAPI('PF_POWER_OF_ATTORNEY_REPS', {
    uploadUrlResolver: (fileEntry, api) => {
      return api.post(
        PurchaseFlowEndpoint.GET_POA_REPS_UPLOAD_URL,
        { sessionId: sessionId!.toString() },
        {
          filename: fileEntry.fileObject.name,
          contentType: fileEntry.fileObject.type,
        },
        true
      )
    },
    deleteHandler: (_, api) => api.delete(
      PurchaseFlowEndpoint.DELETE_UPLOADED_POA_REPS,
      { sessionId: sessionId!.toString() },
      true
    )
  })

  const powerOfAttorneyOtherFilesUpload = useFileAPI('PF_POWER_OF_ATTORNEY_OTHER_FILES', {
    uploadUrlResolver: (fileEntry, api) => {
      return api.post(
        PurchaseFlowEndpoint.GET_DOCUMENT_UPLOAD_URL,
        { sessionId: sessionId!.toString() },
        {
          documentMetadata: {
            filename: fileEntry.fileObject.name,
            contentType: fileEntry.fileObject.type,
            type: DocumentUploadType.INPUT,
            tag: UploadTagType.ORDER_SUPPLEMENTARY_DOCUMENT,
          }
        },

        true
      )
    },
    deleteHandler: (_, api) => api.delete(
      PurchaseFlowEndpoint.DELETE_UPLOADED_DOCUMENT,
      { sessionId: sessionId!.toString() },
      true
    )
  })

  const isPowerOfAttorneyStepValid = useMemo(() => {
    const isRequestingRepresentative = targetUser.requiresRepresentativePowerOfAttorney
    const isPowerOfAttorneyUploaded = powerOfAttorneyUpload?.allFilesArray.length > 0
    const isPowerOfAttorneyRepsUploaded = powerOfAttorneyRepsUpload?.allFilesArray.length > 0

    if (isRequestingRepresentative && (isPowerOfAttorneyUploaded || isPowerOfAttorneyRepsUploaded)) {
      if (isPowerOfAttorneyUploaded && isPowerOfAttorneyRepsUploaded) {
        return true
      }

      return false
    }

    return true
  }, [powerOfAttorneyRepsUpload?.allFilesArray.length, powerOfAttorneyUpload?.allFilesArray.length, targetUser.requiresRepresentativePowerOfAttorney])

  return {
    powerOfAttorneyUpload,
    powerOfAttorneyRepsUpload,
    isPowerOfAttorneyStepValid,
    powerOfAttorneyOtherFilesUpload,
  }
})
