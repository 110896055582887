import { BEIGE_600, BEIGE_700, BEIGE_900, CORAL_100, CORAL_300, CORAL_400, CORAL_600, CORAL_700, GRAY_1000, GRAY_1100, GRAY_500, GRAY_600, GRAY_700, GRAY_800, GRAY_900, GREEN_100, GREEN_200, GREEN_300, GREEN_600, GREEN_700, HKGroteskFamily, TRANSPARENT, WHITE } from './theme'

import { createTheme } from '@mui/material/styles'

export const ButtonMUITheme = createTheme({
  palette: {
    defaultPrimary: {
      main: GRAY_1000,
      hover: GRAY_1100,
      disabled: GRAY_500,
      label: WHITE,
      disabledLabel: WHITE,
      border: GRAY_1000,
      disabledWithBorder: TRANSPARENT
    },
    greenPrimary: {
      main: GREEN_600,
      hover: GREEN_700,
      disabled: GREEN_300,
      label: WHITE,
      disabledLabel: WHITE,
      border: TRANSPARENT,
      disabledWithBorder: TRANSPARENT
    },
    greenSecondary: {
      main: GREEN_100,
      hover: GREEN_200,
      disabled: GREEN_100,
      label: GREEN_600,
      disabledLabel: GREEN_300,
      border: TRANSPARENT,
      disabledWithBorder: TRANSPARENT
    },
    orangePrimary: {
      main: CORAL_600,
      hover: CORAL_700,
      disabled: CORAL_300,
      label: WHITE,
      disabledLabel: WHITE,
      border: TRANSPARENT,
      disabledWithBorder: TRANSPARENT
    },
    darkPrimary: {
      main: GRAY_800,
      hover: GRAY_900,
      disabled: GRAY_1000,
      label: WHITE,
      disabledLabel: GRAY_600,
      border: GRAY_900,
    },
    secondaryNoBorder: {
      main: TRANSPARENT,
      hover: `${BEIGE_900}26`, // 15% opacity
      disabled: TRANSPARENT,
      label: GRAY_900,
      disabledLabel: GRAY_600,
      border: TRANSPARENT,
    },
    darkSecondaryNoborder: {
      main: TRANSPARENT,
      hover: GRAY_900,
      disabled: GRAY_1000,
      label: WHITE,
      disabledLabel: GRAY_700,
      border: TRANSPARENT,
    },
    orangeSecondaryNoBorder: {
      main: TRANSPARENT,
      hover: CORAL_100,
      disabled: TRANSPARENT,
      label: CORAL_600,
      disabledLabel: CORAL_400,
      border: TRANSPARENT,
    },
    secondaryBorder: {
      main: WHITE,
      hover: `${BEIGE_900}26`, // 15% opacity
      disabled: WHITE,
      label: GRAY_900,
      disabledLabel: GRAY_600,
      border: BEIGE_600,
      disabledWithBorder: BEIGE_700,
    },
    orangeSecondaryBorder: {
      main: WHITE,
      hover: CORAL_100,
      disabled: WHITE,
      label: CORAL_600,
      disabledLabel: CORAL_400,
      border: CORAL_400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontFamily: HKGroteskFamily,
          textTransform: 'none',
          fontSize: '1.4rem',
          lineHeight: '1.9rem',
          minWidth: 'unset',
          gap: '.8rem',
        },
      },
      variants: [
        {
          props: { size: 'xs' },
          style: { padding: '.1rem 1rem .4rem 1rem', minHeight: '2.4rem' },
        },
        {
          props: { size: 'sm' },
          style: { padding: '.2rem 1.2rem', minHeight: '2.8rem' },
        },
        {
          props: { size: 'md' },
          style: { padding: '.6rem 1.6rem', minHeight: '3.6rem' },
        },
        {
          props: { size: 'lg' },
          style: { padding: '.6rem 2rem', minHeight: '4rem', fontSize: '1.6rem', lineHeight: '2.4rem' },
        },
      ],
    },
  },
})
