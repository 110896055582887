import { FC, useEffect, useMemo } from 'react'
import { PRODUCTS_WITH_OPTIONAL_STAGING, ProductType, STAGING_PRODUCT_KINDS } from 'constants/product'
import { useGalleryAssignment, useGalleryDeal, useGalleryNavigation, useGalleryProduct, useGalleryVisualsMeta } from '../../contexts'

import { AssignmentStage } from 'constants/assignment'
import { ClientGalleryContent } from '../ClientGalleryContent'
import { ClientGalleryOrderInformation } from '../ClientGalleryOrderInformation'
import { ClientGalleryOrderTypeFilter } from '../ClientGalleryOrderTypeFilter/ClientGalleryOrderTypeFilter.module'
import { ClientGalleryPageActions } from '../ClientGalleryPageActions'
import { ClientGalleryRequests } from '../ClientGalleryRequests'
import FetchedContent from 'components/common/FetchedContent/FetchedContent'
import { GalleryHeader } from 'components/common/Gallery/GalleryHeader'
import { GalleryNotFound } from '../common/GalleryNotFound'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { PageContent } from 'components/common/Page/PageContent'
import { PageHeader } from 'components/common/Page/PageHeader'
import { PageLayout } from 'components/common/Page/PageLayout'
import { PageTab } from 'components/common/Page/PageTab'
import { PageTabs } from 'components/common/Page/PageTabs'
import { Path } from 'constants/router/paths'
import Stack from '@mui/material/Stack'
import { isEditingCategory } from 'utils/validators'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export enum Tab {
  GALLERY = 'gallery',
  ORDER_INFO = 'order_info',
  REQUESTS = 'requests'
}

const tabs = [Tab.GALLERY, Tab.REQUESTS, Tab.ORDER_INFO]

/**
 * Client gallery.
 * 
 * @example <ClientGalleryController />
 */
export const ClientGalleryController: FC = () => {
  const { t } = useTranslation(['gallery'])
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  const { assignmentStage, assignment } = useGalleryAssignment()
  const { product } = useGalleryProduct()
  const {
    order,
    dealData,
    dealError,
    totalRequestCountData,
    dealAssignmentsForTab,
    orderIncludesDocumentProductWithoutMeasurementOnSiteProduct,
  } = useGalleryDeal()
  const navigate = useNavigate()
  const { allVisuals } = useGalleryVisualsMeta()
  const { activeTab, setActiveTab, handleChangeTab } = useGalleryNavigation()

  const isDealNotFound = useMemo(() => {
    return order?.isError || (order?.isSuccess && !dealData)
  }, [dealData, order?.isError, order?.isSuccess])

  const headerTitle = useMemo(() => {
    if (isDealNotFound) return t('notfound:title')

    if (!dealData) return ''
    const { reference, address, productCategory } = dealData
    if (!reference && isEditingCategory(productCategory)) return t(`category:${productCategory}`)
    if (reference) return reference
    return address
  }, [dealData, isDealNotFound, t])

  const availableTabs = useMemo(() => {
    if (orderIncludesDocumentProductWithoutMeasurementOnSiteProduct) return tabs
    return tabs.filter(tab => tab !== Tab.REQUESTS)
  }, [orderIncludesDocumentProductWithoutMeasurementOnSiteProduct])

  const isStagingFilterToGroundPhotoRedirect =
    !!product?.kind &&
    STAGING_PRODUCT_KINDS.has(product?.kind) &&
    assignmentStage &&
    assignmentStage !== AssignmentStage.VISUALS_SENT_TO_CLIENT

  const firstGroundPhotoProduct = dealAssignmentsForTab.find((assignments) => assignments.type === ProductType.PHOTO && PRODUCTS_WITH_OPTIONAL_STAGING.has(assignments.products[0].kind))

  useEffect(() => {
    if (isStagingFilterToGroundPhotoRedirect && firstGroundPhotoProduct) navigate(Path.GALLERY.replace(':id', firstGroundPhotoProduct.id.toString()))
    // navigate not dep
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStagingFilterToGroundPhotoRedirect, firstGroundPhotoProduct])

  const showGalleryNotFound = !!assignment?.error || isDealNotFound

  return (
    <PageLayout
      header={
        <PageHeader
          actions={!!dealData && <ClientGalleryPageActions />}
        >
          {!!headerTitle &&
            <GalleryHeader
              title={headerTitle}
              subTitle={dealData?.reference ? dealData?.address : ''}
            />
          }
        </PageHeader>
      }
      tabs={
        <PageTabs>
          {availableTabs.map(tab =>
            <PageTab
              key={tab}
              tab={tab}
              tabText={t(tab)}
              activeTab={activeTab}
              totalCount={tab === Tab.REQUESTS ? totalRequestCountData ?? 0 : undefined}
              onClick={() => handleChangeTab(tab)}
              disabled={!dealData && tab !== activeTab}
            />
          )}
        </PageTabs>
      }
      layoutErrorContent={showGalleryNotFound && <GalleryNotFound errorType={dealError} />}
    >
      <Stack width="100%" alignItems="center" justifyContent="center" >

        <PageContent>
          {/* ASSIGNMENT TYPES FILTERS */}
          {!!dealData && activeTab === Tab.GALLERY && <ClientGalleryOrderTypeFilter />}
        </PageContent>

        {allVisuals &&
          <FetchedContent
            request={allVisuals}
            error={<GalleryNotFound errorType={dealError} />}
          >
            <PageContent>

              {/** VISUALS CONTENT */}
              <Stack
                height="100%"
                flex="1 1 100%"
                overflow="auto"
                alignItems="center"
                justifyContent="center"
                paddingBottom={isMobileView ? '13rem' : '8rem'}
              >

                {/** GALLERY CONTENT */}
                {!!dealData && activeTab === Tab.GALLERY && <ClientGalleryContent setActiveTab={setActiveTab} />}

                {/** ORDER INFORMATION */}
                {!!dealData && activeTab === Tab.ORDER_INFO && <ClientGalleryOrderInformation />}

                {/** THREAD REQUESTS */}
                {!!dealData && activeTab === Tab.REQUESTS && <ClientGalleryRequests />}

              </Stack>

            </PageContent>
          </FetchedContent>
        }

      </Stack>
    </PageLayout>

  )
}
