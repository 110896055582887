import { FC, useMemo } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { PropertyContextProvider, PropertyNavigationContextProvider } from '../contexts'

import NotFound from 'components/pages/General/NotFound/NotFound'
import { PageTransition } from 'utils/animations'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { redesignMUITheme } from 'constants/styling/redesignTheme'

export const Property: FC = () => {
  const { id } = useParams<{ id?: string }>()
  const propertyId = useMemo(() => id || undefined, [id])

  if (!propertyId) return <NotFound />

  return (
    <ThemeProvider theme={redesignMUITheme}>
      <PropertyNavigationContextProvider>
        <PropertyContextProvider>
          <PageTransition>
            <Outlet></Outlet>
          </PageTransition>
        </PropertyContextProvider>
      </PropertyNavigationContextProvider>
    </ThemeProvider>
  )
}
