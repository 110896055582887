import { BEIGE_700, GRAY_700, GRAY_800, WHITE } from 'constants/styling/theme'
import { Trans, useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { FC } from 'react'
import { GalleryImage } from 'components/common/Gallery/GalleryImage'
import { MUIBadge } from 'components/common/MUIBadge'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @interface Props
 */
interface Props {
  /** onClose Beta card callback */
  onClose: () => void
}

/**
 * Temporary client gallery beta Self editing promo card
 * 
 * @example <ClientGalleryBetaSelfEditingCard />
 */
export const ClientGalleryBetaSelfEditingCard: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation(['gallery'])
  const { hasUserActiveSubscription } = useUserData()

  const isMobileView = useMediaQuery(redesignMUITheme.breakpoints.down('lg'))

  return (
    <GalleryImage>
      <Stack padding={{ xs: 0.5, md: 1, l: 2 }}>

        <Stack sx={{ paddingTop: 2, position: 'absolute', top: '-1rem', right: '.5rem', cursor: 'pointer' }}>
          <CloseIcon onClick={onClose} />
        </Stack>

        <Stack marginX='auto'>
          <MUIBadge label="Beta" size="xs" color="beige" icon={<ScienceOutlinedIcon fontSize='small' />} />
        </Stack>

        <Typography paddingTop={{ xs: 1, l: 0 }} paddingBottom={{ xs: 1, l: 0 }} variant={isMobileView ? 'text-xs' : 'text-md'} fontWeight="bold">{t('client_gallery.beta_self_editing_card.title')}</Typography>

        <Typography variant={isMobileView ? 'body1' : 'text-sm'} fontWeight="regular" color={GRAY_700}>
          <Trans
            t={t}
            i18nKey='client_gallery.beta_self_editing_card.description'
          >
            <span style={{
              display: 'inline-block',
              justifyContent: 'center',
              alignItems: 'center',
              height: '1.8rem',
              width: '1.8rem',
              border: `solid .1rem ${BEIGE_700}`,
              backgroundColor: WHITE,
              borderRadius: '50%',
              fontSize: '1.2rem'
            }}>
              <EditOutlinedIcon fontSize='inherit' sx={{ color: GRAY_800 }} />
            </span>
          </Trans>
          {!hasUserActiveSubscription &&
            <Trans t={t} i18nKey={'client_gallery.beta_self_editing_card.non_subscribers_availability_date'} />
          }
        </Typography>

      </Stack>
    </GalleryImage>
  )
}
