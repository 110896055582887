import { MessageThreadDTO } from 'models/messageThreads'
import { Options } from 'components/common/FileAPI'
import React from 'react'
import { RequestThreadController } from './RequestThread.controller'

/** @interface Props for the RequestThread component. */
interface Props {
  /** Title of the request threads. */
  title: string
  /** ID of the assignment associated with the thread. */
  assignmentId?: string
  /** Array of message thread items. */
  threadMessageItems?: MessageThreadDTO[]
  /** Options for the file upload API handler, or 'none' to disable file uploads. */
  fileUploadOptions: Options | 'none'
  /** Callback function to handle the click event for the header button. */
  onHeaderButtonClick?: () => void
}

/**
 * `RequestThread`renders the `RequestThreadController` component.
 * It passes all received props to the `RequestThreadController`.
 *
 * @example <RequestThread title="Requests" assignmentId="123456-A" threadMessageItems={[]} />
 */
export const RequestThread: React.FC<Props> = (props) => {
  return (
    <RequestThreadController
      {...props}
    />
  )
}
