import { FC, useMemo } from 'react'
import { FloorPlanColors, getFloorPlanPreviewImages } from './basicFloorPlanConstants'

import Box from '@mui/material/Box'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { SimpleImage } from 'components/common/SimpleImage'
import { useFloorPlanConfig } from '../_main/FloorPlanConfigStep.context'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * FloorPlanPreview2D displays a preview image of the basic floor plan when 2D is selected.
 * 
 * @example 
 * <FloorPlanPreview2D />
 */
export const FloorPlanPreview2D: FC = () => {
  const { t } = useTranslation(['purchase_flow'])

  const {
    selectedColor,
    showFixtures,
    showFurniture,
    showRoomNames,
    showCompass,
    isActiveDimensions,
    showDimensionChain,
    showScalebar,
    showRoomArea,
    isActiveLogo,
    companyLogo,
    showDisclaimer,
    selectedTemplateId,
  } = useFloorPlanConfig()

  const previewDimensions = useMemo(() => {
    if (isActiveDimensions) {
      return {
        showDimensionChain,
        showRoomArea
      }
    }

    return {
      showRoomArea: false
    }
  }, [isActiveDimensions, showDimensionChain, showRoomArea])

  const previewUrls = useMemo(() => getFloorPlanPreviewImages({
    ...previewDimensions,
    color: Object.values(FloorPlanColors).includes(selectedColor as FloorPlanColors) ? selectedColor : FloorPlanColors.BLACK,
    showLogo: isActiveLogo && !!companyLogo,
    showFurniture,
    showFixtures,
    showRoomNames,
    showScalebar,
    showCompass,
    showDisclaimer,
  }), [companyLogo, isActiveLogo, previewDimensions, selectedColor, showCompass, showDisclaimer, showFixtures, showFurniture, showRoomNames, showScalebar])

  return (
    <SectionedBorderBox title={t('floor_plan_step.preview.example_picture')}>
      <Box sx={{ position: 'relative', width: '100%', height: '23rem' }}>
        {previewUrls.map((previewUrl) =>
          <SimpleImage
            key={`${previewUrl}-${selectedTemplateId ? selectedTemplateId : 'basic-2D'}`}
            url={previewUrl}
            sx={{
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              position: 'absolute',
            }}
            borderRadius='none'
          />
        )}
      </Box>
    </SectionedBorderBox>
  )
}
