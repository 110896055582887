import { AccessTime, CalendarToday, HourglassTop } from '@mui/icons-material'
import { GRAY_800, GRAY_900 } from 'constants/styling/theme'
import React, { useCallback, useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import { getDateString, getTimeString, isSameTimezone } from 'utils/time'

import { ISODateString } from 'models/misc'
import { Nullable } from 'models/helpers'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props EventTime properties
 */
interface Props {
  /** Start date of event */
  eventStart: ISODateString,
  /** Timezone of event */
  eventTimezone: string | null
  /** Timezone of logged user */
  userTimezone: string
  /** Min time on site in minutes */
  minDuration?: Nullable<number>
  /** Max time on site in minutes */
  maxDuration?: Nullable<number>
}

/**
 * @component Displays date and time frame of given event
 * @example
 * <EventTime eventStart={new Date().toISOString()} minDuration={30} maxDuration={60} eventTimezone="Europe/Berlin" userTimezone="Europe/Berlin" />
 */
export const EventTime: React.FC<Props> = ({
  eventStart,
  eventTimezone,
  userTimezone,
  minDuration,
  maxDuration,
}) => {

  const { t } = useTranslation(['deal_assignment', 'time_translations'])
  const timezonesMatch = useMemo(() => !!eventTimezone && !!isSameTimezone(eventTimezone, userTimezone), [eventTimezone, userTimezone])

  const getEventDurationTimeString = useCallback((start: string, timezone: string) => getTimeString(start, { timezone }), [])

  const getShortTimeFromDuration = useCallback((durationInMinutes: number) => {

    if (durationInMinutes === 0) return `${durationInMinutes} ${t('time_translations:min')}`

    const segments: string[] = []

    if (durationInMinutes / 60 >= 24) segments.push(`${Math.floor(durationInMinutes / 60 / 24)} ${t('time_translations:d')}`)
    if (durationInMinutes / 60 % 24 >= 1) segments.push(`${Math.floor(durationInMinutes / 60 % 24)} ${t('time_translations:hr')}`)
    if (durationInMinutes % 60 > 0) segments.push(`${durationInMinutes % 60} ${t('time_translations:min')}`)

    return segments.join(' ')
  }, [t])

  return (
    <Stack gap={{ xs: 3, md: 1.5 }}>
      {/* User timezone */}
      <Stack direction="row" gap={1} flexWrap="wrap">

        {/* Date */}
        <Stack direction="row" alignItems="center" gap={.3}>
          <CalendarToday fontSize="medium" htmlColor={GRAY_900} />
          <Typography variant="text-sm">
            {getDateString(eventStart, { timezone: userTimezone })}
          </Typography>
        </Stack>

        {/* Time + timezone */}
        <Stack direction="row" alignItems="center" gap={.3}>
          <AccessTime fontSize="medium" htmlColor={GRAY_900} />
          <Typography variant="text-sm">
            {getEventDurationTimeString(eventStart, userTimezone)}
            {(!eventTimezone || !timezonesMatch) &&
              <span> {t('deal_assignment:timezone:user_timezone')}: {userTimezone}</span>
            }
          </Typography>
        </Stack>

        {/* Range */}
        {(typeof minDuration === 'number' || typeof maxDuration === 'number') &&
          <Stack direction="row" alignItems="center" gap={.3}>
            <HourglassTop fontSize="medium" htmlColor={GRAY_900} />
            <Typography variant="text-sm">
              {t('time_translations:approx')} {typeof minDuration === 'number' && minDuration > 0 && `${getShortTimeFromDuration(minDuration)} - `} {typeof maxDuration === 'number' && getShortTimeFromDuration(maxDuration)}
            </Typography>
          </Stack>
        }

      </Stack>

      {/* Location timezone */}
      {!!eventTimezone && !timezonesMatch && (
        <Stack direction="row" gap={1} flexWrap="wrap" fontWeight={600} color={GRAY_800}>

          {/* Date */}
          <Stack direction="row" alignItems="center" gap={.3}>
            <CalendarToday fontSize="medium" htmlColor={GRAY_900} />
            <Typography variant="text-sm">
              {getDateString(eventStart, { timezone: eventTimezone })}
            </Typography>
          </Stack>

          {/* Time + timezone */}
          <Stack direction="row" alignItems="center" gap={.3}>
            <AccessTime fontSize="medium" htmlColor={GRAY_900} />
            <Typography variant="text-sm">
              {getEventDurationTimeString(eventStart, eventTimezone)}
              {(!eventTimezone || !timezonesMatch) &&
                <span> {t('deal_assignment:timezone:location_timezone')}: {eventTimezone}</span>
              }
            </Typography>
          </Stack>

          {/* Range */}
          {(typeof minDuration === 'number' || typeof maxDuration === 'number') &&
            <Stack direction="row" alignItems="center" gap={.3}>
              <HourglassTop fontSize="medium" htmlColor={GRAY_900} />
              <Typography variant="text-sm">
                {t('time_translations:approx')} {typeof minDuration === 'number' && minDuration > 0 && `${getShortTimeFromDuration(minDuration)} - `} {typeof maxDuration === 'number' && getShortTimeFromDuration(maxDuration)}
              </Typography>
            </Stack>
          }

        </Stack>
      )}

    </Stack>
  )
}
