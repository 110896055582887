import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { FC, Fragment, useEffect } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowProducts, useTargetOrderUser } from '../../_main/contexts'

import { FileAPIUpload } from 'components/common/FileAPIUpload'
import { FileType } from 'constants/misc'
import { PowerOfAttorneyUpload } from 'components/common/PowerOfAttorneyUpload'
import { Stack } from '@mui/material'
import { StepWrapper } from '../../common/StepWrapper'
import { usePowerOfAttorneyStep } from './PowerOfAttorneyStep.context'
import { useTranslation } from 'react-i18next'

const powerOfAttorneyForOwner = `${process.env.PUBLIC_URL}/assets/power-of-attorney-for-owner.pdf`
const powerOfAttorneyForOwnerRepresentatives = `${process.env.PUBLIC_URL}/assets/power-of-attorney-for-owner-representatives.pdf`

export const PowerOfAttorneyStepController: FC = () => {
  const { t } = useTranslation(['power_of_attorney', 'purchase_flow'])

  const { targetUser } = useTargetOrderUser()
  const { powerOfAttorneyUpload, powerOfAttorneyRepsUpload, powerOfAttorneyOtherFilesUpload } = usePowerOfAttorneyStep()
  const { selectedCategory } = usePurchaseFlowConfig()
  const {
    selectedProducts,
    selectedKinds,
    selectedProductTypes
  } = usePurchaseFlowProducts()

  const onCheckFileSize = (fileSize: number) => {
    // Check if file size is bigger than 5MB
    if (fileSize > 5 * 1024 * 1024) return false

    return true
  }

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvent.ENTER_ORDER_POA_PAGE_SCREEN, {
      productTypes: Array.from(selectedProductTypes),
      productKinds: Array.from(selectedKinds),
      products: Object.values(selectedProducts),
      category: selectedCategory,
    })

    // Only trigger when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StepWrapper
      title={t('purchase_flow:power_of_attorney_step.section_title')}
      subtitle={t('purchase_flow:power_of_attorney_step.section_description')}
    >
      <Stack direction="column" gap={2} alignItems="stretch" justifyContent="flex-start">

        {!targetUser.requiresRepresentativePowerOfAttorney &&
          <PowerOfAttorneyUpload
            variant="owners"
            boxStyle="elevated"
            sampleUrl={powerOfAttorneyForOwner}
            uploadSlot={
              <FileAPIUpload
                title={t('power_of_attorney')}
                files={powerOfAttorneyUpload?.allFilesArray}
                maxNumberOfFiles={1}
                isDraggingFile={false}
                acceptedFileTypes={[FileType.PDF]}
                fileSizeCheckFailedWarning={t('purchase_flow:power_of_attorney_step.file_size_warning')}
                onCheckFileSize={onCheckFileSize}
                uploadHandler={(acceptedFiles) => powerOfAttorneyUpload.uploadFiles(acceptedFiles)}
                onDelete={(fileId) => powerOfAttorneyUpload.deleteFiles([fileId])}
              />
            }
          />
        }

        {targetUser.requiresRepresentativePowerOfAttorney &&
          <Fragment>
            <PowerOfAttorneyUpload
              variant="owner_from_owner"
              boxStyle="elevated"
              uploadContentTitle={t('upload_content_title_general_info')}
              uploadSlot={
                <FileAPIUpload
                  title={t('power_of_attorney_from_owner_to_owner')}
                  files={powerOfAttorneyUpload?.allFilesArray}
                  maxNumberOfFiles={1}
                  isDraggingFile={false}
                  acceptedFileTypes={[FileType.PDF]}
                  fileSizeCheckFailedWarning={t('purchase_flow:power_of_attorney_step.file_size_warning')}
                  onCheckFileSize={onCheckFileSize}
                  uploadHandler={(acceptedFiles) => powerOfAttorneyUpload.uploadFiles(acceptedFiles)}
                  onDelete={(fileId) => powerOfAttorneyUpload.deleteFiles([fileId])}
                />
              }
            />

            <PowerOfAttorneyUpload
              variant="representatives"
              boxStyle="elevated"
              sampleUrl={powerOfAttorneyForOwnerRepresentatives}
              uploadSlot={
                <FileAPIUpload
                  title={t('power_of_attorney_reps')}
                  files={powerOfAttorneyRepsUpload?.allFilesArray}
                  maxNumberOfFiles={1}
                  isDraggingFile={false}
                  acceptedFileTypes={[FileType.PDF]}
                  fileSizeCheckFailedWarning={t('purchase_flow:power_of_attorney_step.file_size_warning')}
                  onCheckFileSize={onCheckFileSize}
                  uploadHandler={(acceptedFiles) => powerOfAttorneyRepsUpload.uploadFiles(acceptedFiles)}
                  onDelete={(fileId) => powerOfAttorneyRepsUpload.deleteFiles([fileId])}
                />
              }
            />
          </Fragment>
        }

        {/* Let user upload any other files */}
        <FileAPIUpload
          title={t('other_files_upload')}
          files={powerOfAttorneyOtherFilesUpload.allFilesArray}
          acceptedFileTypes={[FileType.PDF]}
          isDraggingFile={false}
          fileSizeCheckFailedWarning={t('purchase_flow:power_of_attorney_step.file_size_warning')}
          onCheckFileSize={onCheckFileSize}
          uploadHandler={(acceptedFiles) => powerOfAttorneyOtherFilesUpload.uploadFiles(acceptedFiles)}
          onDelete={(fileId) => powerOfAttorneyOtherFilesUpload.deleteFiles([fileId])}
          maxNumberOfFiles={15}
        />

      </Stack>
    </StepWrapper>
  )
}
