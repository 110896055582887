import { BLUE_600, CORAL_100, CORAL_600, GRAY_100, GRAY_400, GRAY_900, WHITE } from 'constants/styling/theme'
import { InputBase, Stack, Typography, inputBaseClasses, styled } from '@mui/material'
import React, { ChangeEvent, KeyboardEvent, ReactNode, useMemo } from 'react'

import { BorderBoxWrapper } from '../BorderBoxWrapper/BorderBoxWrapper.component'
import { CopyButton } from '../CopyButton'
import { Label } from '../Label'
import { MUIInputFieldButton } from './MUIInputFieldButton'

export enum InputFieldStyleTypes {
  DEFAULT,
  COPY
}

/**
 * @typedef InputStyleType Available input types
 */
export type InputStyleType = InputFieldStyleTypes.DEFAULT | InputFieldStyleTypes.COPY

/**
 * @interface MUIInputFieldProps Input properties
 */
export interface MUIInputFieldProps {
  /** Input type style */
  type?: InputStyleType,
  /** Input value */
  value?: string,
  /** Input placeholder */
  placeholder?: string,
  /** Custom input box width */
  inputBoxWidth?: string,
  /** Whether the input is read only or not */
  readOnly?: boolean,
  /** Input block label */
  label?: string
  /** Whether input is error or not */
  isError?: boolean
  /** Input error text */
  errorText?: string | null
  /** Input hint text */
  hintText?: string | null | ReactNode
  /** Allow input field with multiple lines. Material UI will automatically treat input as textarea. */
  isMultiline?: boolean
  /** Whether the input is disabled or not */
  disabled?: boolean
  /** Whether the input is required or not */
  required?: boolean
  /** Custom input component */
  customInput?: ReactNode
  /** Custom suffix to be displayed at the end of the input. */
  suffix?: string
  /** Input element type, default is 'text' */
  inputElementType?: string,
  /** Custom tooltip besides label */
  tooltip?: ReactNode,
  /** OnChange action to update the input value */
  onBlur?: () => void
  /** OnChange action to update the input value */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  /** OnClick action to copy the input value */
  onClickCopy?: () => void
  /** onKeyDown action triggered when user presses any key on the keyboard */
  onKeyDown?: (e: KeyboardEvent) => void
}

export const StyledMUIInput = styled(InputBase)(({ error, required }) => {
  return {
    width: '100%',
    flex: '1 1 6rem',
    [`.${inputBaseClasses.input}`]: {
      padding: '.8rem 1.2rem !important',
      border: 'unset !important',
      borderRadius: '.8rem',
      color: `${error ? CORAL_600 : GRAY_900} !important`,
      backgroundColor: WHITE,
      boxShadow: 'unset !important',
      ':focus': {
        color: `${GRAY_900} !important`
      }
    },
    [`.${inputBaseClasses.input}:disabled`]: {
      backgroundColor: `${GRAY_100} !important`,
    }
  }
})

/**
 * @component MUI Input field
 * @example
 * <MUIInputField
 *  type="copy"
 *  value="owo!"
 *  onChange={() => {}}
 * />
 */
export const MUIInputField: React.FC<MUIInputFieldProps> = ({
  type = 'default',
  value,
  isError,
  errorText,
  hintText,
  placeholder,
  inputBoxWidth,
  readOnly = false,
  label = undefined,
  isMultiline,
  disabled = false,
  required,
  customInput,
  suffix,
  inputElementType,
  tooltip,
  onBlur,
  onChange,
  onClickCopy,
  onKeyDown,
}) => {

  const hoverBorderColor = useMemo(() => {
    if (isError) return 'inherit'
    if (disabled) return GRAY_400
    return BLUE_600
  }, [disabled, isError])

  const inputBgColor = useMemo(() => {
    if (isError) return CORAL_100
    if (disabled) return GRAY_100
    return 'inherit'
  }, [disabled, isError])

  return (
    <Stack gap={.6}>
      {!!label &&
        <Label
          text={label}
          tooltip={tooltip}
          isError={isError}
          required={required}
        />
      }

      <BorderBoxWrapper
        borderColor={isError ? CORAL_600 : GRAY_400}
        backgroundColor={inputBgColor}
        component="form"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          transition: '.3s ease',
          overflow: 'hidden',
          width: inputBoxWidth,
          '&:hover': {
            border: `.1rem solid ${hoverBorderColor}`,
          }
        }}
      >

        {customInput ||
          <StyledMUIInput
            value={value}
            error={isError}
            disabled={disabled}
            placeholder={placeholder}
            readOnly={readOnly}
            onBlur={onBlur}
            onChange={onChange}
            multiline={isMultiline}
            onKeyDown={onKeyDown}
            required={required}
          />
        }

        {type === InputFieldStyleTypes.COPY &&
          <MUIInputFieldButton>
            <CopyButton
              variant='text'
              showIcon={true}
              showTooltip={false}
              showIdleIcon={true}
              onCopy={onClickCopy}
            />
          </MUIInputFieldButton>
        }

        {suffix &&
          <MUIInputFieldButton disabled>
            <Typography variant="text-md" color={GRAY_900} fontWeight={500}>
              {suffix}
            </Typography>
          </MUIInputFieldButton>
        }

      </BorderBoxWrapper>

      {!!hintText && !isError &&
        <Label text={hintText} />
      }

      {isError && errorText &&
        <Label text={errorText} isError={true} />
      }

    </Stack>
  )
}
