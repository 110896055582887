import { FC, Fragment } from 'react'

import { PageTransition } from 'utils/animations'
import { VisualPinturaEditingContextProvider } from './VisualPinturaEditing.context'
import { VisualPinturaEditingController } from './VisualPinturaEditingController.controller'
import { useLocation } from 'react-router-dom'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useTranslation } from 'react-i18next'

export const VisualPinturaEditing: FC = () => {
  const { t } = useTranslation(['visual_pintura_editing'])
  const location = useLocation()

  const state = location.state as { file: File, imageUrl: string, assignmentId: string, isFileVersioned: boolean }

  const { file, imageUrl, assignmentId, isFileVersioned } = state

  const { spawnErrorToast } = useSnackbar()

  if (!file.name) {
    spawnErrorToast(t('error_missing_visual_name'))
    window.history.back()
    return null
  }

  return (
    <Fragment>
      <VisualPinturaEditingContextProvider file={file} imageUrl={imageUrl} assignmentId={assignmentId} isFileVersioned={isFileVersioned}>
        <PageTransition>
          <VisualPinturaEditingController />
        </PageTransition>
      </VisualPinturaEditingContextProvider>
    </Fragment>
  )
}
