import { BorderBoxWrapper, BorderBoxWrapperProps } from 'components/common/BorderBoxWrapper'
import { FC, ReactNode } from 'react'

import { GRAY_900 } from 'constants/styling/theme'
import { IconButton } from '@mui/material'
import { MUIDivider } from 'components/common/MUIDivider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

/** Props for the SectionedBorderBox component. */
interface Props extends Omit<BorderBoxWrapperProps, 'title'> { // For some reason I get an error if I don't use Omit here
  /** The main title displayed in the sectioned border box. */
  title: string | ReactNode
  /** An optional description displayed below the main title. */
  description?: string
  /** OnIconClick function to be called */
  onIconClick?: () => void
  /** Custom icon displaying on the right side of header. Binding with function onIconClick. */
  customIcon?: ReactNode
  /** The content to be wrapped by the SectionedBorderBox component. */
  children: ReactNode
}

/**
 * @component
 * SectionedBorderBox component provides a styled border box with a title, optional description,
 * and content section separated by a divider.
 *
 * @example
 * <SectionedBorderBox
 *   title="Dunder Mifflin Infinity"
 *   description="The best paper company in Scranton."
 *   boxProps={{ borderColor: BLUE_600 }}
 * >
 *   <SectionContent />
 * </SectionedBorderBox>
 */
export const SectionedBorderBox: FC<Props> = ({
  title,
  description,
  children,
  onIconClick,
  customIcon,
  ...boxProps
}) => {

  return (
    <BorderBoxWrapper padding={2} {...boxProps}>

      {/** HEADER */}
      <Stack flexDirection="row" justifyContent="space-between">

        <Stack gap={0.5} width="100%">
          {typeof title === 'string'
            ? <Typography color={GRAY_900} variant='text-lg' fontWeight={600}>{title}</Typography>
            : title // ReactNode
          }
          {description && <Typography variant="text-sm">{description}</Typography>}
        </Stack>

        {onIconClick &&
          <IconButton
            sx={{ width: '3rem', height: '3rem' }}
            onClick={onIconClick}
          >
            {customIcon}
          </IconButton>}

      </Stack>

      {/** DEFAULT DIVIDER */}
      <MUIDivider margin={12} />

      {/** CONTENT */}
      {children}

    </BorderBoxWrapper>
  )
}
