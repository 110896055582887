import React from 'react'
import Stack from '@mui/material/Stack'

export const PropertyAITextGenerator: React.FC = () => {
  return (
    <Stack>
      <span>Property AI Text Generator</span>
    </Stack>
  )
}
