import React, { useMemo } from 'react'

import Collapse from '@mui/material/Collapse'
import { EmptyThread } from '../EmptyThread'
import { GRAY_900 } from 'constants/styling/theme'
import { MUIButton } from 'components/common/MUIButton'
import { MessageThreadDTO } from 'models/messageThreads'
import { Options } from 'components/common/FileAPI'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { Thread } from '../Thread'
import { TransitionGroup } from 'react-transition-group'
import Typography from '@mui/material/Typography'
import { standardTimeoutConfig } from 'utils/animations'
import { useThreads } from '../context'

/** @interface Props for the RequestThread component. */
interface Props {
  /** Title of the request threads. */
  title: string
  /** ID of the assignment associated with the thread. */
  assignmentId?: string
  /** Array of message thread items. */
  threadMessageItems?: MessageThreadDTO[]
  /** Options for the file upload API handler, or 'none' to disable file uploads. */
  fileUploadOptions: Options | 'none'
  /** Callback function to handle the click event for the header button. */
  onHeaderButtonClick?: () => void
}

/**
 * @component
 * RequestThreadController displays a thread request and manages the display and interaction of thread of messages.
 *
 * @example 
 * <RequestThreadController />
 */
export const RequestThreadController: React.FC<Props> = ({ title, assignmentId, threadMessageItems, fileUploadOptions, onHeaderButtonClick }) => {

  const { headerButtonText } = useThreads()

  /** List of thread messages sorted by state and creation date. */
  const threadList = useMemo(() => {
    if (!threadMessageItems || threadMessageItems.length === 0) return []

    return threadMessageItems.sort((a, b) => {
      if (a.state === 'OPEN' && b.state !== 'OPEN') return -1
      if (a.state !== 'OPEN' && b.state === 'OPEN') return 1
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    })
  }, [threadMessageItems])

  return (
    <SectionedBorderBox
      title={
        <Stack flexDirection="row" gap={1} justifyContent="space-between" alignItems="center">

          <Typography color={GRAY_900} variant="text-lg" fontWeight={600}>
            {title}
          </Typography>

          {headerButtonText &&
            <MUIButton type="secondaryBorder" onClick={onHeaderButtonClick}>
              {headerButtonText}
            </MUIButton>
          }

        </Stack>
      }
    >
      {/** EMPTY CONTENT */}
      {threadList.length === 0 && <EmptyThread />}

      {/** THREADS LIST */}
      {threadList.length > 0 &&
        <Stack gap={2} minHeight="30rem">
          <TransitionGroup component={null}>
            {threadList.map((threadData) => (
              <Collapse key={threadData.id} timeout={standardTimeoutConfig}>

                <Thread
                  assignmentId={assignmentId ?? ''}
                  fileUploadOptions={fileUploadOptions !== 'none' ? fileUploadOptions : {}}
                  {...threadData}
                />

              </Collapse>
            ))}
          </TransitionGroup>
        </Stack>
      }

    </SectionedBorderBox>
  )
}
