import '@pqina/pintura/pintura.css'

import { FeatureFlag } from 'utils/featureFlags'
import { MUIButton } from 'components/common/MUIButton'
import { PinturaImageState } from '@pqina/pintura'
import Stack from '@mui/material/Stack'
import { useFlag } from '@unleash/proxy-client-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/** interface @Props for the VisualPinturaEditingFooter component. */
interface Props {
  /** onClose callback */
  onClose: () => void
  /** onSave callback */
  onSave: (tag: string) => void
  /** Whether the Pintura is processing image */
  isProcessing: boolean
  /** Whether the image is already versioned */
  isFileVersioned: boolean
  /** Pintura history */
  pinturaHistory: PinturaImageState[] | undefined
}

export enum PinturaSave {
  PINTURA_RE_EDIT_VISUAL = 'PINTURA_RE_EDIT_VISUAL',
  PINTURA_EDIT_VISUAL = 'PINTURA_EDIT_VISUAL',
}

/**
 * Visual Pintura editing Footer component
 * @example <VisualPinturaEditingFooter/>
 */
export const VisualPinturaEditingFooter: React.FC<Props> = ({
  onClose,
  onSave,
  isProcessing,
  isFileVersioned,
  pinturaHistory
}) => {
  const { t } = useTranslation(['visual_pintura_editing'])

  const [tag, setTag] = useState<PinturaSave>(PinturaSave.PINTURA_EDIT_VISUAL)

  const isPinturaHistoryEmpty = !pinturaHistory || pinturaHistory.length === 0
  const isBetaSelfEditingCard = useFlag(FeatureFlag.BETA_SELF_EDIT_WIDGET)
  const { hasUserActiveSubscription } = useUserData()

  return (
    <Stack
      gap={2}
      height="100%"
      marginRight={4}
      alignItems="center"
      flexDirection="row"
      justifyContent="flex-end"
    >

      <MUIButton
        type="secondaryNoBorder"
        onClick={onClose}
        disabled={isProcessing}
      >
        {t('cancel')}
      </MUIButton>

      {isFileVersioned &&
        <MUIButton
          type="secondaryNoBorder"
          onClick={() => {
            onSave(PinturaSave.PINTURA_RE_EDIT_VISUAL)
            setTag(PinturaSave.PINTURA_RE_EDIT_VISUAL)
          }}
          disabled={isProcessing || isPinturaHistoryEmpty || (!hasUserActiveSubscription && !isBetaSelfEditingCard)}
          isLoading={isProcessing && tag === PinturaSave.PINTURA_RE_EDIT_VISUAL}
        >
          {t('save_and_replace')}
        </MUIButton>
      }

      <MUIButton
        type="defaultPrimary"
        variant="outlined"
        onClick={() => onSave(PinturaSave.PINTURA_EDIT_VISUAL)}
        disabled={isProcessing || isPinturaHistoryEmpty || (!hasUserActiveSubscription && !isBetaSelfEditingCard)}
        isLoading={isProcessing && tag === PinturaSave.PINTURA_EDIT_VISUAL}
      >
        {t(isFileVersioned ? 'save_as_new' : 'save')}
      </MUIButton>

    </Stack>
  )
}
