import 'components/pages/Gallery/_main/Gallery.sass'

import { Color, ColorClass, IconType } from 'constants/assets'
import { ErrorMessageType, errorMessageType, externalNavigate, getDownloadErrorsString } from 'utils/helpers'
import { FC, Fragment, KeyboardEvent, MouseEvent as ReactMouseEvent, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { GalleryBreadcrumbs, GalleryProductFeedback, GalleryUploadErrorPopup, GalleryVirtualVisitInstructions, SubmitAssignmentModal } from '../../common'
import { POST_WATERMARK, VisualFileType } from 'constants/visual'
import { Trans, useTranslation } from 'react-i18next'
import { VisualTypeSelectEnumType, useGallery, useGalleryAssignment, useGalleryConstants, useGalleryDocumentsContext, useGalleryOrder, useGalleryProduct, useGalleryVirtualVisit, useGalleryVisualSelection, useGalleryVisualType, useGalleryVisuals, useGalleryVisualsMeta } from '../contexts'
import { deleteVisual, uploadVisual, uploadVisualReplacement } from 'redux/Individual/Visual/LoadVisual'
import { useSubmitAssignment, useSubmitDocumentAssignment } from 'dataQueries'

import { APIRequestErrorType } from 'constants/API'
import { AnalyticsEvent } from 'utils/analytics'
import { AssignmentDTOIsCreativeDTO } from 'utils/typeguards'
import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import Button from 'components/common/Button/Button'
import { CheckCircleOutlined } from '@mui/icons-material'
import { CreativeProductInstruction } from 'utils/product'
import { DocumentGalleryContent } from 'components/common/DocumentGalleryContent'
import { DocumentUploadType } from 'constants/documents'
import DynamicQueryContent from 'components/common/DynamicQueryContent/DynamicQueryContent'
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage'
import { FeatureFlag } from 'utils/featureFlags/featureFlags'
import FetchedContent from 'components/common/FetchedContent/FetchedContent'
import { FileAvailableActions } from 'components/common/FileAPI/fileUtils'
import { FileState } from 'components/common/FileAPI'
import GalleryImage from 'components/common/GalleryImage/GalleryImage'
import Icon from 'components/common/Icon/Icon'
import { KeyboardEventKey } from 'constants/misc'
import { Link } from 'react-router-dom'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDivider } from 'components/common/MUIDivider'
import { MUITooltip } from 'components/common/MUITooltip'
import Modal from 'components/common/Modals/Modal/Modal'
import { Nullable } from 'models/helpers'
import { PIPEDRIVE_INFINITY } from 'constants/pipedrive'
import { Path } from 'constants/router'
import { ProductKind } from 'constants/product'
import ProgressCard from 'components/common/ProgressCard/ProgressCard'
import ReactLoading from 'react-loading'
import TriangleIcon from 'components/common/TriangleIcon/TriangleIcon'
import { UploadImage } from 'models/redux'
import closeButtonStyles from 'components/styles/close-button.module.sass'
import { getSubscriptionPricingLink } from 'utils/localization/subscriptionPricing'
import i18n from 'translations/i18n'
import { multilineText } from 'utils/formatting'
import { supportEmail } from 'constants/contacts'
import { useActionPopup } from 'utils/hooks/useActionPopup'
import { useDispatch } from 'react-redux'
import { useDraggingFile } from 'utils/hooks/useDraggingFile'
import { useFlag } from '@unleash/proxy-client-react'
import { useImageRegex } from 'utils/hooks'
import { useUserData } from 'components/contexts/UserDataContext'
import { wrapGrid } from 'animate-css-grid'

export const CreativeGalleryController: FC = () => {
  const allowResumableUploads = useFlag(FeatureFlag.ALLOW_RESUMABLE_UPLOADS)
  const uploadType = allowResumableUploads ? 'resumable' : 'standard'

  const { assignmentId } = useGallery()

  const {
    VisualTypeSelectEnum,
    visualTypeIconMap,
    stagesUnlockingCreativeSendAssignmentVisualsToAdmin,
    stagesUnlockingCreativeManipulateVisuals,
    productKindsUnlockingMatterportLinks,
    beforeUnloadHandler,
  } = useGalleryConstants()

  const {
    assignment,
    assignmentData,
    product,
    assignmentStage,
    isAssignmentDelivered,
    isFeedbackButtonAllowed,
    isAssignmentSubmittedByCT,
    logGalleryEvent,
  } = useGalleryAssignment()

  const {
    visualTypeSelect,
    setVisualTypeSelect,
    originalType,
    thumbnailType,
    webType,
    normalizedOriginalType,
  } = useGalleryVisualType()

  const {
    isVideo,
    isVirtualVisit,
    isDocumentProduct,
  } = useGalleryProduct()

  const {
    sortEntriesFunction,
  } = useGalleryOrder()

  const {
    allVisuals,
    purchasedVisualsKeys,
    uploadMinCount,
    uploadMaxCount,
    selectMaxValue,
  } = useGalleryVisualsMeta()

  const {
    downloadVisuals,
    downloadVisualsEntries,
    uploadVisualsEntries,
    downloadVisualsKeys,
    downloadVisualsCount,
    uploadVisualsCount,
    uploadVisualsOriginalFilenames,
    visualsCount,
    listedVisuals,
    visualsKeyToOriginalNameDictionary,
    downloadVisualsWithErrorEntries,
  } = useGalleryVisuals()

  const {
    virtualVisitID,
    virtualVisitExpired,
  } = useGalleryVirtualVisit()

  const {
    selected,
    setSelected,
    canSelectVisuals,
    selectableVisualsCount,
    selectAll,
    deselectAll,
    toggleSelectOnImage,
  } = useGalleryVisualSelection()

  const { galleryDocumentsFileController } = useGalleryDocumentsContext()

  const dispatch = useDispatch()
  const { t } = useTranslation(['gallery', 'deal_assignment', 'common'])

  // REACT QUERIES
  const submitAssignment = useSubmitAssignment()
  const submitDocumentAssignment = useSubmitDocumentAssignment()
  const { showConfirm } = useActionPopup()
  const { creativeData } = useUserData()
  const isDraggingFile = useDraggingFile()

  const [visualsGrid, setVisualsGrid] = useState<HTMLDivElement | undefined>(undefined)
  const setVisualsGridRef = useCallback((node: HTMLDivElement) => {
    if (node) setVisualsGrid(node)
  }, [])

  const visualUploadingTriggered = useRef(false)

  const [isLightboxModalOpen, setLightboxModalOpen] = useState(false)
  const [expandedImageName, setExpandedImageName] = useState<string | undefined>(undefined)
  const [isVisualSubmitOpen, setIsVisualSubmitOpen] = useState(false)
  const [isDocumentSubmitOpen, setIsDocumentSubmitOpen] = useState(false)
  const [isUploadErrorPopupOpen, setIsUploadErrorPopupOpen] = useState(false)

  const visualsRecentlySubmited = useMemo(() => (submitAssignment.isPending || submitAssignment.isSuccess), [submitAssignment])
  const isAssignmentStageUnlockingCreativeManipulateVisuals = useMemo(() => !!assignmentStage && stagesUnlockingCreativeManipulateVisuals.has(assignmentStage), [assignmentStage, stagesUnlockingCreativeManipulateVisuals])
  const canDeleteVisuals = useMemo(() => !isVirtualVisit && (originalType === VisualFileType.RAW && isAssignmentStageUnlockingCreativeManipulateVisuals), [isVirtualVisit, originalType, isAssignmentStageUnlockingCreativeManipulateVisuals])
  const canUploadVisuals = useMemo(() => !isVirtualVisit && originalType !== POST_WATERMARK && (originalType === VisualFileType.RAW && isAssignmentStageUnlockingCreativeManipulateVisuals), [isVirtualVisit, originalType, isAssignmentStageUnlockingCreativeManipulateVisuals])
  const canReplaceVisuals = useMemo(() => originalType !== POST_WATERMARK && (originalType === VisualFileType.RAW && isAssignmentStageUnlockingCreativeManipulateVisuals), [originalType, isAssignmentStageUnlockingCreativeManipulateVisuals])
  const showUploadAdvice = useMemo(() => originalType === VisualFileType.RAW && isAssignmentStageUnlockingCreativeManipulateVisuals, [isAssignmentStageUnlockingCreativeManipulateVisuals, originalType])
  const canSubmitVisuals = useMemo(() => {
    if (!assignmentStage) return false
    if (!stagesUnlockingCreativeSendAssignmentVisualsToAdmin.has(assignmentStage)) return false
    if (!!product) return !productKindsUnlockingMatterportLinks.has(product?.kind)
    return true
  }, [product, productKindsUnlockingMatterportLinks, assignmentStage, stagesUnlockingCreativeSendAssignmentVisualsToAdmin])
  const canCreativeSeeEditedVisuals = useMemo(() => {
    return (isAssignmentDelivered && !isVirtualVisit && !isVideo)
  }, [isAssignmentDelivered, isVirtualVisit, isVideo])
  const availableVisualTypes = useMemo(() => {
    const types: VisualTypeSelectEnumType[] = [VisualTypeSelectEnum.RAW]
    if (canCreativeSeeEditedVisuals) types.push(VisualTypeSelectEnum.POST_WATERMARK)

    return types
  }, [VisualTypeSelectEnum, canCreativeSeeEditedVisuals])

  // DOCUMENTS
  const allDocuments = useMemo(() => galleryDocumentsFileController.filesByTag[DocumentUploadType.OUTPUT] ?? [], [galleryDocumentsFileController.filesByTag])
  const availableDocuments = useMemo(() => allDocuments.filter((file) => FileAvailableActions.has(file.action) && file.state === FileState.SUCCESS), [allDocuments])
  const isAnyDocumentActionRunning = useMemo(() => allDocuments.some((file) => file.state === FileState.RUNNING), [allDocuments])

  // Virtual visit
  const showVirtualVisitInstructions = useMemo(() => {
    return !(product?.kind === ProductKind.HD && originalType === VisualFileType.POST)
  }, [originalType, product?.kind])

  // Grid animations
  useLayoutEffect(() => {
    if (visualsGrid) wrapGrid(visualsGrid)
  }, [visualsGrid])

  useEffect(() => {
    if (uploadVisualsCount > 0) {
      visualUploadingTriggered.current = true
    }
  }, [uploadVisualsCount])

  useEffect(() => {
    // use uploadRef to check if the download process is after uploading or not. 
    // If so, wait until all the download visuals process finish then show the error msg popup if there's error in downloadEntries.
    if (visualUploadingTriggered.current && !isUploadErrorPopupOpen && downloadVisualsWithErrorEntries.length && (downloadVisualsCount === visualsCount)) {
      setIsUploadErrorPopupOpen(true)
      logGalleryEvent(AnalyticsEvent.UPLOAD_ERROR, {
        userEmail: creativeData?.email,
        userType: 'creative',
        errorReason: getDownloadErrorsString(downloadVisualsWithErrorEntries, thumbnailType),
      })
    }
  }, [creativeData?.email, downloadVisualsCount, downloadVisualsWithErrorEntries, downloadVisualsWithErrorEntries.length, isUploadErrorPopupOpen, logGalleryEvent, thumbnailType, visualsCount])

  const closeLightboxModal = useCallback((e?: ReactMouseEvent<Element, MouseEvent>) => {
    if (e) e.preventDefault()
    setLightboxModalOpen(false)
    setTimeout(() => {
      setExpandedImageName(undefined)
    }, 500)
  }, [])

  const expandedImage = useMemo(() => {
    if (!expandedImageName) return undefined
    if (!downloadVisuals?.[expandedImageName]) return undefined
    return downloadVisuals[expandedImageName]
  }, [expandedImageName, downloadVisuals])

  const expandedImageWebType = useMemo(() => expandedImage?.[webType], [expandedImage, webType])

  const switchExpandedImage = useCallback((direction: 'next' | 'previous') => {
    if (!expandedImage) return
    if (!downloadVisuals) return

    const isDirectionNext = direction === 'next'

    if (downloadVisualsCount === 0) return
    let chooseThisOne = false

    for (let i = isDirectionNext ? 0 : downloadVisualsCount - 1; isDirectionNext ? i < downloadVisualsCount : i >= 0; isDirectionNext ? i++ : i--) {
      if (chooseThisOne) return setExpandedImageName(downloadVisualsKeys[i])
      if (expandedImageName === downloadVisualsKeys[i]) chooseThisOne = true
    }

    return setExpandedImageName(isDirectionNext ? downloadVisualsKeys[0] : downloadVisualsKeys[downloadVisualsCount - 1])
  }, [expandedImage, expandedImageName, downloadVisuals, downloadVisualsKeys, downloadVisualsCount])

  const deleteSingleVisual = useCallback((visualKey: string) => {
    if (!canDeleteVisuals) return
    if (!visualKey) return
    if (selected.has(visualKey)) {
      selected.delete(visualKey)
      setSelected(new Set(selected))
    }
    dispatch(deleteVisual(assignmentId, visualKey, normalizedOriginalType))
  }, [canDeleteVisuals, selected, dispatch, assignmentId, normalizedOriginalType, setSelected])

  const deleteAllVisuals = useCallback(async () => {
    if (!canDeleteVisuals) return
    if (!(await showConfirm(t('delete_all_prompt')))) return
    if (downloadVisuals) {
      for (let downloadKey of downloadVisualsKeys) {
        dispatch(deleteVisual(assignmentId, downloadKey, normalizedOriginalType))
      }
    }
    if (uploadVisualsEntries) {
      for (let entry of uploadVisualsEntries) {
        const uploadImage = entry[1]?.[normalizedOriginalType]
        if (!uploadImage?.file?.name) continue
        dispatch(deleteVisual(assignmentId, uploadImage.file.name, normalizedOriginalType))
      }
    }
  }, [t, canDeleteVisuals, downloadVisuals, uploadVisualsEntries, assignmentId, dispatch, downloadVisualsKeys, normalizedOriginalType, showConfirm])

  const lightboxModalKeyDownHandler = useCallback((e: KeyboardEvent) => {
    switch (e.key) {
      // escape
      case KeyboardEventKey.ESCAPE:
        return closeLightboxModal()
      // spacebar
      case KeyboardEventKey.SPACE:
        if (!expandedImage || !expandedImageName) return
        return toggleSelectOnImage(expandedImageName)
      // left key
      case KeyboardEventKey.ARROW_LEFT:
        return switchExpandedImage('previous')
      // right key
      case KeyboardEventKey.ARROW_RIGHT:
        return switchExpandedImage('next')
    }
  }, [expandedImage, expandedImageName, closeLightboxModal, toggleSelectOnImage, switchExpandedImage])

  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections && fileRejections.length > 0) {
      const formats: string[] = []
      for (let fileRejection of fileRejections) {
        const split = fileRejection.file.name.split('.')
        if (split.length < 2) formats.push(fileRejection.file.name)
        else formats.push(split.slice(1).join('.'))
      }
      const formatsString = Array.from(new Set(formats).keys()).join(', ')
      alert(`${t('unsupported_file_format')}\n${formatsString}`)
    }

    const duplicateFiles = []
    for (let file of acceptedFiles) {
      if (!uploadVisualsOriginalFilenames.includes(file.name)) {
        dispatch(uploadVisual(assignmentId, file, normalizedOriginalType, uploadType))
      } else duplicateFiles.push(file.name)
    }
    if (duplicateFiles.length > 0) {
      alert(t('duplicate_file_alert', { count: duplicateFiles.length, files: duplicateFiles.join(', ') }))
    }
  }, [t, dispatch, normalizedOriginalType, uploadVisualsOriginalFilenames, assignmentId, uploadType])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  // Warn about upload being in progress while leaving the browser tab
  useEffect(() => {
    if (uploadVisualsCount > 0) {
      window.addEventListener('beforeunload', beforeUnloadHandler)
      return () => {
        window.removeEventListener('beforeunload', beforeUnloadHandler)
      }
    }
  }, [beforeUnloadHandler, uploadVisualsCount])

  const imageNameReplacePattern = useImageRegex(assignmentId)

  const initiateSubmitAssignment = useCallback(async (creativeNote: string) => {
    if (!canSubmitVisuals) return
    if (visualsRecentlySubmited) return
    if (!product) return
    if (isVirtualVisit) {
      submitAssignment.mutate({ assignmentId, filenames: [], creativeComment: creativeNote, product, minUploadCount: uploadMinCount })
    } else if (isDocumentProduct) {
      if (availableDocuments.length < 1 || isAnyDocumentActionRunning) return
      submitDocumentAssignment.mutate({ assignmentId, filenames: availableDocuments.map((doc) => doc.originalFilename || ''), creativeComment: creativeNote, product, minUploadCount: 1 })
    } else {
      if (uploadVisualsCount > 0) return
      if (selected.size === 0) return
      if (selected.size > uploadMaxCount) window.alert(t('creative_exceeded_max_limit_message'))
      submitAssignment.mutate({ assignmentId, filenames: Array.from(selected), creativeComment: creativeNote, product, minUploadCount: uploadMinCount })
    }
  }, [canSubmitVisuals, visualsRecentlySubmited, product, isVirtualVisit, isDocumentProduct, submitAssignment, assignmentId, uploadMinCount, availableDocuments, isAnyDocumentActionRunning, submitDocumentAssignment, uploadVisualsCount, selected, uploadMaxCount, t])

  const errorElement = useCallback((errorType: APIRequestErrorType) => (
    <div className="page-error">
      <h1 className="error-heading">{t('forbidden_access_heading')}</h1>
      {(errorType === APIRequestErrorType.FORBIDDEN_ERROR || APIRequestErrorType.PAYMENT_REQUIRED_ERROR) ?
        <div>
          <Trans t={t} i18nKey="forbidden_access">
            <span>
              <a href={getSubscriptionPricingLink(i18n.language)} target="_blank" rel="noreferrer" className="underline">&nbsp;</a>
            </span>
          </Trans>
          <BlockInfo className="margin-top">
            <Trans t={t} i18nKey={'forbidden_contact_us'}>
              <span>
                <a href={`mailto:${supportEmail}`} className="underline">&nbsp;</a>
              </span>
            </Trans>
          </BlockInfo>
        </div>
        :
        <p><ErrorMessage error_type={errorType} /></p>
      }
      <Link to={Path.INDEX}>
        <Button className="back-button">{t('back_to_my_orders')}</Button>
      </Link>
    </div>
  ), [t])

  const preloadListing = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = listedVisuals.map(visual => {
      const filename = visual.name.replace(imageNameReplacePattern, '') + (visual.originalName ? ` | ${visual.originalName}` : '')
      const reactKey = visual.name
      return [visual.name, reactKey, (
        <div className="image-wrap">
          <GalleryImage
            image={undefined}
            label={filename}
            wait={true}
            faded={true}
            expandable={false}
            purchased={purchasedVisualsKeys.has(visual.name)}
            deletable={canDeleteVisuals}
            onDelete={() => visual.name && deleteSingleVisual(visual.name)}
          >
            <div className="text">
              <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner" />
              <span>{t('loading')}</span>
            </div>
          </GalleryImage>
        </div>
      )]
    })

    return tupleArray
  }, [listedVisuals, t, imageNameReplacePattern, purchasedVisualsKeys, canDeleteVisuals, deleteSingleVisual])

  const downloadListing = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = downloadVisualsEntries.map(([key, allTypes]) => {
      const img = allTypes?.[thumbnailType]
      if (!img) {
        throw new Error(`Image does not exist for key: ${key} and visual type: ${thumbnailType}`)
      }

      const isError = img.request.error_type !== APIRequestErrorType.NONE
      const errorMsg = img.request.error
      const isOtherMsg = errorMessageType(errorMsg) === ErrorMessageType.OTHER
      const filename = img.file?.name || ''
      const originalFilename = img.originalFilename || !!filename && visualsKeyToOriginalNameDictionary[filename] || ''
      const label = filename.replace(imageNameReplacePattern, '') + (originalFilename ? ` | ${originalFilename}` : '')
      const reactKey = key

      return [key, reactKey, (
        <div className="image-wrap">
          <GalleryImage
            image={img.signedUrl}
            label={label}
            wait={img.deleting}
            faded={img.deleting}
            deletable={canDeleteVisuals}
            onDelete={() => img.file?.name && deleteSingleVisual(img.file.name)}
            selectable={canSelectVisuals && !isError}
            selected={!isError && selected.has(img.file?.name || '')}
            onSelect={canSelectVisuals && !isError ? e => {
              toggleSelectOnImage(img.file?.name)
            } : undefined}
            expandable={!!(webType && allTypes?.[webType]?.signedUrl)}
            purchased={purchasedVisualsKeys.has(key)}
            onClick={e => {
              setExpandedImageName(img.file?.name)
              setLightboxModalOpen(true)
            }}
            droppable={canReplaceVisuals}
            isUserDraggingFile={canReplaceVisuals && isDraggingFile}
            dropZoneOptions={{ noClick: true, multiple: false }}
            insideHover={canReplaceVisuals && isDraggingFile ?
              <div className="text">
                <span>{t('drop_here')}</span>
              </div>
              :
              undefined
            }
            onDrop={canReplaceVisuals ? async (acceptedFiles, rejectedFiles) => {
              if (rejectedFiles.length === 1) return window.alert(t('reject_files'))
              if (acceptedFiles.length === 0) return window.alert(t('reject_files'))
              if (rejectedFiles.length > 1) return window.alert(t('drop_only_one'))
              if (acceptedFiles.length > 1) return window.alert(t('drop_only_one'))
              if (acceptedFiles.length === 1) {
                if (!img.file?.name) return
                if (await showConfirm(t('replace_visual_prompt'))) {
                  if (!uploadVisualsOriginalFilenames.includes(img.file.name)) {
                    dispatch(uploadVisualReplacement(assignmentId, img.file.name, acceptedFiles[0], normalizedOriginalType, uploadType))
                  } else {
                    alert(t('duplicate_file_alert', { count: 1, files: img.file.name }))
                  }
                }
              }
            } : undefined}
          >
            {img.deleting ?
              <div className="text">
                <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner" />
                <span>{t('deleting')}</span>
              </div>
              :
              isError ?
                <div className="text">
                  <span>
                    <Trans t={t} i18nKey="thumbnail_failed_description">
                      <MUITooltip placement="top" content={isOtherMsg ? errorMsg : t(`image_processing_error.${errorMsg}`)}>
                        <span style={{ cursor: 'pointer' }} data-tip data-for="re-upload-tooltip">&nbsp;</span>
                      </MUITooltip>
                    </Trans>
                  </span>
                </div>
                :
                <Fragment></Fragment>
            }
          </GalleryImage>
        </div>
      )]
    })

    return tupleArray
  }, [deleteSingleVisual, downloadVisualsEntries, canDeleteVisuals, canReplaceVisuals, canSelectVisuals, selected, t, thumbnailType, toggleSelectOnImage, webType, assignmentId, imageNameReplacePattern, purchasedVisualsKeys, isDraggingFile, dispatch, normalizedOriginalType, uploadVisualsOriginalFilenames, showConfirm, visualsKeyToOriginalNameDictionary, uploadType])

  const uploadImageContent = useCallback((img: Nullable<UploadImage>, label: string) => {
    if (!img) return
    const isError = img.request.error_type !== APIRequestErrorType.NONE
    const errorMsg = img.request.error
    const isOtherMsg = errorMessageType(errorMsg) === ErrorMessageType.OTHER

    if (img.deleting) {
      return (
        <Fragment>
          <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner" />
          <span>{t('deleting')}</span>
        </Fragment>
      )
    }

    if (img.progress === 100) {
      if (isError) {
        return (
          <span>
            <Trans t={t} i18nKey="upload_failed_description">
              <MUITooltip placement="top" content={isOtherMsg ? errorMsg : t(`image_processing_error.${errorMsg}`)}>
                <span style={{ cursor: 'pointer' }} data-tip data-for="re-upload-tooltip">&nbsp;</span>
              </MUITooltip>
            </Trans>
          </span>
        )
      }

      return (
        <Fragment>
          <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner" />
          <span>{t('processing')}</span>
        </Fragment>
      )
    }

    return (
      <span>
        {img.originalFilename &&
          <Fragment>
            {label}
            <br />
          </Fragment>
        }
        {t('uploading')}: {img.progress || 0}%
      </span>
    )
  }, [t])

  const uploadListing = useCallback(() => {
    const tupleArray: [string, string, string, JSX.Element][] = uploadVisualsEntries.map(([key, allTypes]) => {
      const img = allTypes?.[normalizedOriginalType]
      if (!img) throw new Error(`Image does not exist for key: ${key} and visual type: ${normalizedOriginalType}`)
      // For sorting fresh uploads on top
      const sortKey = (img.replaces || key.match(imageNameReplacePattern)) ? key : `${assignmentId}-bkbn-ZZZZZ-${img.droppedIn}-${key}`
      const reactKey = img.originalFilename || key
      const fileName = img.file ? img.file.name.replace(imageNameReplacePattern, '') : ''
      const originalFilename = img.originalFilename
      const label = fileName ? fileName + (originalFilename ? ` | ${originalFilename}` : '') : originalFilename || ''
      return [key, reactKey, sortKey, (
        <div className="image-wrap">
          <GalleryImage
            image={undefined}
            label={label}
            wait={img.deleting}
            faded={img.deleting}
            deletable={canDeleteVisuals}
            onDelete={() => img.file?.name && deleteSingleVisual(img.file.name)}
          >
            <div className="text">
              {uploadImageContent(img, label)}
            </div>
          </GalleryImage>
        </div>
      )]
    })

    return tupleArray
  }, [uploadVisualsEntries, normalizedOriginalType, imageNameReplacePattern, assignmentId, canDeleteVisuals, uploadImageContent, deleteSingleVisual])

  const visualsListing = useCallback(() => {
    const map: Map<string, [string, JSX.Element]> = new Map()
    if (isVirtualVisit) return map

    for (let [key, reactKey, node] of preloadListing()) map.set(key, [reactKey, node])
    for (let [key, reactKey, node] of downloadListing()) map.set(key, [reactKey, node])
    for (let [key, reactKey, sortKey, node] of uploadListing()) {
      map.delete(key)
      map.set(sortKey, [reactKey, node])
    }

    const entries: [string, [string, JSX.Element]][] = Array.from(map.entries())
    const sortedEntries = entries.sort(sortEntriesFunction)

    return (
      <Fragment>
        {sortedEntries.map(([_, [reactKey, node]]) => (
          <Fragment key={reactKey}>
            {node}
          </Fragment>
        ))}
      </Fragment>
    )
  }, [isVirtualVisit, preloadListing, uploadListing, downloadListing, sortEntriesFunction])

  const fileSelection = useCallback(() => (
    <Fragment>
      <div className="part">
        <Button
          type="secondary"
          disabled={selected.size === selectableVisualsCount}
          onClick={selectAll}
          textAndIcon={true}
          className="space-right space-bottom"
        >
          <Icon icon={IconType.CHECK} />
          <span>{t('select_all')}</span>
        </Button>
        <Button
          type="secondary"
          disabled={selected.size === 0}
          onClick={deselectAll}
          textAndIcon={true}
        >
          <Icon icon={IconType.CROSS} />
          <span>{t('deselect_all')}</span>
        </Button>
      </div>
    </Fragment>
  ), [deselectAll, selectAll, selectableVisualsCount, selected.size, t])

  return (
    <Fragment>
      <div className="page gallery">
        <div className="page-content">
          {assignment &&
            <Fragment>
              <GalleryBreadcrumbs assignment={assignment.data} />

              <DynamicQueryContent
                query={assignment}
                showStates={['error']}
              >

                {/* DOCUMENT GALLERY */}
                {isDocumentProduct &&
                  <div className="gallery-content">

                    {!isAssignmentSubmittedByCT &&
                      <div className="details-wrapper">

                        {/** Control panel */}
                        <div className="details">
                          <div className="sticky">


                            <TriangleIcon type={ColorClass.PRIMARY_BLUE} icon={IconType.FILE} />
                            <h2>{t('upload_documents')}</h2>

                            <p>{t('upload_documents_copy')}</p>

                            {canSubmitVisuals &&
                              <Fragment>
                                <MUIButton
                                  type="greenPrimary"
                                  sx={{ mt: 3 }}
                                  startIcon={<CheckCircleOutlined fontSize="large" />}
                                  disabled={availableDocuments.length < 1 || isAnyDocumentActionRunning}
                                  onClick={() => setIsDocumentSubmitOpen(true)}
                                >
                                  {t('submit_document_assignment')}
                                </MUIButton>

                                {availableDocuments.length < 1 &&
                                  <p style={{ margin: '0', marginTop: '.5rem' }}>
                                    {/* For now not dynamic - set up for potential change */}
                                    {t('upload_at_least_documents', { count: 1 })}
                                  </p>
                                }
                              </Fragment>
                            }

                          </div>
                        </div>
                      </div>
                    }

                    <div className="main-content-wrapper">
                      {assignmentStage
                        ? (
                          <div className="main-content">
                            <DocumentGalleryContent documentUploadType={DocumentUploadType.OUTPUT} />
                          </div>
                        )
                        : (
                          <div className="error-wrap">
                            <h3 className="error-heading"><ErrorMessage error_type={'error'} /></h3>
                            <ErrorMessage className="error" error_type={APIRequestErrorType.UNKNOWN_ERROR} />
                            <div className="error-contact-wrap">
                              <BlockInfo className="error-contact">
                                <Trans t={t} i18nKey={'gallery:error_contact_us'} values={{ supportEmail }}>
                                  <strong>
                                    <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                                  </strong>
                                </Trans>
                              </BlockInfo>
                            </div>
                          </div>
                        )
                      }
                    </div>

                  </div>
                }

                {/* OTHER GALLERIES */}
                {allVisuals && !isDocumentProduct &&
                  <FetchedContent
                    request={allVisuals}
                    error={errorElement(allVisuals?.error_type)}
                  >
                    <Fragment>
                      <div className="gallery-content">
                        <div className="details-wrapper">
                          {assignmentStage ?
                            <Fragment>

                              {/** SEVERAL PRODUCTS FEEDBACK BUTTON */}
                              {isFeedbackButtonAllowed && <GalleryProductFeedback />}

                              {/** Header of admin section */}
                              <div className="details">
                                <div className="sticky">
                                  {availableVisualTypes.length === 1 ?
                                    <Fragment>
                                      <TriangleIcon type={ColorClass.PRIMARY_BLUE} icon={visualTypeIconMap[availableVisualTypes[0]]} />
                                    </Fragment>
                                    :
                                    <Fragment>
                                      {availableVisualTypes.length > 1 &&
                                        <div className="space-bottom-large flex">
                                          <div className="visualTypeTabs">
                                            {availableVisualTypes.map(type => (
                                              <Button
                                                key={type}
                                                type={visualTypeSelect === type ? 'primary' : 'secondary'}
                                                textAndIcon={true}
                                                onClick={() => {
                                                  setVisualTypeSelect(type)
                                                  setSelected(new Set())
                                                }}
                                              >
                                                <Icon icon={visualTypeIconMap[type]} />
                                                <span>{t(`visual_type.creative.${type}`)}</span>
                                              </Button>
                                            ))}
                                          </div>
                                        </div>
                                      }
                                    </Fragment>
                                  }
                                  <Fragment>
                                    {showUploadAdvice &&
                                      <Fragment>
                                        <h2>{t('upload_visuals')}</h2>
                                        {isVirtualVisit ?
                                          <Fragment>
                                            {!virtualVisitID &&
                                              <p>{t('upload_visuals_description_virtual_visit')}</p>
                                            }
                                          </Fragment>
                                          :
                                          <Fragment>
                                            {originalType === VisualFileType.POST &&
                                              <p>{t('upload_visuals_description_post')}</p>
                                            }
                                          </Fragment>
                                        }
                                        {!isVirtualVisit &&
                                          <Fragment>
                                            {(() => {
                                              const getMinCount = () => {
                                                return originalType !== VisualFileType.RAW ? selectMaxValue : uploadMinCount
                                              }
                                              const minCount = getMinCount()
                                              const maxCount = originalType !== VisualFileType.RAW ? selectMaxValue : uploadMaxCount
                                              const progressTitle = t('upload_minimum', { count: minCount === PIPEDRIVE_INFINITY ? 1 : minCount })
                                              return (
                                                <Fragment>
                                                  <ProgressCard
                                                    className="part"
                                                    title={progressTitle}
                                                    max={minCount === PIPEDRIVE_INFINITY ? Number.MAX_SAFE_INTEGER : minCount}
                                                    value={visualsCount}
                                                    highlighted={maxCount === PIPEDRIVE_INFINITY ? false : visualsCount > maxCount}
                                                    leftText={(() => {
                                                      if (minCount === PIPEDRIVE_INFINITY) return t('n_uploaded', { count: visualsCount })
                                                      if (visualsCount < minCount) return t('n_remaining', { count: minCount - visualsCount })
                                                      if (originalType !== VisualFileType.RAW && visualsCount > minCount) return t('n_above', { count: visualsCount - minCount })
                                                      if (visualsCount > maxCount) return t('n_above', { count: visualsCount - maxCount })
                                                      return t('n_uploaded', { count: visualsCount })
                                                    })()}
                                                  />
                                                  {originalType === VisualFileType.RAW && product && assignmentData && AssignmentDTOIsCreativeDTO(assignmentData) &&
                                                    <BlockInfo className="part">
                                                      <CreativeProductInstruction
                                                        category={product.category}
                                                        type={product.type}
                                                        kind={product.kind}
                                                        attributeX={assignmentData.creativeInstructionAttributeX}
                                                        attributeY={assignmentData.creativeInstructionAttributeY}
                                                        count={product.quantity}
                                                        segment={product.segments[0]}
                                                      />
                                                    </BlockInfo>
                                                  }
                                                </Fragment>
                                              )
                                            })()}
                                          </Fragment>
                                        }
                                      </Fragment>
                                    }
                                    <div className="part">
                                      {canSelectVisuals && originalType !== POST_WATERMARK &&
                                        <Fragment>
                                          {fileSelection()}
                                        </Fragment>
                                      }
                                      {canDeleteVisuals &&
                                        <Button
                                          type="secondary red"
                                          disabled={visualsCount === 0}
                                          onClick={deleteAllVisuals}
                                          textAndIcon={true}
                                        >
                                          <Icon icon={IconType.TRASH} />
                                          <span>{t('delete_all')}</span>
                                        </Button>
                                      }
                                    </div>
                                    {assignmentData && AssignmentDTOIsCreativeDTO(assignmentData) &&
                                      <Fragment>
                                        {assignmentData.creativeInstructionsUrl ?
                                          <Fragment>
                                            <MUIDivider />
                                            <Button
                                              type="primary"
                                              onClick={() => externalNavigate(assignmentData.creativeInstructionsUrl)}
                                            >
                                              {t('follow_instruction_button')}
                                            </Button>
                                          </Fragment>
                                          :
                                          <Fragment>
                                            <p className="space-top"><strong>{t('meeting_instructions')}</strong></p>
                                            <BlockInfo className="blue">
                                              {multilineText(assignmentData.meetingInstructions)}
                                            </BlockInfo>
                                            <br />
                                            <p><strong>{t('creative_instructions_from_admin')}</strong></p>
                                            <BlockInfo className="blue">
                                              {multilineText(assignmentData.creativeInstructionsFromAdmin)}
                                            </BlockInfo>
                                          </Fragment>
                                        }
                                        <br />
                                        {virtualVisitExpired &&
                                          <BlockInfo className="part">
                                            <Trans t={t} i18nKey={'gallery:virtual_visit_expired_creative'} values={{ supportEmail }}>
                                              <strong>
                                                <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                                              </strong>
                                            </Trans>
                                          </BlockInfo>
                                        }
                                        {isVirtualVisit &&
                                          <BlockInfo className="part discreet space-top">
                                            {t('virtual_visit_confidential')}
                                          </BlockInfo>
                                        }
                                        {canSubmitVisuals &&
                                          <Fragment>
                                            <br />
                                            <Button
                                              type="secondary green"
                                              className="space-bottom space-right"
                                              onClick={() => setIsVisualSubmitOpen(true)}
                                              disabled={(selected.size === 0 && !isVirtualVisit) || uploadVisualsCount > 0 || visualsRecentlySubmited}
                                            >
                                              {t('submit_assignment')}
                                            </Button>
                                            <Fragment>
                                              {visualsRecentlySubmited ?
                                                <span>{t('assignment_submitted')}</span>
                                                :
                                                (selected.size === 0 && !isVirtualVisit) &&
                                                <span>{t('no_visuals_selected')}</span>
                                              }
                                            </Fragment>
                                          </Fragment>
                                        }
                                      </Fragment>
                                    }
                                  </Fragment>
                                </div>
                              </div>
                            </Fragment>
                            :
                            <div className="details">
                              <div className="error-wrap">
                                <h3 className="error-heading"><ErrorMessage error_type={'error'} /></h3>
                                <ErrorMessage className="error" error_type={APIRequestErrorType.UNKNOWN_ERROR} />
                                <div className="error-contact-wrap">
                                  <BlockInfo className="error-contact">
                                    <Trans t={t} i18nKey={'gallery:error_contact_us'} values={{ supportEmail }}>
                                      <strong>
                                        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                                      </strong>
                                    </Trans>
                                  </BlockInfo>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                        <div className="main-content-wrapper">
                          {assignmentStage ?
                            <div className={`main-content ${isVirtualVisit ? 'virtual-visit' : ''}`.trim()}>
                              <Fragment>
                                {canUploadVisuals &&
                                  <Fragment>
                                    <div className={`drop-zone ${isDraggingFile ? 'drag-active' : ''} ${isDragActive ? 'drag-over' : ''} ${visualsCount > 0 ? 'margin-bottom' : ''}`} {...getRootProps()}>
                                      {originalType === VisualFileType.RAW && showUploadAdvice && !isVirtualVisit &&
                                        <Fragment>
                                          <p className="upload-instructions">
                                            <Trans i18nKey={'gallery:upload_visuals_note_raw'}>
                                              <strong></strong>
                                            </Trans>
                                          </p>
                                        </Fragment>
                                      }
                                      <input {...getInputProps()} />
                                      {isDragActive || isDraggingFile ?
                                        <h3>{t('drop_here')}</h3>
                                        :
                                        <Fragment>
                                          <p>{t('drag_n_drop')}</p>
                                          <Button type="primary" className="choose-files">{t('choose_button')}</Button>
                                        </Fragment>
                                      }
                                    </div>
                                  </Fragment>
                                }
                                {!isVirtualVisit ?
                                  <div className="photo-grid" ref={setVisualsGridRef}>
                                    {visualsListing()}
                                  </div>
                                  :
                                  <Fragment>
                                    {!virtualVisitID &&
                                      <div className="instructions-wrap">
                                        <BlockInfo color="blue">
                                          <h2>{t('upload_visuals_instructions_virtual_visit_title')}</h2>
                                          <p>{t('upload_visuals_instructions_virtual_visit_1')}</p>
                                          <p>{t('upload_visuals_instructions_virtual_visit_2')}</p>
                                        </BlockInfo>
                                      </div>
                                    }
                                    {showVirtualVisitInstructions &&
                                      <GalleryVirtualVisitInstructions />
                                    }
                                    {!!virtualVisitID && !showVirtualVisitInstructions &&
                                      <div className="virtual-visit-embed">
                                        <iframe
                                          className="matterport-embed"
                                          title={virtualVisitID}
                                          width="100%"
                                          height="100%"
                                          src={`https://my.matterport.com/show/?m=${virtualVisitID}`}
                                          frameBorder="0"
                                          allowFullScreen
                                          allow="xr-spatial-tracking"
                                        >
                                        </iframe>
                                      </div>
                                    }
                                  </Fragment>
                                }
                              </Fragment>
                            </div>
                            :
                            <div className="error-wrap">
                              <h3 className="error-heading"><ErrorMessage error_type={'error'} /></h3>
                              <ErrorMessage className="error" error_type={APIRequestErrorType.UNKNOWN_ERROR} />
                              <div className="error-contact-wrap">
                                <BlockInfo className="error-contact">
                                  <Trans t={t} i18nKey={'gallery:error_contact_us'} values={{ supportEmail }}>
                                    <strong>
                                      <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                                    </strong>
                                  </Trans>
                                </BlockInfo>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </Fragment>
                  </FetchedContent>
                }
              </DynamicQueryContent>
            </Fragment>
          }
        </div>
      </div>

      <Modal
        className={`gallery-lightbox-modal ${selected.has(expandedImageName || '') ? 'selected' : ''} ${purchasedVisualsKeys.has(expandedImageName || '') ? 'purchased' : ''}`}
        modalContentClassName="gallery-lightbox-modal-content"
        isOpen={isLightboxModalOpen}
        onClickOutside={closeLightboxModal}
        onKeyDown={lightboxModalKeyDownHandler}
      >
        <div className="inside">
          <div className="info-header">
            <strong>{t('photo_no')}{expandedImage && webType && expandedImageWebType?.file?.name}</strong>
            {expandedImage && webType &&
              <span>{t('reference')}: #{expandedImageWebType?.file?.name.split('.')[0].split('-')[2] || ''}</span>
            }
            <div className={closeButtonStyles.closeWrap}>
              <Button
                type="secondary nobackground noborder"
                onClick={closeLightboxModal}
              >
                <Icon icon={IconType.CROSS} />
              </Button>
            </div>
          </div>
          <div className="image-box" style={{ backgroundImage: `url(${expandedImage && webType && expandedImageWebType?.signedUrl})` }}>
            {webType && !expandedImage?.[webType]?.file?.name &&
              <ErrorMessage className="error" error_type={APIRequestErrorType.NOTFOUND_ERROR} />
            }
            {webType && expandedImageWebType && expandedImageWebType?.request.error_type && expandedImageWebType?.request.error_type !== APIRequestErrorType.NONE &&
              <ErrorMessage className="error" error_type={expandedImageWebType?.request.error_type} />
            }
            {expandedImage && downloadVisualsCount > 1 &&
              <Fragment>
                <Button
                  className="arrow previous"
                  type="secondary nobackground noborder"
                  onClick={(e => { e.preventDefault(); switchExpandedImage('previous') })}
                >
                  <Icon icon={IconType.CARET_LEFT} />
                </Button>
                <Button
                  className="arrow next"
                  type="secondary nobackground noborder"
                  onClick={(e => { e.preventDefault(); switchExpandedImage('next') })}
                >
                  <Icon icon={IconType.CARET_RIGHT} />
                </Button>
              </Fragment>
            }
            {expandedImage && expandedImageName && canSelectVisuals &&
              <span
                className={`actionbutton check circular large select ${selected.has(expandedImageName || '') ? 'checked' : ''}`}
                onClick={e => toggleSelectOnImage(expandedImageName)}
              >
                <Icon icon={IconType.CHECK} /></span>
            }
          </div>
        </div>
      </Modal>

      <div className="gallery-preloader-cache">
        {webType && downloadVisualsEntries.map(([key, allTypes]) => (
          <img key={key} src={allTypes?.[webType]?.signedUrl} onError={(e) => console.log('webType', webType, e)} alt="hidden" />
        ))}
        {thumbnailType && downloadVisualsEntries.map(([key, allTypes]) => (
          <img key={key} src={allTypes?.[thumbnailType]?.signedUrl} onError={(e) => console.log('thumbnailType', thumbnailType, e)} alt="hidden" />
        ))}
      </div>

      {/* SUBMIT ASSIGNMENT MODAL */}
      <SubmitAssignmentModal
        isOpen={isVisualSubmitOpen}
        type="visual"
        onSubmit={note => initiateSubmitAssignment(note)}
        onClose={() => setIsVisualSubmitOpen(false)}
        mutation={submitAssignment}
        onAfterClosed={() => submitAssignment.reset()}
      />

      <SubmitAssignmentModal
        isOpen={isDocumentSubmitOpen}
        type="document"
        onSubmit={note => initiateSubmitAssignment(note)}
        onClose={() => setIsDocumentSubmitOpen(false)}
        mutation={submitDocumentAssignment}
        onAfterClosed={() => submitAssignment.reset()}
      />

      <GalleryUploadErrorPopup
        isOpen={isUploadErrorPopupOpen}
        onClose={() => {
          visualUploadingTriggered.current = false
          setIsUploadErrorPopupOpen(false)
        }}
      />

    </Fragment>
  )
}
