import { FC, useMemo } from 'react'
import { useProperty, usePropertyNavigation } from '../contexts'

import { GalleryHeader } from 'components/common/Gallery/GalleryHeader'
import { GalleryNotFound } from 'components/pages/Gallery/_main/ClientGallery/common/GalleryNotFound'
import { PageContent } from 'components/common/Page/PageContent'
import { PageHeader } from 'components/common/Page/PageHeader'
import { PageLayout } from 'components/common/Page/PageLayout'
import { PageTab } from 'components/common/Page/PageTab'
import { PageTabs } from 'components/common/Page/PageTabs'
import { PropertyAITextGenerator } from '../PropertyAITextGenerator'
import { PropertyDocumentsGallery } from '../PropertyDocumentsGallery'
import { PropertyInformation } from '../PropertyInformation'
import { PropertyOrders } from '../PropertyOrders'
import { PropertyPageActions } from '../PropertyPageActions'
import { PropertyVisualsGallery } from '../PropertyVisualsGallery'
import Stack from '@mui/material/Stack'
import { useTranslation } from 'react-i18next'

export enum PropertyTab {
  VISUALS_GALLERY = 'visuals_gallery', // default tab
  DOCUMENTS = 'documents',
  AI_TEXT_GENERATOR = 'ai_text_generator',
  ORDERS = 'orders',
  INFORMATION = 'information',
}

const tabs = new Set([
  PropertyTab.VISUALS_GALLERY,
  PropertyTab.DOCUMENTS,
  PropertyTab.AI_TEXT_GENERATOR,
  PropertyTab.ORDERS,
  PropertyTab.INFORMATION,
])

export const PropertyController: FC = () => {

  const { t } = useTranslation(['property'])
  const { propertyData } = useProperty()
  const { activeTab, handleChangeTab } = usePropertyNavigation()

  const showPropertyNotFound = useMemo(() => !propertyData, [propertyData])

  const propertySubTitle = useMemo(() => {
    const address = propertyData?.address

    if (!address) return '-' // edge case

    return `${address.street} ${address.streetNumber}, ${address.city}, ${address.country}`
  }, [propertyData?.address])

  return (
    <PageLayout
      header={
        <PageHeader actions={<PropertyPageActions />}>
          <GalleryHeader
            title={propertyData?.title || '-'}
            subTitle={propertySubTitle}
          />
        </PageHeader>
      }
      tabs={
        <PageTabs>
          {[...tabs].map(propertyTab =>
            <PageTab
              key={propertyTab}
              tab={propertyTab}
              tabText={t(`tabs.${propertyTab}`)}
              activeTab={activeTab}
              onClick={() => handleChangeTab(propertyTab)}
            />
          )}
        </PageTabs>
      }
      layoutErrorContent={showPropertyNotFound && <GalleryNotFound />}
    >
      <Stack width="100%">
        <PageContent>

          {/** VISUALS GALLERY */}
          {activeTab === PropertyTab.VISUALS_GALLERY && <PropertyVisualsGallery />}

          {/** DOCUMENTS */}
          {activeTab === PropertyTab.DOCUMENTS && <PropertyDocumentsGallery />}

          {/** AI TEXT GENERATOR */}
          {activeTab === PropertyTab.AI_TEXT_GENERATOR && <PropertyAITextGenerator />}

          {/** ORDERS */}
          {activeTab === PropertyTab.ORDERS && <PropertyOrders />}

          {/** INFORMATION */}
          {activeTab === PropertyTab.INFORMATION && <PropertyInformation />}

        </PageContent>
      </Stack>
    </PageLayout >
  )
}
