import { Checkbox, FormControlLabel, FormGroup, IconButton, Link, Stack, styled } from '@mui/material'
import { InstructionSteps, UploadStatus, useGalleryVirtualVisitInstructions } from './GalleryVirtualVisitInstructions.context'
import React, { Fragment, useMemo } from 'react'

import Button from 'components/common/Button/Button'
import { CORAL_600 } from 'constants/styling/theme'
import { Color } from 'constants/assets'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { QueryStatus } from 'components/common/QueryStatus'
import ReactLoading from 'react-loading'
import styles from './GalleryVirtualVisitInstructions.module.sass'
import { useAuth0 } from 'utils/auth'
import { useTranslation } from 'react-i18next'

const StyledCheckbox = styled(Checkbox)(() => ({
  '& .MuiSvgIcon-root': { fontSize: 20, color: CORAL_600 },
}))

/**
 * Virtual visit instructions controller for uploading matterport links in Gallery.
 * @example <GalleryVirtualVisitInstructionsController />
 */
export const GalleryVirtualVisitInstructionsController: React.FC = () => {

  const { t } = useTranslation(['gallery'])
  const { roles } = useAuth0()
  const {
    links,
    status,
    comments,
    stepsChecked,
    saveVirtualVisitLinks,
    setComments,
    onLinkChange,
    onAddLink,
    onRemoveLink,
    handleSubmit,
    onChangeCheckbox,
  } = useGalleryVirtualVisitInstructions()

  /** Determines whether the upload is allowed based on the current state. */
  const canUpload = useMemo(() => {
    // BKBN Admins can upload links without checking the instructions
    if ((Object.keys(InstructionSteps).length !== stepsChecked.size && roles.isCreative)) return false
    if (!links) return false
    if (Object.values(links).some((value) => value.error)) return false
    return !!Object.values(links)[0].link
  }, [links, stepsChecked.size, roles.isCreative])

  return (
    <div className={styles.wrapper}>

      {saveVirtualVisitLinks.isPending
        ? (
          <Fragment>
            <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner" />
          </Fragment>
        )
        : (
          <Fragment>

            {/** TITLE & DESCRIPTION */}
            <div className={styles.header}>
              <h3>{t(`upload_virtual_visit_instructions.title.${status}`)}</h3>
              {roles.isCreative && <p>{t(`upload_virtual_visit_instructions.description.${status}`)}</p>}
            </div>

            {/** INSTRUCTIONS CHECKLIST */}
            <div className={styles.instructions}>
              {status === UploadStatus.CT_TO_SUBMIT_LINKS && roles.isCreative &&
                <FormGroup className={styles.checkboxes}>
                  {Object.values(InstructionSteps).map((key, index) => (
                    <FormControlLabel
                      key={key}
                      control={<StyledCheckbox />}
                      checked={stepsChecked.has(key)}
                      onChange={() => onChangeCheckbox(key)}
                      label={
                        <label>{t(`upload_virtual_visit_instructions.instruction_${index + 1}`)}</label>
                      }
                    />
                  ))}
                </FormGroup>
              }

              {/** LINKS */}
              <div className={styles.linksWrapper}>
                {!!links && Object.entries(links).map(([id, item], index) =>
                  <Stack key={id} direction="row" spacing={1}>

                    {status === UploadStatus.CT_TO_SUBMIT_LINKS &&
                      <Fragment>
                        {/** Link input */}
                        <Stack width="100%" maxWidth="40rem" direction="column" spacing={1}>
                          <input
                            id={id}
                            key={id}
                            name={id}
                            type="text"
                            value={item.link}
                            className={item.error ? 'error-input' : ''}
                            placeholder={t('upload_virtual_visit_instructions.link_placeholder')}
                            onChange={(e) => onLinkChange(e, id)}
                          />
                          {item.error &&
                            <span className="error-message">
                              {t('upload_virtual_visit_instructions.input_error')}
                            </span>
                          }
                        </Stack>
                        {/** Delete button */}
                        {index > 0 && status &&
                          <IconButton
                            aria-label="delete"
                            key={`button-${id}`}
                            onClick={() => onRemoveLink(id)}
                          >
                            <DeleteOutlineIcon fontSize="large" />
                          </IconButton>
                        }
                      </Fragment>
                    }

                    {(status === UploadStatus.CT_LINKS_UPLOADED || status === UploadStatus.ADMIN_LINKS_UPLOADED) &&
                      <Link href={item.link} target="_blank" rel="noopener">
                        {item.link}
                      </Link>
                    }

                  </Stack>
                )}

                {/** COMMENTS */}
                <div className={styles.comments}>

                  <h3>{t('upload_virtual_visit_instructions.comments')}</h3>

                  <textarea
                    id="virtual-comments"
                    name="virtual-comments"
                    disabled={status !== UploadStatus.CT_TO_SUBMIT_LINKS}
                    value={comments}
                    onChange={e => setComments(e.target.value)}
                    placeholder={t('upload_virtual_visit_instructions.comments_placeholder')}
                  >
                  </textarea>

                </div>
              </div>

            </div>

            {/** ACTION BUTTONS */}
            {status === UploadStatus.CT_TO_SUBMIT_LINKS &&
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2 }}
                marginTop={2}
              >
                <Button type='secondary nobackground' onClick={onAddLink}>
                  {t('upload_virtual_visit_instructions.add_link')}
                </Button>

                <Button type='primary' disabled={!canUpload} onClick={handleSubmit}>
                  {t('upload_virtual_visit_instructions.submit_links')}
                </Button>
              </Stack>
            }

          </Fragment>
        )
      }

      <QueryStatus
        spaceTopRem={2}
        query={saveVirtualVisitLinks}
        onPurge={() => saveVirtualVisitLinks.reset()}
        showStates={['error']}
      />

    </div>
  )
}
