import React, { Fragment, useEffect, useMemo } from 'react'
import { useGalleryAssignment, useGalleryDocumentsContext } from '../../contexts'

import Collapse from '@mui/material/Collapse'
import { MUIDivider } from 'components/common/MUIDivider'
import { RequestThread } from 'components/common/Threads'
import { ThemeProvider } from '@mui/material'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useGetAssignmentMessageThreads } from 'dataQueries/messageThreads'
import { useThreads } from 'components/common/Threads/context'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * AdminGalleryMessageThreads renders the message threads related in preview mode to a specific gallery assignment. 
 *
 * @example
 * <AdminGalleryMessageThreads />
 */
export const AdminGalleryMessageThreads: React.FC = () => {

  const { t } = useTranslation('gallery')

  const { assignmentData } = useGalleryAssignment()
  const { isMessageThreadsVisible } = useGalleryDocumentsContext()
  const { setIsPreviewMode, setShowParticipantName } = useThreads()

  const currentAssignmentThreads = useGetAssignmentMessageThreads(assignmentData?.id ?? '')

  const threadItems = useMemo(() => currentAssignmentThreads.data?.data, [currentAssignmentThreads.data])

  useEffect(() => {
    setIsPreviewMode(true)
    setShowParticipantName(true)
    // On first render, no dependencies needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      {/* Use custom theme for the typography in RequestThread as admin gallery is not part of the redesign. */}
      <ThemeProvider theme={redesignMUITheme}>
        <Collapse in={isMessageThreadsVisible} timeout='auto' unmountOnExit>

          <MUIDivider />

          <RequestThread
            assignmentId={assignmentData?.id}
            title={t('admin_message_threads_title')}
            threadMessageItems={threadItems}
            fileUploadOptions="none" // Admins cannot upload files or send messages
          />

        </Collapse>
      </ThemeProvider>
    </Fragment>
  )
}
