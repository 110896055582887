import constate from 'constate'
import { useGetPropertyDetail } from 'dataQueries'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useUserData } from 'components/contexts/UserDataContext'

export const [PropertyContextProvider, useProperty] = constate(() => {

  const { userPropertyWorkspaceId } = useUserData()

  const { id } = useParams<{ id?: string }>()
  const propertyId = useMemo(() => id || undefined, [id])

  const getPropertyDetailQuery = useGetPropertyDetail(propertyId, userPropertyWorkspaceId, false)

  const propertyData = useMemo(() => getPropertyDetailQuery.data, [getPropertyDetailQuery.data])

  return {
    propertyId,
    propertyData,
  }
})
