import { useCallback, useState } from 'react'

import { MessageThreadState } from 'models/messageThreads'
import constate from 'constate'
import { useSendAssignmentMessageThread } from 'dataQueries/messageThreads'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useTranslation } from 'react-i18next'

export const [ThreadContextProvider, useThreads] = constate(() => {

  const { spawnErrorToast } = useSnackbar()
  const { t } = useTranslation(['threads'])

  const [headerButtonText, setHeaderButtonText] = useState<string | null>(null)
  const [requestThreadTitle, setRequestThreadTitle] = useState<string>('')
  const [requestThreadStatus, setRequestThreadStatus] = useState<MessageThreadState | null>(null)
  const [requestThreadStartedDate, setRequestThreadStartedDate] = useState<string>('')
  const [requestThreadId, setRequestThreadId] = useState<string>('')
  const [allowUploadOfDocuments, setAllowUploadOfDocuments] = useState<boolean>(true)
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false)
  const [showParticipantName, setShowParticipantName] = useState<boolean>(false)

  const sendAssignmentMessageThread = useSendAssignmentMessageThread()

  /** Handles the submission of a message with attached files to a specific thread within an assignment. */
  const onSubmitMessage = useCallback((assignmentId: string, threadId: string, content: string, attachmentFilenames: string[]) => {
    sendAssignmentMessageThread.mutate(
      { assignmentId, threadId, content, attachmentFilenames },
      {
        onError: (error) => {
          spawnErrorToast(
            t('error_message'),
            { error: error.request }
          )
        }
      }
    )
  }, [sendAssignmentMessageThread, spawnErrorToast, t])

  return {
    headerButtonText,
    requestThreadTitle,
    requestThreadStatus,
    requestThreadStartedDate,
    requestThreadId,
    sendAssignmentMessageThread,
    allowUploadOfDocuments,
    isPreviewMode,
    showParticipantName,
    setShowParticipantName,
    setIsPreviewMode,
    setAllowUploadOfDocuments,
    setRequestThreadTitle,
    setRequestThreadId,
    setRequestThreadStartedDate,
    setRequestThreadStatus,
    onSubmitMessage,
    setHeaderButtonText,
  }
})
