import { CopyButton } from 'components/common/CopyButton'
import { PageActions } from 'components/common/Page/PageActions'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const PropertyPageActions: React.FC = () => {

  const { t } = useTranslation(['common'])

  return (
    <PageActions>
      <CopyButton
        type="secondary"
        showIcon={true}
        showTooltip={false}
        textContent={t('Copy_link')}
        copyText={window.location.href}
      />
    </PageActions>
  )
}
