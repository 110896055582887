export enum DocumentUploadType {
  /** What client uploads (e.g. power of attorney) */
  INPUT = 'INPUT',
  /** What is supplied by contractors as a result (e.g. certificate) */
  OUTPUT = 'OUTPUT',
}

/** Enum representing different types of tags that can be associated with an uploaded visual. */
export enum UploadTagType {
  EXTERNAL_VIEW = 'EXTERNAL_VIEW',
  HEATING_SYSTEM_LABEL = 'HEATING_SYSTEM_LABEL',
  CONSTRUCTION_DESCRIPTION = 'CONSTRUCTION_DESCRIPTION',
  GROUND_PLAN = 'GROUND_PLAN',
  ORDER_SUPPLEMENTARY_DOCUMENT = 'ORDER_SUPPLEMENTARY_DOCUMENT',
  OTHERS = 'OTHERS'
}
