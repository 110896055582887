import './EmailVerified.sass'

import React, { useMemo } from 'react'

import ChangedEmailMode from './ChangedEmailMode.module'
import { PageTransition } from 'utils/animations'
import SignupMode from './SignupMode.module'

export enum EmailVerifyMode {
  DEFAULT = 'default',
  CHANGE_EMAIL = 'change-email'
}

const EmailVerified: React.FC = () => {
  const params = useMemo(() => new URLSearchParams(window.location.search), [])
  const emailVerifyMode = useMemo(() => params.has('mode') ? params.get('mode') : EmailVerifyMode.DEFAULT, [params])


  if (emailVerifyMode === EmailVerifyMode.DEFAULT) return <SignupMode />

  return (
    <PageTransition>
      <div className="page triangle-stripe-bg email-verified">
        <div className="page-content">
          <div className="wrap">
            <div className="content">
              <div className="card">
                {emailVerifyMode === EmailVerifyMode.CHANGE_EMAIL && <ChangedEmailMode />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  )
}

export default EmailVerified
