import './AssignmentTabLink.sass'

import { AssignmentDTOIsAdministratorDTO, DealDTOOrAssignmentDTOIsAssignmentDTO } from 'utils/typeguards'

import { AssignmentDTO } from 'models/assignment'
import { Badge } from '../Badges'
import { Fragment } from 'react'
import Icon from '../Icon/Icon'
import { IconType } from 'constants/assets'
import { Link } from 'react-router-dom'
import { MUIDivider } from '../MUIDivider'
import classNames from 'classnames'
import { externalNavigate } from 'utils/helpers'
import { getSalesforceAssignmentLink } from 'utils/serialization'
import { useAuth0 } from 'utils/auth'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
interface Props {
  /** assignment data */
  assignment: AssignmentDTO
  /** is this AssignmentTabLink active */
  isActive: boolean
}

/**
 * @component Link component to navigate between assignments
 * @example <AssignmentTabLink assignment={assignment} isActive={isActive}</AssignmentTabLink>
 */
const AssignmentTabLink: React.FC<Props> = ({
  assignment,
  isActive
}) => {

  const { t } = useTranslation(['assignment_tab_link', 'assignment_stage', 'product'])
  const { roles } = useAuth0()
  const { id, products, stage } = assignment

  return (
    <Link
      className={classNames('tab-link-wrap', {
        'is-disabled': isActive,
        'is-selected': isActive,
      })}
      key={id}
      to={`/gallery/${id}`}
      onClick={isActive
        ? e => e.preventDefault()
        : undefined
      }
    >
      <div className="tab-link" data-tip data-for={`assignment_tab_link_tooltip_${id}`}>
        {roles.isAdmin &&
          <Fragment>
            <div className="top-wrap">
              <div className="title">
                <h4 className="title-text">#{id}</h4>

                {DealDTOOrAssignmentDTOIsAssignmentDTO(assignment) && AssignmentDTOIsAdministratorDTO(assignment) &&
                  <Fragment>

                    {assignment.pipedriveId && !assignment.salesforceId &&
                      <h4 className="crm-link">
                        PD: <span
                          className="link"
                          onClick={() => externalNavigate(`https://backbone.pipedrive.com/deal/${String(assignment.pipedriveId)}`)}
                        >
                          {assignment.pipedriveId}
                        </span>
                      </h4>
                    }

                    {!assignment.pipedriveId && assignment.salesforceId &&
                      <h4 className="crm-link">
                        SF: <span className="link"
                          onClick={() => externalNavigate(getSalesforceAssignmentLink(assignment.salesforceId))}
                        >
                          {assignment.salesforceId}
                        </span>
                      </h4>
                    }

                    {!assignment.pipedriveId && !assignment.salesforceId &&
                      <span style={{ textDecoration: 'line-through' }}>PD / SF</span>
                    }

                    {assignment.assignmentExternalIds.length > 0 &&
                      <span className='title-text-id'>
                        EXT: <span className="text-id">{assignment.assignmentExternalIds.join(', ')}</span>
                      </span>
                    }

                  </Fragment>
                }

                {!isActive &&
                  <div className="title-icon-wrap">
                    <Icon className="title-icon" icon={IconType.LINK}></Icon>
                  </div>
                }

              </div>

              <MUIDivider margin={8} />

              <p className="products">{products.map((product) => (product.quantity > 1 ? `${product.quantity} x ` : '') + t(`product:p_${product.id}`)).join(', ')}</p>
            </div>
            <Badge color="gray">{t(`assignment_stage:admin:${stage}`)}</Badge>
          </Fragment>
        }
      </div>
    </Link>
  )
}

export default AssignmentTabLink
