import { BEIGE_300, BEIGE_600, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import React, { Fragment, ReactNode } from 'react'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

/** @interface Props for the SummaryDetails component. */
interface DynamicSummaryProps {
  /** The title of the summary. */
  title: string
  /** The value to be displayed in the summary. */
  value: string | ReactNode
}

/**
 * @component 
 * `DynamicSummary` displays a title and value which can be a string or a ReactNode.
 * 
 * @example
 * <DynamicSummary title="Severus Snape" value="Turn to page 394." />
 */
export const DynamicSummary: React.FC<DynamicSummaryProps> = ({ title, value }) => {
  return (
    <Stack gap={0.5}>

      <Typography color={GRAY_700} fontWeight={500} variant='text-sm'>
        {title}
      </Typography>

      {typeof value === 'string'
        ? (
          <Typography color={GRAY_900} variant='text-sm'>
            {value}
          </Typography>
        )
        : (
          <Fragment>
            {value}
          </Fragment>
        )
      }

    </Stack>
  )
}

/**
 * @component
 * `SummaryTextBlock` displays a long text wrapped as a block with border and background.
 * 
 * @example
 * <SummaryTextBlock value="You are here to learn the subtle science and exact art of potion-making." />
 */
export const SummaryTextBlock: React.FC<{ value: string }> = ({ value }) => {
  return (
    <BorderBoxWrapper
      padding={1}
      marginTop={0.5}
      elevation='none'
      borderColor={BEIGE_600}
      backgroundColor={BEIGE_300}
    >
      <Typography color={GRAY_900} variant='text-sm'>
        {value}
      </Typography>
    </BorderBoxWrapper>
  )
}

/** @interface Props for the SummaryContactPerson component. */
interface SummaryContactPersonProps {
  /** The name of the contact person. */
  name: string
  /** The email of the contact person. */
  email: string
  /** The phone of the contact person. */
  phone: string
}

/**
 * @component `SummaryContactPerson` displays date and time.
 * 
 * @example
 * <SummaryContactPerson date={new Date()} time="12:00 PM" />
 */
export const SummaryContactPerson: React.FC<SummaryContactPersonProps> = ({ name, email, phone }) => {
  const { t } = useTranslation(['common'])

  return (
    <Stack gap={0.5}>

      <Typography color={GRAY_900} variant='text-sm' fontWeight={600}>
        {name}
      </Typography>

      <Stack gap={1} direction="row" alignItems="center">

        <Typography color={GRAY_700} variant='text-sm'>
          {`${t('Email')}:`}
        </Typography>

        <Typography color={GRAY_900} variant='text-sm'>
          {email}
        </Typography>

      </Stack>

      <Stack gap={1} direction="row" alignItems="center">

        <Typography color={GRAY_700} variant='text-sm'>
          {`${t('Phone_number')}:`}
        </Typography>

        <Typography color={GRAY_900} variant='text-sm'>
          {phone}
        </Typography>

      </Stack>

    </Stack>
  )
}
