import { BEIGE_100, BEIGE_1000, BEIGE_300, BEIGE_500, BEIGE_600, BEIGE_700, BEIGE_900, BLUE_100, BLUE_1000, BLUE_200, BLUE_600, BLUE_700, CORAL_100, CORAL_1000, CORAL_200, CORAL_600, CORAL_700, GRAY_100, GRAY_1000, GRAY_300, GRAY_600, GRAY_700, GRAY_800, GREEN_100, GREEN_1000, GREEN_200, GREEN_600, GREEN_700, HKGroteskFamily, ORANGE_100, ORANGE_1000, ORANGE_200, ORANGE_600, ORANGE_700, WHITE } from './theme'

import { chipClasses } from '@mui/material/Chip'
import { createTheme } from '@mui/material/styles'

export const BadgeMUITheme = createTheme({
  palette: {
    gray: {
      main: GRAY_600,
      light: GRAY_100,
      dark: GRAY_1000,
      contrastText: WHITE
    },
    darkGray: {
      main: GRAY_800,
      light: GRAY_100,
      dark: GRAY_1000,
      contrastText: WHITE
    },
    beige: {
      main: BEIGE_600,
      light: BEIGE_100,
      dark: BEIGE_1000,
      contrastText: WHITE
    },
    blue: {
      main: BLUE_600,
      light: BLUE_100,
      dark: BLUE_1000,
      contrastText: WHITE
    },
    coral: {
      main: CORAL_600,
      light: CORAL_100,
      dark: CORAL_1000,
      contrastText: WHITE
    },
    green: {
      main: GREEN_600,
      light: GREEN_100,
      dark: GREEN_1000,
      contrastText: WHITE
    },
    orange: {
      main: ORANGE_600,
      light: ORANGE_100,
      dark: ORANGE_1000,
      contrastText: WHITE
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          gap: '.4rem',
          borderRadius: '.4rem',
          height: 'unset'
        },
        label: {
          fontWeight: 500,
          fontFamily: HKGroteskFamily,
          padding: 0,
        },
        deleteIcon: {
          fontSize: '1.6rem',
          margin: 0
        },
        icon: {
          fontSize: '1.6rem',
          margin: 0
        }
      },
      variants: [
        {
          props: { size: 'xs' },
          style: { padding: '.1rem .4rem', minHeight: '1.8rem', fontSize: '1rem', lineHeight: '1.6rem' }
        },
        {
          props: { size: 'sm' },
          style: { padding: '.2rem .6rem', minHeight: '2.2rem', fontSize: '1.2rem', lineHeight: '1.8rem' }
        },
        {
          props: { size: 'md' },
          style: { padding: '.2rem .6rem', minHeight: '2.4rem', fontSize: '1.4rem', lineHeight: '2rem' }
        },
        {
          props: { size: 'lg' },
          style: { padding: '.4rem .8rem', minHeight: '2.8rem', fontSize: '1.4rem', lineHeight: '2rem' }
        },
        {
          props: { color: 'gray' },
          style: { borderColor: GRAY_300, backgroundColor: GRAY_100, color: GRAY_700, [`& .${chipClasses.deleteIcon}`]: { color: GRAY_700 } }
        },
        {
          props: { color: 'darkGray' },
          style: { borderColor: GRAY_300, backgroundColor: GRAY_100, color: GRAY_800, [`& .${chipClasses.deleteIcon}`]: { color: GRAY_800 } }
        },
        {
          props: { color: 'beige' },
          style: { borderColor: BEIGE_500, backgroundColor: BEIGE_300, color: BEIGE_900, [`& .${chipClasses.deleteIcon}`]: { color: BEIGE_700 } }
        },
        {
          props: { color: 'blue' },
          style: { borderColor: BLUE_200, backgroundColor: BLUE_100, color: BLUE_600, [`& .${chipClasses.deleteIcon}`]: { color: BLUE_700 } }
        },
        {
          props: { color: 'coral' },
          style: { borderColor: CORAL_200, backgroundColor: CORAL_100, color: CORAL_600, [`& .${chipClasses.deleteIcon}`]: { color: CORAL_700 } }
        },
        {
          props: { color: 'green' },
          style: { borderColor: GREEN_200, backgroundColor: GREEN_100, color: GREEN_700, [`& .${chipClasses.deleteIcon}`]: { color: GREEN_700 } }
        },
        {
          props: { color: 'orange' },
          style: { borderColor: ORANGE_200, backgroundColor: ORANGE_100, color: ORANGE_600, [`& .${chipClasses.deleteIcon}`]: { color: ORANGE_700 } }
        },
      ]
    }
  }
})
