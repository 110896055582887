import { AssignmentDTO } from 'models/assignment'
import { AssignmentProgress } from 'components/common/Gallery/AssignmentProgress'
import { BEIGE_600 } from 'constants/styling/theme'
import { CircleIcon } from 'components/common/CircleIcon'
import { DotDivider } from 'components/common/DotDivider'
import { EventTime } from 'components/common/EventTime'
import { ProductKind } from 'constants/product'
import Stack from '@mui/material/Stack'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import styles from './OrderDetailAssignmentItem.module.sass'
import { useGalleryProduct } from 'components/pages/Gallery/_main/contexts/GalleryProduct.context'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTimezone } from 'components/contexts/timezone.context'
import { useTranslation } from 'react-i18next'

export interface Props {
  /** Assignment DTO data */
  assignment: AssignmentDTO
  button?: JSX.Element
}

/**
 * Gallery order detail assignment item info component
 * @example <OrderDetailAssignmentItem></OrderDetail>
 */
export const OrderDetailAssignmentItem: React.FC<Props> = ({ assignment, button }) => {
  const { t } = useTranslation(['gallery', 'product'])
  const { userTimezone } = useTimezone()
  const isMobileView = useMediaQuery(redesignMUITheme.breakpoints.down('md'))

  const { getProductKindIcon } = useGalleryProduct()
  const productKind = assignment.products[0].kind

  return (
    <Stack className={styles.orderDetailAssignmentItem}>
      <div className={styles.orderDetailHeader}>
        <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 2, md: 1 }} justifyContent="space-between" alignItems={{ xs: 'left', md: 'center' }}>

          <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 2, md: 1 }} alignContent="flex-start">
            <Stack direction="row" gap={1} alignContent="flex-start">
              <CircleIcon size="2.4rem" icon={getProductKindIcon(productKind)} circleColor={BEIGE_600} />
              <span className={styles.assignmentItemTitle}>{assignment.products.map((product) => (product.quantity > 1 ? `${product.quantity} x ` : '') + t(`product:p_${product.id}`)).join(', ')}</span>
            </Stack>

            {!isMobileView && <DotDivider />}
            <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
              <span className={styles.assignmentItemTitle}>{`#${assignment.id}`}</span>
              {!!button && isMobileView &&
                <Stack direction="row" alignContent="flex-end">{button}</Stack>
              }
            </Stack>
          </Stack>

          {!!button && !isMobileView &&
            <Stack direction="row" alignContent="flex-end">{button}</Stack>
          }
        </Stack>

        {/** START TIME */}
        {productKind !== ProductKind.AUTHORITIES_DOCUMENTS && !!assignment.shootingStartDateTime &&
          <EventTime
            eventStart={assignment.shootingStartDateTime}
            eventTimezone={assignment.timezone}
            userTimezone={userTimezone}
            minDuration={assignment.timeOnSiteMin}
            maxDuration={assignment.timeOnSiteMax}
          />
        }
      </div>

      {/** ASSIGNMENT STAGE */}
      <AssignmentProgress assignmentId={assignment.id} stage={assignment.stage} products={assignment.products}></AssignmentProgress>
    </Stack>
  )
}
