import React from 'react'
import Stack from '@mui/material/Stack'

export const PropertyOrders: React.FC = () => {
  return (
    <Stack>
      <span>Property Orders</span>
    </Stack>
  )
}
